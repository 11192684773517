<fid-project-dialog-header
  [casino]="dialogData!.casino"
  [wallet]="dialogData?.wallet"
></fid-project-dialog-header>

<fid-cashier-form
  *ngIf="!transactionInProcess()"
  [casino]="dialogData!.casino"
  [game]="dialogData?.game"
></fid-cashier-form>

<fid-cashier-status
  *ngIf="transactionInProcess()"
  [casino]="dialogData!.casino"
  (isSwitchedToCashier)="switchToCashier($event)"
></fid-cashier-status>
