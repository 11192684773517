import { AsyncPipe, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Output,
  signal,
  WritableSignal,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslocoDirective } from '@ngneat/transloco';
import {
  TuiButtonModule,
  TuiErrorModule,
  TuiHostedDropdownModule,
  TuiNumberFormatModule,
  tuiNumberFormatProvider,
  TuiSvgModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import {
  TuiDataListWrapperModule,
  TuiFieldErrorPipeModule,
  TuiInputNumberModule,
  TuiInputPhoneModule,
  TuiSelectModule,
} from '@taiga-ui/kit';
import { AllowedCountriesRepository } from 'core/data/repository/allowed-countries/allowed-countries.repository';
import { PhoneSearchListComponent } from 'shared/components/phone-search-list/phone-search-list.component';
import { AppAuthService } from 'features/auth/services/app-auth.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { QrImageComponent } from 'shared/components/qr-image/qr-image.component';
import { combineLatest, tap } from 'rxjs';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { ClientInfoService } from 'core/features/client-info/services/client-info.service';
import { AppLinkComponent } from 'shared/components/app-link/app-link.component';
import { OpenDialogDirective } from 'core/features/dialog/directives/open-dialog.directive';

@Component({
  selector: 'fid-login-phone-form',
  standalone: true,
  imports: [
    TuiButtonModule,
    TranslocoDirective,
    TuiSelectModule,
    TuiDataListWrapperModule,
    TuiErrorModule,
    ReactiveFormsModule,
    TuiInputPhoneModule,
    TuiFieldErrorPipeModule,
    AsyncPipe,
    PhoneSearchListComponent,
    FormsModule,
    TuiTextfieldControllerModule,
    TuiHostedDropdownModule,
    TuiInputNumberModule,
    TuiNumberFormatModule,
    NgIf,
    TuiButtonModule,
    TuiButtonModule,
    TuiSvgModule,
    QrImageComponent,
    AppLinkComponent,
    OpenDialogDirective,
  ],
  templateUrl: './login-phone-form.component.html',
  styleUrl: './login-phone-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [tuiNumberFormatProvider({ thousandSeparator: '' })],
})
export class LoginPhoneFormComponent {
  private readonly destroyRef = inject(DestroyRef);
  private readonly appAuthService = inject(AppAuthService);
  private readonly allowedCountriesRepository = inject(AllowedCountriesRepository);
  private readonly clientInfo: ClientInfoService = inject(ClientInfoService);

  private countryCode: string = '';
  protected isPending: WritableSignal<boolean> = signal(false);
  protected isFormDisabled: WritableSignal<boolean> = signal(false);
  protected readonly allowedCountries$ = this.allowedCountriesRepository.list$;
  protected form = new FormGroup({
    prefix: new FormControl<string | null>(null, Validators.required),
    phone: new FormControl<string | null>(null, Validators.required),
  });

  @Output() public formSubmitted: EventEmitter<string> = new EventEmitter();

  constructor() {
    this.checkForm();

    combineLatest([this.clientInfo.info$, this.allowedCountries$])
      .pipe(
        tap(([info, allowedCountries]) => {
          if (info && allowedCountries) {
            const clientCountry = allowedCountries.find(item => item.code === info.country.code);
            if (clientCountry) {
              this.form.controls.prefix.patchValue(clientCountry.prefix);
            }
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();

    combineLatest([this.allowedCountries$, this.form.valueChanges])
      .pipe(
        tap(([allowedCountries, { prefix, phone }]) => {
          this.countryCode = allowedCountries.find(item => item.prefix === prefix)?.code || '';

          this.form.setErrors(
            this.isFormValid(prefix!, phone!, this.countryCode!) ? null : { incorrect: true },
          );

          this.checkForm();
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  protected onSubmit(): void {
    this.isPending.set(true);

    const formControls = this.form.controls;
    const formValue = formControls.prefix.value! + formControls.phone.value!;

    if (this.form.invalid || !formControls.prefix! || !formControls.phone || !formValue) {
      this.isPending.set(false);
      return;
    }

    this.appAuthService
      .loginInit(formValue)
      .pipe(
        tap(() => {
          this.isPending.set(false);
          this.formSubmitted.emit(formValue);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        error: () => {
          this.isPending.set(false);
        },
      });
  }

  protected isFormValid(prefix: string, phone: string, countryCode: string): boolean {
    if (prefix && phone && countryCode) {
      try {
        const phoneNumberUtil = PhoneNumberUtil.getInstance();
        const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(prefix + phone, countryCode);

        return phoneNumberUtil.isValidNumber(phoneNumber);
      } catch (e) {
        return false;
      }
    }

    return false;
  }

  private checkForm(): void {
    this.isFormDisabled.set(!(this.form.value.prefix && this.form.value.phone && this.form.valid));
  }
}
