import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TuiLinkModule, TuiSvgModule } from '@taiga-ui/core';
import { LoginCodeFormComponent } from 'features/auth/components/login-form/login-code-form/login-code-form.component';
import { LoginPhoneFormComponent } from 'features/auth/components/login-form/login-phone-form/login-phone-form.component';
import { LoginSteps } from 'features/auth/model/auth.model';
import { AmpEventsName, AmplitudeService } from 'shared/services/amplitude.service';

@Component({
  selector: 'fid-login-form',
  standalone: true,
  imports: [LoginPhoneFormComponent, LoginCodeFormComponent, TuiSvgModule, NgIf, TuiLinkModule],
  templateUrl: './login-form.component.html',
  styleUrl: './login-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFormComponent {
  private readonly amplitudeService: AmplitudeService = inject(AmplitudeService);

  protected activeStep = LoginSteps.Phone;
  protected loginSteps = LoginSteps;
  protected formPhone?: string;

  protected phoneFormSubmitted(phone: string): void {
    this.formPhone = phone;
    this.switchStep(LoginSteps.Code);

    this.amplitudeService.send(AmpEventsName.AuthEnterPhoneNextClick);
  }

  protected switchStep(step: LoginSteps): void {
    this.activeStep = step;
  }
}
