import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import {
  TuiButtonModule,
  TuiErrorModule,
  TuiSvgModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import { TuiFieldErrorPipeModule, TuiInputNumberModule } from '@taiga-ui/kit';
import { TuiCurrencyPipeModule } from '@taiga-ui/addon-commerce';
import { PaymentMethodsDto } from 'core/fapi/models/payment-methods-dto';
import { CalcConfigModel } from 'features/project/model/project.model';
import { CalcConfig } from 'features/cashier/constants/cashier.constants';
import { SendAnalyticsDirective } from 'shared/directives/send-analytics.directive';
import { AmpEventsName } from 'shared/services/amplitude.service';

@Component({
  selector: 'fid-amount-calculator',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
    TuiButtonModule,
    TuiSvgModule,
    TuiInputNumberModule,
    TuiTextfieldControllerModule,
    NgForOf,
    TuiCurrencyPipeModule,
    NgClass,
    AsyncPipe,
    TuiErrorModule,
    TuiFieldErrorPipeModule,
    SendAnalyticsDirective,
  ],
  templateUrl: './amount-calculator.component.html',
  styleUrl: './amount-calculator.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AmountCalculatorComponent implements OnInit {
  private readonly rootFormGroup = inject(FormGroupDirective);

  @Input() public selectedPayment!: PaymentMethodsDto;

  protected readonly config: CalcConfigModel = CalcConfig;
  protected amountGroup!: FormGroup;
  protected ampEventsName = AmpEventsName;

  protected setAmountInputValue(value: number): void {
    this.amountGroup.setValue({ amount: value });
  }

  protected calcAmountInputValue(step: number, operator: string): void {
    const currentValue = this.amountGroup.controls['amount'].value;
    let amount: number = 0;

    if (operator === 'minus') {
      amount = Math.max(currentValue - step, this.selectedPayment.min);
    }
    if (operator === 'plus') {
      amount = Math.min(currentValue + step, this.selectedPayment.max);
    }

    this.setAmountInputValue(amount || currentValue);
  }

  public ngOnInit(): void {
    this.amountGroup = this.rootFormGroup.control.get('amountGroup') as FormGroup;
  }
}
