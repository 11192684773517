/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getHiws } from '../fn/hiw/get-hiws';
import { GetHiws$Params } from '../fn/hiw/get-hiws';
import { getHiwsId } from '../fn/hiw/get-hiws-id';
import { GetHiwsId$Params } from '../fn/hiw/get-hiws-id';
import { HiwListResponse } from '../models/hiw-list-response';
import { HiwResponse } from '../models/hiw-response';

@Injectable({ providedIn: 'root' })
export class HiwService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getHiws()` */
  static readonly GetHiwsPath = '/hiws';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHiws()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHiws$Response(params?: GetHiws$Params, context?: HttpContext): Observable<StrictHttpResponse<HiwListResponse>> {
    return getHiws(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getHiws$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHiws(params?: GetHiws$Params, context?: HttpContext): Observable<HiwListResponse> {
    return this.getHiws$Response(params, context).pipe(
      map((r: StrictHttpResponse<HiwListResponse>): HiwListResponse => r.body)
    );
  }

  /** Path part for operation `getHiwsId()` */
  static readonly GetHiwsIdPath = '/hiws/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHiwsId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHiwsId$Response(params: GetHiwsId$Params, context?: HttpContext): Observable<StrictHttpResponse<HiwResponse>> {
    return getHiwsId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getHiwsId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHiwsId(params: GetHiwsId$Params, context?: HttpContext): Observable<HiwResponse> {
    return this.getHiwsId$Response(params, context).pipe(
      map((r: StrictHttpResponse<HiwResponse>): HiwResponse => r.body)
    );
  }

}
