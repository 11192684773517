import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormControl, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class InputOTPService {
  public validate(control: AbstractControl, size: number): ValidationErrors | null {
    if (!control.value || control.value.length < size) {
      return {
        otpInputError: true,
      };
    }

    return null;
  }

  public buildFormArray(size: number): FormArray {
    return new FormArray(new Array(size).fill('').map(v => new FormControl(v)));
  }
}
