import { inject, Injectable } from '@angular/core';
import { SportListResponse } from 'core/capi/models/sport-list-response';
import { SportListResponseDataItem } from 'core/capi/models/sport-list-response-data-item';
import { ListMapper, Mapper, ResponseMapper } from 'core/base/model/mapper.model';
import { IconMapper } from 'core/data/common/icon/icon.mapper';
import { SportModel } from 'core/data/domain/sport.model';

@Injectable({
  providedIn: 'root',
})
export class SportMapper
  implements
    Mapper<SportListResponseDataItem, SportModel>,
    ListMapper<SportListResponseDataItem, SportModel>,
    ResponseMapper<SportListResponse, SportModel[]>
{
  private readonly iconMapper: IconMapper = inject(IconMapper);

  public mapFrom({ attributes }: SportListResponseDataItem): SportModel {
    return {
      description: attributes?.XDescription || '',
      date: attributes?.XDate || '',
      odd: attributes?.XOdd || '',
      category: {
        name: attributes?.sportCategory?.data?.attributes?.name || '',
      },
      teamA: {
        teamId: attributes?.ATeamID || '',
        teamName: attributes?.ATeamName || '',
        teamOdd: attributes?.ATeamOdd || '',
        icon: this.iconMapper.mapFrom(attributes?.ATeamIcon),
      },
      teamB: {
        teamId: attributes?.BTeamID || '',
        teamName: attributes?.BTeamName || '',
        teamOdd: attributes?.BTeamOdd || '',
        icon: this.iconMapper.mapFrom(attributes?.BTeamIcon),
      },
    };
  }

  public mapFromList(params: SportListResponseDataItem[]): SportModel[] {
    return params.map((item: SportListResponseDataItem) => this.mapFrom(item));
  }

  public mapFromResponse({ data }: SportListResponse): SportModel[] {
    return this.mapFromList(data || []);
  }
}
