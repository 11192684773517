/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getPages } from '../fn/page/get-pages';
import { GetPages$Params } from '../fn/page/get-pages';
import { getPagesId } from '../fn/page/get-pages-id';
import { GetPagesId$Params } from '../fn/page/get-pages-id';
import { PageListResponse } from '../models/page-list-response';
import { PageResponse } from '../models/page-response';

@Injectable({ providedIn: 'root' })
export class PageService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getPages()` */
  static readonly GetPagesPath = '/pages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPages()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPages$Response(params?: GetPages$Params, context?: HttpContext): Observable<StrictHttpResponse<PageListResponse>> {
    return getPages(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPages(params?: GetPages$Params, context?: HttpContext): Observable<PageListResponse> {
    return this.getPages$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageListResponse>): PageListResponse => r.body)
    );
  }

  /** Path part for operation `getPagesId()` */
  static readonly GetPagesIdPath = '/pages/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPagesId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPagesId$Response(params: GetPagesId$Params, context?: HttpContext): Observable<StrictHttpResponse<PageResponse>> {
    return getPagesId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPagesId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPagesId(params: GetPagesId$Params, context?: HttpContext): Observable<PageResponse> {
    return this.getPagesId$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageResponse>): PageResponse => r.body)
    );
  }

}
