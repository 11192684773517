/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ApiPaginatedDto } from '../models/api-paginated-dto';
import { ApiResponseDto } from '../models/api-response-dto';
import { IntersectionTransactionDtoAdditionalTransactionDto } from '../models/intersection-transaction-dto-additional-transaction-dto';
import { transactionControllerCancelWithdrawal } from '../fn/transaction/transaction-controller-cancel-withdrawal';
import { TransactionControllerCancelWithdrawal$Params } from '../fn/transaction/transaction-controller-cancel-withdrawal';
import { transactionControllerHistory } from '../fn/transaction/transaction-controller-history';
import { TransactionControllerHistory$Params } from '../fn/transaction/transaction-controller-history';
import { transactionControllerInfo } from '../fn/transaction/transaction-controller-info';
import { TransactionControllerInfo$Params } from '../fn/transaction/transaction-controller-info';
import { TransactionDto } from '../models/transaction-dto';

@Injectable({ providedIn: 'root' })
export class TransactionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `transactionControllerHistory()` */
  static readonly TransactionControllerHistoryPath = '/api/transaction/history';

  /**
   * Get user transaction history
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transactionControllerHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionControllerHistory$Response(params?: TransactionControllerHistory$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiPaginatedDto & {
'data'?: Array<TransactionDto>;
}>> {
    return transactionControllerHistory(this.http, this.rootUrl, params, context);
  }

  /**
   * Get user transaction history
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `transactionControllerHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionControllerHistory(params?: TransactionControllerHistory$Params, context?: HttpContext): Observable<ApiPaginatedDto & {
'data'?: Array<TransactionDto>;
}> {
    return this.transactionControllerHistory$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiPaginatedDto & {
'data'?: Array<TransactionDto>;
}>): ApiPaginatedDto & {
'data'?: Array<TransactionDto>;
} => r.body)
    );
  }

  /** Path part for operation `transactionControllerInfo()` */
  static readonly TransactionControllerInfoPath = '/api/transaction/{uuid}';

  /**
   * Get transaction info
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transactionControllerInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionControllerInfo$Response(params: TransactionControllerInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiResponseDto & {
'data'?: IntersectionTransactionDtoAdditionalTransactionDto;
}>> {
    return transactionControllerInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * Get transaction info
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `transactionControllerInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionControllerInfo(params: TransactionControllerInfo$Params, context?: HttpContext): Observable<ApiResponseDto & {
'data'?: IntersectionTransactionDtoAdditionalTransactionDto;
}> {
    return this.transactionControllerInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiResponseDto & {
'data'?: IntersectionTransactionDtoAdditionalTransactionDto;
}>): ApiResponseDto & {
'data'?: IntersectionTransactionDtoAdditionalTransactionDto;
} => r.body)
    );
  }

  /** Path part for operation `transactionControllerCancelWithdrawal()` */
  static readonly TransactionControllerCancelWithdrawalPath = '/api/transaction/withdrawal/cancel';

  /**
   * Cancel user's pending withdrawal transaction if initiator is owner of the transaction
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transactionControllerCancelWithdrawal()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  transactionControllerCancelWithdrawal$Response(params: TransactionControllerCancelWithdrawal$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return transactionControllerCancelWithdrawal(this.http, this.rootUrl, params, context);
  }

  /**
   * Cancel user's pending withdrawal transaction if initiator is owner of the transaction
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `transactionControllerCancelWithdrawal$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  transactionControllerCancelWithdrawal(params: TransactionControllerCancelWithdrawal$Params, context?: HttpContext): Observable<void> {
    return this.transactionControllerCancelWithdrawal$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
