import { inject, Injectable } from '@angular/core';
import {
  TransactionHistoryResponseModel,
  TransactionInfoResponseModel,
  TransactionModel,
} from 'core/data/domain/transaction.model';
import { TransactionMapper } from 'core/data/repository/transaction/transaction.mapper';
import { TransactionService } from 'core/fapi/services/transaction.service';
import { LocalizationService } from 'core/features/localization/services/localization.service';
import { map, Observable } from 'rxjs';

import type { TransactionControllerHistory$Params } from 'core/fapi/fn/transaction/transaction-controller-history';

@Injectable({
  providedIn: 'root',
})
export class TransactionRepository {
  private readonly transactionService: TransactionService = inject(TransactionService);
  private readonly localization: LocalizationService = inject(LocalizationService);
  private readonly mapper: TransactionMapper = inject(TransactionMapper);

  public getHistoryResponse(
    params?: TransactionControllerHistory$Params,
  ): Observable<TransactionHistoryResponseModel> {
    return this.localization.trackChanges(
      this.transactionService.transactionControllerHistory(params).pipe(
        map(response => ({
          data: this.mapper.mapFromList(response.data || []),
        })),
      ),
    );
  }

  public getHistoryList(
    params?: TransactionControllerHistory$Params,
  ): Observable<TransactionModel[]> {
    return this.getHistoryResponse(params).pipe(
      map(response => response.data),
      map(transactions => this.sortTransactionsByDate(transactions)),
    );
  }

  public getInfoResponse(uuid: string): Observable<TransactionInfoResponseModel> {
    return this.localization.trackChanges(
      this.transactionService.transactionControllerInfo({ uuid }).pipe(
        map(response => ({
          data: this.mapper.mapFrom(response.data),
        })),
      ),
    );
  }

  private sortTransactionsByDate(transactions: TransactionModel[]): TransactionModel[] {
    return transactions.sort(
      (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );
  }
}
