import { ReverseMap } from 'core/base/utils/types';

export const FidQueryParam = {
  Search: 'search',
  Start: 'start',
  Limit: 'limit',
  Category: 'category',
  Provider: 'provider',
  Casino: 'casino',
  TxType: 'type',
  TxStatus: 'status',
  TxDateFrom: 'createdFrom',
  TxDateTo: 'createdTo',
  MId: 'mid',
  FluId: 'fluid',
  Games: 'games',
  Offers: 'offers',
} as const;

export type FidQueryParam = ReverseMap<typeof FidQueryParam>;
