import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CurrencyPipe, NgIf, NgOptimizedImage } from '@angular/common';
import { CasinoModel } from 'core/data/domain/casino.model';
import { TranslocoDirective } from '@ngneat/transloco';
import { CdnUrlPipe } from 'core/base/utils/cdn-url.pipe';
import { UserProjectModel } from 'features/user/model/user.model';
import { WalletFullModel } from 'core/data/domain/wallet.model';
import { XmasBackgroundComponent } from 'shared/components/xmas-background/xmas-background.component';

@Component({
  selector: 'fid-project-dialog-header',
  standalone: true,
  imports: [
    NgOptimizedImage,
    TranslocoDirective,
    NgIf,
    CdnUrlPipe,
    CurrencyPipe,
    XmasBackgroundComponent,
  ],
  templateUrl: './project-dialog-header.component.html',
  styleUrl: './project-dialog-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectDialogHeaderComponent {
  @Input() public casino!: CasinoModel & Partial<UserProjectModel>;
  @Input() public wallet?: WalletFullModel;
}
