/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { accountControllerGetAccountInfo } from '../fn/operations/account-controller-get-account-info';
import { AccountControllerGetAccountInfo$Params } from '../fn/operations/account-controller-get-account-info';
import { adjustControllerProcessCallback } from '../fn/operations/adjust-controller-process-callback';
import { AdjustControllerProcessCallback$Params } from '../fn/operations/adjust-controller-process-callback';
import { ApiResponseDto } from '../models/api-response-dto';
import { applicationControllerCheck } from '../fn/operations/application-controller-check';
import { ApplicationControllerCheck$Params } from '../fn/operations/application-controller-check';
import { appsflyerControllerCallbackRead } from '../fn/operations/appsflyer-controller-callback-read';
import { AppsflyerControllerCallbackRead$Params } from '../fn/operations/appsflyer-controller-callback-read';
import { appsflyerControllerCallbackWrite } from '../fn/operations/appsflyer-controller-callback-write';
import { AppsflyerControllerCallbackWrite$Params } from '../fn/operations/appsflyer-controller-callback-write';
import { clientControllerSettingsApplication } from '../fn/operations/client-controller-settings-application';
import { ClientControllerSettingsApplication$Params } from '../fn/operations/client-controller-settings-application';
import { contentControllerPromotionListTemp } from '../fn/operations/content-controller-promotion-list-temp';
import { ContentControllerPromotionListTemp$Params } from '../fn/operations/content-controller-promotion-list-temp';
import { dashboardControllerBrand } from '../fn/operations/dashboard-controller-brand';
import { DashboardControllerBrand$Params } from '../fn/operations/dashboard-controller-brand';
import { dashboardControllerMarket } from '../fn/operations/dashboard-controller-market';
import { DashboardControllerMarket$Params } from '../fn/operations/dashboard-controller-market';
import { dashboardControllerTotal } from '../fn/operations/dashboard-controller-total';
import { DashboardControllerTotal$Params } from '../fn/operations/dashboard-controller-total';
import { exchangeControllerCreate } from '../fn/operations/exchange-controller-create';
import { ExchangeControllerCreate$Params } from '../fn/operations/exchange-controller-create';
import { gatewayIdentControllerAuth } from '../fn/operations/gateway-ident-controller-auth';
import { GatewayIdentControllerAuth$Params } from '../fn/operations/gateway-ident-controller-auth';
import { gatewayReferonControllerActivityReport } from '../fn/operations/gateway-referon-controller-activity-report';
import { GatewayReferonControllerActivityReport$Params } from '../fn/operations/gateway-referon-controller-activity-report';
import { gatewayReferonControllerRegistrationReport } from '../fn/operations/gateway-referon-controller-registration-report';
import { GatewayReferonControllerRegistrationReport$Params } from '../fn/operations/gateway-referon-controller-registration-report';
import { guestControllerProfile } from '../fn/operations/guest-controller-profile';
import { GuestControllerProfile$Params } from '../fn/operations/guest-controller-profile';
import { guestControllerProfileIntegration } from '../fn/operations/guest-controller-profile-integration';
import { GuestControllerProfileIntegration$Params } from '../fn/operations/guest-controller-profile-integration';
import { guestControllerRegister } from '../fn/operations/guest-controller-register';
import { GuestControllerRegister$Params } from '../fn/operations/guest-controller-register';
import { infoControllerGetInfo } from '../fn/operations/info-controller-get-info';
import { InfoControllerGetInfo$Params } from '../fn/operations/info-controller-get-info';
import { InfoResponse } from '../models/info-response';
import { mailControllerSend } from '../fn/operations/mail-controller-send';
import { MailControllerSend$Params } from '../fn/operations/mail-controller-send';
import { paymentGatewayControllerActionInitWidget } from '../fn/operations/payment-gateway-controller-action-init-widget';
import { paymentGatewayControllerActionInitWidget_1 } from '../fn/operations/payment-gateway-controller-action-init-widget-1';
import { PaymentGatewayControllerActionInitWidget_1$Params } from '../fn/operations/payment-gateway-controller-action-init-widget-1';
import { paymentGatewayControllerActionInitWidget_2 } from '../fn/operations/payment-gateway-controller-action-init-widget-2';
import { PaymentGatewayControllerActionInitWidget_2$Params } from '../fn/operations/payment-gateway-controller-action-init-widget-2';
import { paymentGatewayControllerActionInitWidget_3 } from '../fn/operations/payment-gateway-controller-action-init-widget-3';
import { PaymentGatewayControllerActionInitWidget_3$Params } from '../fn/operations/payment-gateway-controller-action-init-widget-3';
import { PaymentGatewayControllerActionInitWidget$Params } from '../fn/operations/payment-gateway-controller-action-init-widget';
import { paymentGatewayControllerCallback } from '../fn/operations/payment-gateway-controller-callback';
import { paymentGatewayControllerCallback_1 } from '../fn/operations/payment-gateway-controller-callback-1';
import { PaymentGatewayControllerCallback_1$Params } from '../fn/operations/payment-gateway-controller-callback-1';
import { PaymentGatewayControllerCallback$Params } from '../fn/operations/payment-gateway-controller-callback';
import { ProfileIntegrationDataResponse } from '../models/profile-integration-data-response';
import { prometheusControllerIndex } from '../fn/operations/prometheus-controller-index';
import { PrometheusControllerIndex$Params } from '../fn/operations/prometheus-controller-index';
import { referonControllerSendReport } from '../fn/operations/referon-controller-send-report';
import { ReferonControllerSendReport$Params } from '../fn/operations/referon-controller-send-report';
import { RegisterDataResponse } from '../models/register-data-response';
import { socketControllerMessage } from '../fn/operations/socket-controller-message';
import { SocketControllerMessage$Params } from '../fn/operations/socket-controller-message';
import { staticPaymentControllerPayment } from '../fn/operations/static-payment-controller-payment';
import { staticPaymentControllerPayment_1 } from '../fn/operations/static-payment-controller-payment-1';
import { StaticPaymentControllerPayment_1$Params } from '../fn/operations/static-payment-controller-payment-1';
import { StaticPaymentControllerPayment$Params } from '../fn/operations/static-payment-controller-payment';
import { staticPaymentControllerReturn } from '../fn/operations/static-payment-controller-return';
import { StaticPaymentControllerReturn$Params } from '../fn/operations/static-payment-controller-return';
import { transactionControllerList } from '../fn/operations/transaction-controller-list';
import { TransactionControllerList$Params } from '../fn/operations/transaction-controller-list';
import { versionControllerInfo } from '../fn/operations/version-controller-info';
import { VersionControllerInfo$Params } from '../fn/operations/version-controller-info';

@Injectable({ providedIn: 'root' })
export class ApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `infoControllerGetInfo()` */
  static readonly InfoControllerGetInfoPath = '/api/info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `infoControllerGetInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  infoControllerGetInfo$Response(params?: InfoControllerGetInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiResponseDto & {
'data'?: InfoResponse;
}>> {
    return infoControllerGetInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `infoControllerGetInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  infoControllerGetInfo(params?: InfoControllerGetInfo$Params, context?: HttpContext): Observable<ApiResponseDto & {
'data'?: InfoResponse;
}> {
    return this.infoControllerGetInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiResponseDto & {
'data'?: InfoResponse;
}>): ApiResponseDto & {
'data'?: InfoResponse;
} => r.body)
    );
  }

  /** Path part for operation `accountControllerGetAccountInfo()` */
  static readonly AccountControllerGetAccountInfoPath = '/api/account/info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountControllerGetAccountInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountControllerGetAccountInfo$Response(params?: AccountControllerGetAccountInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return accountControllerGetAccountInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountControllerGetAccountInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountControllerGetAccountInfo(params?: AccountControllerGetAccountInfo$Params, context?: HttpContext): Observable<{
}> {
    return this.accountControllerGetAccountInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `adjustControllerProcessCallback()` */
  static readonly AdjustControllerProcessCallbackPath = '/api/adjust/callback';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adjustControllerProcessCallback()` instead.
   *
   * This method doesn't expect any request body.
   */
  adjustControllerProcessCallback$Response(params?: AdjustControllerProcessCallback$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adjustControllerProcessCallback(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adjustControllerProcessCallback$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adjustControllerProcessCallback(params?: AdjustControllerProcessCallback$Params, context?: HttpContext): Observable<void> {
    return this.adjustControllerProcessCallback$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `guestControllerRegister()` */
  static readonly GuestControllerRegisterPath = '/api/guest/register';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `guestControllerRegister()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  guestControllerRegister$Response(params?: GuestControllerRegister$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiResponseDto & {
'data'?: RegisterDataResponse;
}>> {
    return guestControllerRegister(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `guestControllerRegister$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  guestControllerRegister(params?: GuestControllerRegister$Params, context?: HttpContext): Observable<ApiResponseDto & {
'data'?: RegisterDataResponse;
}> {
    return this.guestControllerRegister$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiResponseDto & {
'data'?: RegisterDataResponse;
}>): ApiResponseDto & {
'data'?: RegisterDataResponse;
} => r.body)
    );
  }

  /** Path part for operation `guestControllerProfile()` */
  static readonly GuestControllerProfilePath = '/api/guest/profile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `guestControllerProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  guestControllerProfile$Response(params?: GuestControllerProfile$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return guestControllerProfile(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `guestControllerProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  guestControllerProfile(params?: GuestControllerProfile$Params, context?: HttpContext): Observable<void> {
    return this.guestControllerProfile$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `guestControllerProfileIntegration()` */
  static readonly GuestControllerProfileIntegrationPath = '/api/guest/profile/{integration}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `guestControllerProfileIntegration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  guestControllerProfileIntegration$Response(params: GuestControllerProfileIntegration$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiResponseDto & {
'data'?: ProfileIntegrationDataResponse;
}>> {
    return guestControllerProfileIntegration(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `guestControllerProfileIntegration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  guestControllerProfileIntegration(params: GuestControllerProfileIntegration$Params, context?: HttpContext): Observable<ApiResponseDto & {
'data'?: ProfileIntegrationDataResponse;
}> {
    return this.guestControllerProfileIntegration$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiResponseDto & {
'data'?: ProfileIntegrationDataResponse;
}>): ApiResponseDto & {
'data'?: ProfileIntegrationDataResponse;
} => r.body)
    );
  }

  /** Path part for operation `socketControllerMessage()` */
  static readonly SocketControllerMessagePath = '/api/socket/message';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `socketControllerMessage()` instead.
   *
   * This method doesn't expect any request body.
   */
  socketControllerMessage$Response(params?: SocketControllerMessage$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return socketControllerMessage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `socketControllerMessage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  socketControllerMessage(params?: SocketControllerMessage$Params, context?: HttpContext): Observable<void> {
    return this.socketControllerMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `staticPaymentControllerPayment()` */
  static readonly StaticPaymentControllerPaymentPath = '/api/static/payment/success';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `staticPaymentControllerPayment()` instead.
   *
   * This method doesn't expect any request body.
   */
  staticPaymentControllerPayment$Response(params?: StaticPaymentControllerPayment$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return staticPaymentControllerPayment(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `staticPaymentControllerPayment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  staticPaymentControllerPayment(params?: StaticPaymentControllerPayment$Params, context?: HttpContext): Observable<void> {
    return this.staticPaymentControllerPayment$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `staticPaymentControllerPayment_1()` */
  static readonly StaticPaymentControllerPayment_1Path = '/api/static/payment/fail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `staticPaymentControllerPayment_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  staticPaymentControllerPayment_1$Response(params?: StaticPaymentControllerPayment_1$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return staticPaymentControllerPayment_1(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `staticPaymentControllerPayment_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  staticPaymentControllerPayment_1(params?: StaticPaymentControllerPayment_1$Params, context?: HttpContext): Observable<void> {
    return this.staticPaymentControllerPayment_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `staticPaymentControllerReturn()` */
  static readonly StaticPaymentControllerReturnPath = '/api/static/payment/return';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `staticPaymentControllerReturn()` instead.
   *
   * This method doesn't expect any request body.
   */
  staticPaymentControllerReturn$Response(params?: StaticPaymentControllerReturn$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return staticPaymentControllerReturn(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `staticPaymentControllerReturn$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  staticPaymentControllerReturn(params?: StaticPaymentControllerReturn$Params, context?: HttpContext): Observable<void> {
    return this.staticPaymentControllerReturn$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `referonControllerSendReport()` */
  static readonly ReferonControllerSendReportPath = '/referon/send';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referonControllerSendReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  referonControllerSendReport$Response(params: ReferonControllerSendReport$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return referonControllerSendReport(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referonControllerSendReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referonControllerSendReport(params: ReferonControllerSendReport$Params, context?: HttpContext): Observable<void> {
    return this.referonControllerSendReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `gatewayReferonControllerRegistrationReport()` */
  static readonly GatewayReferonControllerRegistrationReportPath = '/gateway/referon/report/registration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gatewayReferonControllerRegistrationReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  gatewayReferonControllerRegistrationReport$Response(params: GatewayReferonControllerRegistrationReport$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return gatewayReferonControllerRegistrationReport(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gatewayReferonControllerRegistrationReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  gatewayReferonControllerRegistrationReport(params: GatewayReferonControllerRegistrationReport$Params, context?: HttpContext): Observable<{
}> {
    return this.gatewayReferonControllerRegistrationReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `gatewayReferonControllerActivityReport()` */
  static readonly GatewayReferonControllerActivityReportPath = '/gateway/referon/report/activity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gatewayReferonControllerActivityReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  gatewayReferonControllerActivityReport$Response(params: GatewayReferonControllerActivityReport$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return gatewayReferonControllerActivityReport(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gatewayReferonControllerActivityReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  gatewayReferonControllerActivityReport(params: GatewayReferonControllerActivityReport$Params, context?: HttpContext): Observable<void> {
    return this.gatewayReferonControllerActivityReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `gatewayIdentControllerAuth()` */
  static readonly GatewayIdentControllerAuthPath = '/gateway/ident/auth';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gatewayIdentControllerAuth()` instead.
   *
   * This method doesn't expect any request body.
   */
  gatewayIdentControllerAuth$Response(params?: GatewayIdentControllerAuth$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return gatewayIdentControllerAuth(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gatewayIdentControllerAuth$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  gatewayIdentControllerAuth(params?: GatewayIdentControllerAuth$Params, context?: HttpContext): Observable<{
}> {
    return this.gatewayIdentControllerAuth$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `mailControllerSend()` */
  static readonly MailControllerSendPath = '/backoffice/mail/send';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mailControllerSend()` instead.
   *
   * This method doesn't expect any request body.
   */
  mailControllerSend$Response(params?: MailControllerSend$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return mailControllerSend(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mailControllerSend$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mailControllerSend(params?: MailControllerSend$Params, context?: HttpContext): Observable<void> {
    return this.mailControllerSend$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `transactionControllerList()` */
  static readonly TransactionControllerListPath = '/backoffice/transaction/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transactionControllerList()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionControllerList$Response(params?: TransactionControllerList$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return transactionControllerList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `transactionControllerList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionControllerList(params?: TransactionControllerList$Params, context?: HttpContext): Observable<{
}> {
    return this.transactionControllerList$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `dashboardControllerTotal()` */
  static readonly DashboardControllerTotalPath = '/backoffice/dashboard/total';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dashboardControllerTotal()` instead.
   *
   * This method doesn't expect any request body.
   */
  dashboardControllerTotal$Response(params: DashboardControllerTotal$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return dashboardControllerTotal(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dashboardControllerTotal$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dashboardControllerTotal(params: DashboardControllerTotal$Params, context?: HttpContext): Observable<{
}> {
    return this.dashboardControllerTotal$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `dashboardControllerMarket()` */
  static readonly DashboardControllerMarketPath = '/backoffice/dashboard/market';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dashboardControllerMarket()` instead.
   *
   * This method doesn't expect any request body.
   */
  dashboardControllerMarket$Response(params: DashboardControllerMarket$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return dashboardControllerMarket(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dashboardControllerMarket$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dashboardControllerMarket(params: DashboardControllerMarket$Params, context?: HttpContext): Observable<{
}> {
    return this.dashboardControllerMarket$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `dashboardControllerBrand()` */
  static readonly DashboardControllerBrandPath = '/backoffice/dashboard/brand';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dashboardControllerBrand()` instead.
   *
   * This method doesn't expect any request body.
   */
  dashboardControllerBrand$Response(params: DashboardControllerBrand$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return dashboardControllerBrand(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dashboardControllerBrand$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dashboardControllerBrand(params: DashboardControllerBrand$Params, context?: HttpContext): Observable<{
}> {
    return this.dashboardControllerBrand$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `versionControllerInfo()` */
  static readonly VersionControllerInfoPath = '/backoffice/version/info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `versionControllerInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  versionControllerInfo$Response(params?: VersionControllerInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return versionControllerInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `versionControllerInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  versionControllerInfo(params?: VersionControllerInfo$Params, context?: HttpContext): Observable<{
}> {
    return this.versionControllerInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `clientControllerSettingsApplication()` */
  static readonly ClientControllerSettingsApplicationPath = '/api/client/settings/application';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientControllerSettingsApplication()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientControllerSettingsApplication$Response(params?: ClientControllerSettingsApplication$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return clientControllerSettingsApplication(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientControllerSettingsApplication$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientControllerSettingsApplication(params?: ClientControllerSettingsApplication$Params, context?: HttpContext): Observable<{
}> {
    return this.clientControllerSettingsApplication$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `paymentGatewayControllerActionInitWidget()` */
  static readonly PaymentGatewayControllerActionInitWidgetPath = '/api/paymentGateway/initWidget';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentGatewayControllerActionInitWidget()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentGatewayControllerActionInitWidget$Response(params: PaymentGatewayControllerActionInitWidget$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return paymentGatewayControllerActionInitWidget(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentGatewayControllerActionInitWidget$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentGatewayControllerActionInitWidget(params: PaymentGatewayControllerActionInitWidget$Params, context?: HttpContext): Observable<void> {
    return this.paymentGatewayControllerActionInitWidget$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `paymentGatewayControllerActionInitWidget_1()` */
  static readonly PaymentGatewayControllerActionInitWidget_1Path = '/api/paymentGateway/init-widget';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentGatewayControllerActionInitWidget_1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentGatewayControllerActionInitWidget_1$Response(params: PaymentGatewayControllerActionInitWidget_1$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return paymentGatewayControllerActionInitWidget_1(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentGatewayControllerActionInitWidget_1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentGatewayControllerActionInitWidget_1(params: PaymentGatewayControllerActionInitWidget_1$Params, context?: HttpContext): Observable<void> {
    return this.paymentGatewayControllerActionInitWidget_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `paymentGatewayControllerActionInitWidget_2()` */
  static readonly PaymentGatewayControllerActionInitWidget_2Path = '/api/payment-gateway/initWidget';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentGatewayControllerActionInitWidget_2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentGatewayControllerActionInitWidget_2$Response(params: PaymentGatewayControllerActionInitWidget_2$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return paymentGatewayControllerActionInitWidget_2(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentGatewayControllerActionInitWidget_2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentGatewayControllerActionInitWidget_2(params: PaymentGatewayControllerActionInitWidget_2$Params, context?: HttpContext): Observable<void> {
    return this.paymentGatewayControllerActionInitWidget_2$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `paymentGatewayControllerActionInitWidget_3()` */
  static readonly PaymentGatewayControllerActionInitWidget_3Path = '/api/payment-gateway/init-widget';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentGatewayControllerActionInitWidget_3()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentGatewayControllerActionInitWidget_3$Response(params: PaymentGatewayControllerActionInitWidget_3$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return paymentGatewayControllerActionInitWidget_3(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentGatewayControllerActionInitWidget_3$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentGatewayControllerActionInitWidget_3(params: PaymentGatewayControllerActionInitWidget_3$Params, context?: HttpContext): Observable<void> {
    return this.paymentGatewayControllerActionInitWidget_3$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `paymentGatewayControllerCallback()` */
  static readonly PaymentGatewayControllerCallbackPath = '/paymentGateway/callback';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentGatewayControllerCallback()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentGatewayControllerCallback$Response(params?: PaymentGatewayControllerCallback$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return paymentGatewayControllerCallback(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentGatewayControllerCallback$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentGatewayControllerCallback(params?: PaymentGatewayControllerCallback$Params, context?: HttpContext): Observable<void> {
    return this.paymentGatewayControllerCallback$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `paymentGatewayControllerCallback_1()` */
  static readonly PaymentGatewayControllerCallback_1Path = '/payment-gateway/callback';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentGatewayControllerCallback_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentGatewayControllerCallback_1$Response(params?: PaymentGatewayControllerCallback_1$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return paymentGatewayControllerCallback_1(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentGatewayControllerCallback_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentGatewayControllerCallback_1(params?: PaymentGatewayControllerCallback_1$Params, context?: HttpContext): Observable<void> {
    return this.paymentGatewayControllerCallback_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `applicationControllerCheck()` */
  static readonly ApplicationControllerCheckPath = '/api/application/update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `applicationControllerCheck()` instead.
   *
   * This method doesn't expect any request body.
   */
  applicationControllerCheck$Response(params: ApplicationControllerCheck$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return applicationControllerCheck(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `applicationControllerCheck$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  applicationControllerCheck(params: ApplicationControllerCheck$Params, context?: HttpContext): Observable<void> {
    return this.applicationControllerCheck$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `prometheusControllerIndex()` */
  static readonly PrometheusControllerIndexPath = '/metrics';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `prometheusControllerIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  prometheusControllerIndex$Response(params?: PrometheusControllerIndex$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return prometheusControllerIndex(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `prometheusControllerIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  prometheusControllerIndex(params?: PrometheusControllerIndex$Params, context?: HttpContext): Observable<void> {
    return this.prometheusControllerIndex$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `exchangeControllerCreate()` */
  static readonly ExchangeControllerCreatePath = '/api/exchange/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exchangeControllerCreate()` instead.
   *
   * This method doesn't expect any request body.
   */
  exchangeControllerCreate$Response(params?: ExchangeControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return exchangeControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exchangeControllerCreate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exchangeControllerCreate(params?: ExchangeControllerCreate$Params, context?: HttpContext): Observable<void> {
    return this.exchangeControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `appsflyerControllerCallbackRead()` */
  static readonly AppsflyerControllerCallbackReadPath = '/api/appsflyer/callback';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appsflyerControllerCallbackRead()` instead.
   *
   * This method doesn't expect any request body.
   */
  appsflyerControllerCallbackRead$Response(params?: AppsflyerControllerCallbackRead$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return appsflyerControllerCallbackRead(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `appsflyerControllerCallbackRead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  appsflyerControllerCallbackRead(params?: AppsflyerControllerCallbackRead$Params, context?: HttpContext): Observable<void> {
    return this.appsflyerControllerCallbackRead$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `appsflyerControllerCallbackWrite()` */
  static readonly AppsflyerControllerCallbackWritePath = '/api/appsflyer/callback';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appsflyerControllerCallbackWrite()` instead.
   *
   * This method doesn't expect any request body.
   */
  appsflyerControllerCallbackWrite$Response(params?: AppsflyerControllerCallbackWrite$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return appsflyerControllerCallbackWrite(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `appsflyerControllerCallbackWrite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  appsflyerControllerCallbackWrite(params?: AppsflyerControllerCallbackWrite$Params, context?: HttpContext): Observable<void> {
    return this.appsflyerControllerCallbackWrite$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `contentControllerPromotionListTemp()` */
  static readonly ContentControllerPromotionListTempPath = '/api/content/promotion/brand-connect';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contentControllerPromotionListTemp()` instead.
   *
   * This method doesn't expect any request body.
   */
  contentControllerPromotionListTemp$Response(params: ContentControllerPromotionListTemp$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return contentControllerPromotionListTemp(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contentControllerPromotionListTemp$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contentControllerPromotionListTemp(params: ContentControllerPromotionListTemp$Params, context?: HttpContext): Observable<void> {
    return this.contentControllerPromotionListTemp$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
