import { DestroyRef, inject, Injectable, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { CasinoModel } from 'core/data/domain/casino.model';
import {
  UserProjectFullModel,
  UserProjectModel,
  UserProjectResponseModel,
} from 'features/user/model/user.model';
import { CasinoRepository } from 'core/data/repository/casino/casino.repository';
import { UserMapper } from 'core/data/repository/user/user.mapper';
import { UserService } from 'core/fapi/services/user.service';
import { LocalizationService } from 'core/features/localization/services/localization.service';
import { combineLatest, map, Observable, take, tap } from 'rxjs';
import { AuthStorageService } from 'features/auth/services/auth-storage.service';
import { filter } from 'rxjs/operators';
import { ConnectedCasinoCard } from 'core/base/configs/mock-data.config';

@Injectable({
  providedIn: 'root',
})
export class UserRepository {
  private readonly mapper: UserMapper = inject(UserMapper);
  private readonly destroyRef = inject(DestroyRef);
  private readonly casino: CasinoRepository = inject(CasinoRepository);
  private readonly userService: UserService = inject(UserService);
  private readonly localization: LocalizationService = inject(LocalizationService);
  private readonly authStorageService: AuthStorageService = inject(AuthStorageService);

  private userConnectedProjects: WritableSignal<UserProjectFullModel[]> = signal([]);
  private userConnectedProjectsExtended: WritableSignal<UserProjectFullModel[]> = signal([]);
  private userNotConnectedProjects: WritableSignal<CasinoModel[]> = signal([]);

  public userConnectedProjects$: Observable<UserProjectFullModel[]> = toObservable(
    this.userConnectedProjects,
  );
  public userConnectedProjectsExtended$: Observable<UserProjectFullModel[]> = toObservable(
    this.userConnectedProjectsExtended,
  );
  public userNotConnectedProjects$: Observable<CasinoModel[]> = toObservable(
    this.userNotConnectedProjects,
  );

  constructor() {
    this.authStorageService.storage$
      .pipe(
        filter(item => Boolean(item.authToken)),
        tap(() => {
          this.refreshUserConnectedProjects();
          this.refreshUserNotConnectedProjects();
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  public getUserProjectResponse(): Observable<UserProjectResponseModel> {
    return this.localization.trackChanges(
      this.userService
        .userControllerProjectList()
        .pipe(map(response => this.mapper.mapFromResponse(response))),
    );
  }

  public getUserProjectList(): Observable<UserProjectModel[]> {
    return this.getUserProjectResponse().pipe(map(response => response.data));
  }

  private getUserConnectedProjects(): Observable<UserProjectFullModel[]> {
    return combineLatest([this.casino.getList(), this.getUserProjectList()]).pipe(
      map(([casinos, projects]) =>
        projects.reduce<UserProjectFullModel[]>((prev, cur) => {
          const casino = casinos.find(
            casino => casino.domain?.toLowerCase() === cur.domain.toLowerCase(),
          );
          if (casino) {
            prev.push({ ...casino, ...cur });
          }

          return prev;
        }, []),
      ),
      tap((userProjects: UserProjectFullModel[]) => {
        this.userConnectedProjects.set(userProjects || []);
        this.userConnectedProjectsExtended.set([...userProjects, ConnectedCasinoCard]);
      }),
    );
  }

  public getUserNotConnectedProjects(): Observable<CasinoModel[]> {
    return combineLatest([this.casino.getList(), this.getUserProjectList()]).pipe(
      map(([casinos, projects]) => {
        const connected = new Set(projects.map(({ domain }) => domain.toLowerCase()));
        return casinos.filter(
          ({ domain }) => !(domain && connected.has(domain.toLocaleLowerCase())),
        );
      }),
      tap((projects: CasinoModel[]) => {
        this.userNotConnectedProjects.set(projects || []);
      }),
    );
  }

  public refreshUserNotConnectedProjects(): void {
    this.getUserNotConnectedProjects()
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  public refreshUserConnectedProjects(): void {
    this.getUserConnectedProjects().pipe(take(1), takeUntilDestroyed(this.destroyRef)).subscribe();
  }
}
