import { Injectable } from '@angular/core';
import { Mapper } from 'core/base/model/mapper.model';
import { LogoEntity } from 'core/data/common/logo/logo.entity';
import { LogoModel } from 'core/data/common/logo/logo.model';

@Injectable({
  providedIn: 'root',
})
export class LogoMapper implements Mapper<LogoEntity, LogoModel> {
  public mapFrom(param: LogoEntity): LogoModel {
    return { ...param?.data?.attributes };
  }
}
