import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { DialogCloseService } from 'core/features/dialog/services/dialog-close.service';
import { ProjectChooseListComponent } from 'features/project/components/project-choose-list/project-choose-list.component';
import { TranslocoDirective } from '@ngneat/transloco';
import { DialogData } from 'features/project/model/project.model';

@Component({
  selector: 'fid-project-choose-dialog',
  standalone: true,
  imports: [ProjectChooseListComponent, TranslocoDirective],
  templateUrl: './project-choose-dialog.component.html',
  styleUrl: './project-choose-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectChooseDialogComponent implements OnInit {
  private dialogClose: DialogCloseService = inject(DialogCloseService);

  public dialogData: DialogData | null = null;

  public ngOnInit(): void {
    if (!this.dialogData) {
      this.close();
    }
  }

  public close(): void {
    this.dialogClose.close();
  }
}
