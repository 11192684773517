<div *transloco="let t" class="form" [formGroup]="formGroup">
  <div class="form--group">
    <div class="form--row">
      <div class="form--field">
        <tui-input [formControlName]="'email'" [tuiTextfieldLabelOutside]="true">{{
          t('form.email')
        }}</tui-input>
        <tui-error [formControlName]="'email'" [error]="[] | tuiFieldError | async" />
      </div>
    </div>
    <div class="form--row">
      <div class="form--field">
        <tui-input [formControlName]="'name'" [tuiTextfieldLabelOutside]="true">{{
          t('form.firstName')
        }}</tui-input>
        <tui-error [formControlName]="'name'" [error]="[] | tuiFieldError | async" />
      </div>
    </div>
    <div class="form--row">
      <div class="form--field">
        <tui-input [formControlName]="'surname'" [tuiTextfieldLabelOutside]="true">
          {{ t('form.lastName') }}
        </tui-input>
        <tui-error [formControlName]="'surname'" [error]="[] | tuiFieldError | async" />
      </div>
    </div>
    <div class="form--row">
      <div class="form--field">
        <tui-input-date
          class="date-field"
          [formControlName]="'birthday'"
          [tuiTextfieldLabelOutside]="true"
          [tuiTextfieldIcon]="null"
          [max]="maxBirthdayDate"
          >{{ t('form.dateOfBirth') }}</tui-input-date
        >
        <tui-error [formControlName]="'birthday'" [error]="[] | tuiFieldError | async" />
      </div>
    </div>
    <div class="form--row">
      <div class="form--switcher" [collapsed]="true" tuiGroup>
        <tui-radio-block [formControlName]="'gender'" [hideRadio]="true" [item]="'male'">
          {{ t('form.male') }}
        </tui-radio-block>
        <tui-radio-block [formControlName]="'gender'" [hideRadio]="true" [item]="'female'">
          {{ t('form.female') }}
        </tui-radio-block>
      </div>
      <tui-error [formControlName]="'gender'" [error]="[] | tuiFieldError | async" />
    </div>
  </div>
</div>
