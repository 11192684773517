import { DestroyRef, inject, Injectable, Injector } from '@angular/core';
import { TuiAlertService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { AlertConfig } from 'core/features/alerts/constants/alert.constants';
import { AlertComponent } from 'core/features/alerts/components/alert/alert.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslocoService } from '@ngneat/transloco';

/**
 * t(global.alert.oops)
 */
export const AlertPlaceholderTitle = 'global.alert.oops';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private readonly alerts: TuiAlertService = inject(TuiAlertService);
  private readonly injector: Injector = inject(Injector);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly transLoco: TranslocoService = inject(TranslocoService);

  public show(config: AlertConfig): void {
    this.alerts
      .open(new PolymorpheusComponent(AlertComponent, this.injector), {
        data: {
          status: config.status,
          title: this.transLoco.translate(config.title || AlertPlaceholderTitle),
          text: config.text ? this.transLoco.translate(config.text) : null,
          image: config.image,
          imageBgColor: config.imageBgColor,
        },
        status: config.status,
        hasIcon: false,
      })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }
}
