import { CHAR_PLUS, CHAR_HYPHEN, tuiObjectFromEntries } from '@taiga-ui/cdk';
import { TUI_DIGIT_REGEXP } from '@taiga-ui/core';
const TUI_DATE_MODE_MASKITO_ADAPTER = {
  DMY: 'dd/mm/yyyy',
  MDY: 'mm/dd/yyyy',
  YMD: 'yyyy/mm/dd'
};
const DATE_TIME_SEPARATOR = ', ';

/**
 * @deprecated Use {@link https://github.com/taiga-family/maskito Maskito} instead
 * TODO: delete in v4.0
 */
const EMPTY_MASK = {
  mask() {
    return false;
  }
};

/**
 * @deprecated unused constant
 * TODO: delete in v4.0
 */
const GROUP_CLASS_NAMES = ['_active', '_disabled', '_focused', '_focus-visible', '_hosted_dropdown_focused', 'ng-invalid', 'ng-touched', '_pressed', '_readonly'];
const MASK_AFTER_CODE_REGEXP = /\([#]+\)|[#\- ]/g;
const TUI_PHONE_MASK = [CHAR_PLUS, '7', ' ', TUI_DIGIT_REGEXP, TUI_DIGIT_REGEXP, TUI_DIGIT_REGEXP, ' ', TUI_DIGIT_REGEXP, TUI_DIGIT_REGEXP, TUI_DIGIT_REGEXP, CHAR_HYPHEN, TUI_DIGIT_REGEXP, TUI_DIGIT_REGEXP, CHAR_HYPHEN, TUI_DIGIT_REGEXP, TUI_DIGIT_REGEXP];
/**
 * @deprecated Use {@link https://maskito.dev/kit/time Time} from {@link https://github.com/taiga-family/maskito Maskito} instead
 * TODO: delete in v4.0
 */
const TUI_TIME_MASK = [TUI_DIGIT_REGEXP, TUI_DIGIT_REGEXP, ':', TUI_DIGIT_REGEXP, TUI_DIGIT_REGEXP];

/**
 * Used as a limit for eliminating JS issues with floating point math
 */
const TUI_FLOATING_PRECISION = 7;
const MAX_DAY_RANGE_LENGTH_MAPPER = (min, value, maxLength, backwards) => {
  if (!(value === null || value === void 0 ? void 0 : value.isSingleDay) || !maxLength) {
    return min;
  }
  const negativeMaxLength = tuiObjectFromEntries(Object.entries(maxLength).map(([key, value]) => [key, -value]));
  const dateShift = value.from.append(backwards ? negativeMaxLength : maxLength).append({
    day: !backwards ? -1 : 1
  });
  if (backwards) {
    return dateShift.dayBefore(min) ? min : dateShift;
  }
  if (!min) {
    return dateShift;
  }
  return dateShift.dayAfter(min) ? min : dateShift;
};
const MAX_TIME_VALUES = {
  HH: 23,
  MM: 59,
  SS: 59,
  MS: 999
};
const DEFAULT_ROUTER_LINK_OPTIONS = {
  fragment: '',
  preserveFragment: true,
  replaceUrl: false,
  skipLocationChange: false,
  queryParams: null,
  useHref: false,
  target: null
};

/**
 * Generated bundle index. Do not edit.
 */

export { DATE_TIME_SEPARATOR, DEFAULT_ROUTER_LINK_OPTIONS, EMPTY_MASK, GROUP_CLASS_NAMES, MASK_AFTER_CODE_REGEXP, MAX_DAY_RANGE_LENGTH_MAPPER, MAX_TIME_VALUES, TUI_DATE_MODE_MASKITO_ADAPTER, TUI_FLOATING_PRECISION, TUI_PHONE_MASK, TUI_TIME_MASK };
