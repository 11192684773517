import { inject, Injectable } from '@angular/core';
import { DefaultListRequestOptions, ListRequestOptions } from 'core/base/model/list-options.model';
import { GetAvatarsId$Params } from 'core/capi/fn/avatar/get-avatars-id';
import { AvatarResponse } from 'core/capi/models';
import { AvatarListResponse } from 'core/capi/models/avatar-list-response';
import { AvatarService } from 'core/capi/services/avatar.service';
import { AvatarModel, AvatarsResponseModel } from 'core/data/domain/avatar.model';
import { AvatarMapper } from 'core/data/repository/avatar/avatar.mapper';
import { LocalizationService } from 'core/features/localization/services/localization.service';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AvatarRepository {
  private readonly mapper: AvatarMapper = inject(AvatarMapper);
  private readonly localization: LocalizationService = inject(LocalizationService);
  private readonly avatarService: AvatarService = inject(AvatarService);

  private populate = {
    '0': 'image,backgroundColor',
  };

  public getListResponse(
    options: ListRequestOptions = DefaultListRequestOptions,
  ): Observable<AvatarsResponseModel> {
    return this.localization.trackChanges(
      this.avatarService
        .getAvatars({
          'pagination[limit]': options.limit ? Number(options.limit) : undefined,
          populate: this.populate,
        })
        .pipe(map((response: AvatarListResponse) => this.mapper.mapFromResponse(response))),
    );
  }

  public getList(options?: ListRequestOptions): Observable<AvatarModel[]> {
    return this.getListResponse(options).pipe(map(response => response.data));
  }

  public getAvatar(id: GetAvatarsId$Params['id']): Observable<AvatarModel> {
    return this.localization.trackChanges(
      this.avatarService
        .getAvatarsId({ id, populate: this.populate })
        .pipe(map((response: AvatarResponse) => this.mapper.mapFromAvatarResponse(response))),
    );
  }
}
