import { inject, Injectable } from '@angular/core';
import { DiscoveryPageResponse } from 'core/capi/models/discovery-page-response';
import { DiscoveryPageService } from 'core/capi/services';
import { PopulationService } from 'core/base/services/population.service';
import { DiscoveryPageModel } from 'core/data/domain/discovery.model';
import { DiscoveryMapper } from 'core/data/repository/discovery/discovery.mapper';
import { LocalizationService } from 'core/features/localization/services/localization.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DiscoveryRepository {
  private readonly discoveryApi: DiscoveryPageService = inject(DiscoveryPageService);
  private readonly population: PopulationService = inject(PopulationService);
  private readonly mapper: DiscoveryMapper = inject(DiscoveryMapper);
  private readonly localization: LocalizationService = inject(LocalizationService);

  public getPageResponse(): Observable<DiscoveryPageModel> {
    const populateMainBanner = this.population.populate('mainBanner', [
      'videoDesktop',
      'videoMobile',
    ]);
    const populateTopCasinos = this.population.populate('topCasinos', [
      'logo',
      'icon',
      'animation',
      'casinoCategory',
    ]);
    const populateActiveTournaments = this.population.populate('activeTournaments', [
      'label',
      'name',
      'textGradient',
      'backgroundGradient',
      'reward',
      'image',
      'casino',
      'casino.icon',
      'casino.name',
      'casino.image',
      'casino.casinoCategory',
    ]);
    const populateMostPlayed = this.population.populate('mostPlayed', [
      'id',
      'name',
      'image',
      'gameProvider',
      'gameProvider.icon',
    ]);
    const populateJackpotBanner = this.population.populate('jackpotBanner', [
      'title',
      'buttonText',
      'video',
      'amount',
    ]);
    const populateLiveCasinos = this.population.populate('liveCasino', [
      'id',
      'name',
      'image',
      'gameProvider',
      'gameProvider.icon',
    ]);
    const populateSpecialFeatures = this.population.populate('specialFeatures', [
      'backgroundGradient',
      'label',
      'name',
      'textGradient',
      'image',
      'casino',
      'casino.icon',
      'casino.name',
    ]);
    const populateNewGames = this.population.populate('newGames', [
      'id',
      'name',
      'image',
      'gameProvider',
      'gameProvider.icon',
    ]);
    const populateCrabBanner = this.population.populate('crabBanner', [
      'title',
      'buttonText',
      'image',
      'game',
      'game.gameProvider',
      'game.gameProvider.icon',
    ]);
    const populateSpecialOffers = this.population.populate('specialOffers', [
      'id',
      'name',
      'offer',
      'description',
      'image',
      'casino',
      'casino.icon',
      'offerDetails',
    ]);
    const populateSportCategories = this.population.populate('sportCategories', [
      'name',
      'sports',
      'sports.ATeamIcon',
      'sports.BTeamIcon',
      'sports.sportCategory',
    ]);
    const populateSportEvents = this.population.populate('sportEvents', [
      'ATeamIcon',
      'BTeamIcon',
      'sportCategory',
    ]);
    const populateTopSports = this.population.populate('topSports', [
      'ATeamIcon',
      'BTeamIcon',
      'sportCategory',
    ]);
    const populateHowItWorks = this.population.populate('hiws', ['image,steps,steps.image']);

    return this.localization.trackChanges(
      this.discoveryApi
        .getDiscoveryPage({
          populate: {
            '0': `
            ${populateMainBanner},
            ${populateTopCasinos},
            ${populateActiveTournaments},
            ${populateMostPlayed},
            ${populateJackpotBanner},
            ${populateLiveCasinos},
            ${populateSpecialFeatures},
            ${populateNewGames},
            ${populateCrabBanner},
            ${populateSpecialOffers},
            ${populateSportCategories},
            ${populateSportEvents},
            ${populateTopSports},
            ${populateHowItWorks}
          `,
          },
        })
        .pipe(map((response: DiscoveryPageResponse) => this.mapper.mapFromResponse(response))),
    );
  }
}
