<div
  *transloco="let t"
  class="brand-dialog-header"
  [attr.style]="casino.gradient ? '--bgGradient:' + casino.gradient : ''"
>
  <fid-xmas-background [density]="0.2"></fid-xmas-background>

  <img
    *ngIf="casino.icon.url as url"
    class="brand-dialog-header--image"
    [alt]="casino.icon.alternativeText"
    [ngSrc]="url | cdnUrl"
    width="60"
    height="60"
  />
  <div class="brand-dialog-header--content">
    <p class="brand-dialog-header--title">{{ casino.name }}</p>
    <ng-container *ngIf="wallet && wallet.balance >= 0; else balanceTemplate">
      <p class="brand-dialog-header--text">{{ t('dialog.project.currentBalance') }}</p>
      <p class="brand-dialog-header--offer">
        {{ wallet.balance || 0 | currency: wallet.currency }}
      </p>
    </ng-container>
    <ng-template #balanceTemplate>
      <p class="brand-dialog-header--text">{{ t('dialog.project.funIdOffer') }}</p>
      <p class="brand-dialog-header--offer">{{ casino.offer }}</p>
    </ng-template>
  </div>
</div>
