import { Injectable } from '@angular/core';
import { ListMapper, Mapper } from 'core/base/model/mapper.model';
import { OfferDetailsEntity } from 'core/data/common/offer-details/offer-details.entity';
import { OfferDetailsModel } from 'core/data/common/offer-details/offer-details.model';

@Injectable({
  providedIn: 'root',
})
export class OfferDetailsMapper
  implements
    Mapper<OfferDetailsEntity, OfferDetailsModel>,
    ListMapper<OfferDetailsEntity, OfferDetailsModel>
{
  public mapFromList(params: OfferDetailsEntity[]): OfferDetailsModel[] {
    return params.map(this.mapFrom);
  }

  public mapFrom(param: OfferDetailsEntity): OfferDetailsModel {
    return { ...param };
  }
}
