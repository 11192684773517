import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  inject,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TuiButtonModule } from '@taiga-ui/core';
import { TuiProgressSegmentedModule } from '@taiga-ui/experimental';
import { TuiCarouselModule, TuiProgressModule } from '@taiga-ui/kit';
import { CdnUrlPipe } from 'core/base/utils/cdn-url.pipe';
import { HowItWorksModel } from 'core/data/domain/how-it-works.model';
import { HowItWorksRepository } from 'core/data/repository/how-it-works/how-it-works.repository';
import { Observable, shareReplay } from 'rxjs';
import { LoaderComponent } from 'shared/components/loader/loader.component';
import { CarouselComponent } from 'shared/components/carousel/carousel.component';
import { CAROUSEL_CONFIG, CarouselConfigFactory } from 'shared/components/carousel/carousel.config';
import { DialogCloseService } from 'core/features/dialog/services/dialog-close.service';
import { AmpEventsName, AmplitudeService } from 'shared/services/amplitude.service';

const CarouselInterval = 5000;

@Component({
  selector: 'fid-how-it-works-dialog',
  standalone: true,
  imports: [
    CommonModule,
    TuiButtonModule,
    TuiCarouselModule,
    LoaderComponent,
    TuiProgressModule,
    TuiProgressSegmentedModule,
    CdnUrlPipe,
    NgOptimizedImage,
    CarouselComponent,
  ],
  templateUrl: './how-it-works-dialog.component.html',
  styleUrl: './how-it-works-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: CAROUSEL_CONFIG,
      useValue: CarouselConfigFactory.create({
        slidesRowsCount: 1,
        slidesPerView: 1,
        pagination: {
          enabled: true,
          clickable: true,
          el: '.progress',
          bulletClass: 'progress--step',
          bulletActiveClass: '_active',
        },
        autoplay: {
          delay: CarouselInterval,
          stopOnLastSlide: true,
        },
      }),
    },
  ],
})
export class HowItWorksDialogComponent implements AfterViewInit {
  private readonly dialogClose: DialogCloseService = inject(DialogCloseService);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly howItWorks: HowItWorksRepository = inject(HowItWorksRepository);
  private readonly amplitude: AmplitudeService = inject(AmplitudeService);

  protected readonly carouselTimerConfig = CarouselInterval;

  @ViewChild('carousel') public carousel!: CarouselComponent<unknown>;

  protected readonly howItWorksItem$: Observable<HowItWorksModel> = this.howItWorks
    .getItem({
      id: this.activatedRoute.snapshot.params['id'],
    })
    .pipe(shareReplay());

  protected nextSlide(slidesLength: number): void {
    if (slidesLength - 1 === this.carousel.getActiveIndex()) {
      this.amplitude.send(AmpEventsName.HowToStoryViewed, {
        storyIndex: 3,
        time: this.carouselTimerConfig,
        source: 'checkUrl',
      });

      this.dialogClose.close();
    } else {
      this.carousel.next();
    }
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.carousel.sliderRef.nativeElement.swiper.on('slideChange', () => {
        this.amplitude.send(AmpEventsName.HowToStoryViewed, {
          storyIndex: this.carousel.getActiveIndex(),
          time: this.carouselTimerConfig,
          source: 'checkUrl',
        });
      });
    }, 1000);
  }
}
