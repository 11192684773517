import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertStatus } from 'core/features/alerts/constants/alert.constants';
import { AlertService } from 'core/features/alerts/services/alert.service';

@Injectable({
  providedIn: 'root',
})
export class DialogCloseService {
  private readonly router: Router = inject(Router);
  private readonly alert: AlertService = inject(AlertService);

  public close(): void {
    setTimeout(() => {
      this.router.navigate(['', { outlets: { dialog: null } }], {
        queryParamsHandling: 'preserve',
      });
    }, 0);
  }

  public errorClose(): void {
    setTimeout(() => {
      this.alert.show({ status: AlertStatus.Error });
      this.router.navigate(['', { outlets: { dialog: null } }], {
        queryParamsHandling: 'preserve',
      });
    }, 0);
  }
}
