import { ImageModel } from 'core/data/common/image/image.model';

export enum AlertStatus {
  Error = 'error',
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Neutral = 'neutral',
}

export enum AlertIconUrl {
  error = '/assets/images/alert/error.jpg',
  info = '/assets/images/alert/info.jpg',
  success = '/assets/images/alert/success.jpg',
  warning = '/assets/images/alert/warning.jpg',
  neutral = '/assets/images/logo.png',
}

export interface AlertConfig {
  status: AlertStatus;
  title?: string;
  text?: string;
  image?: ImageModel;
  imageBgColor?: string;
}

export interface AlertContent {
  status: AlertStatus;
  title?: string;
  text?: string;
  image?: ImageModel;
  imageBgColor?: string;
}
