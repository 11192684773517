import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { CdnUrlPipe } from 'core/base/utils/cdn-url.pipe';

@Component({
  selector: 'fid-filter-dialog-item',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, CdnUrlPipe],
  templateUrl: './filter-dialog-item.component.html',
  styleUrl: './filter-dialog-item.component.scss',
})
export class FilterDialogItemComponent {
  @Input({ required: true }) public name!: string;
  @Input({ required: true }) public url!: string;
  @Input({ required: true }) public alternativeText!: string;
  @Input({ required: true }) public width!: number;
  @Input({ required: true }) public height!: number;
  @Input({ required: true }) public count!: number;
  @HostBinding('class.is-active') @Input() public isActive: boolean = false;
}
