<ng-container *transloco="let t">
  <tui-primitive-textfield
    #input
    class="search-list-field"
    [(value)]="value"
    [tuiTextfieldLabelOutside]="tuiTextfieldLabelOutside"
    [tuiTextfieldIconLeft]="'fidSearchBlue'"
    [tuiTextfieldSize]="'m'"
    [tuiAutoFocus]="true"
    (keydown.arrowDown.prevent)="onArrowDown(list, $event)"
  ></tui-primitive-textfield>

  <tui-data-list
    #list
    [emptyContent]="t('global.text.nofound')"
    (keydown)="onKeyDown($event.key, input.nativeFocusableElement)"
  >
    <tui-opt-group *ngFor="let item of items; trackBy: 'name' | trackByProp">
      <button
        *ngIf="(item.name | includes: [value]) || (item.code | includes: [value])"
        type="button"
        class="search-list-item"
        [value]="item.code"
        tuiOption
      >
        {{ item.name }}
      </button>
    </tui-opt-group>
  </tui-data-list>
</ng-container>
