import {
  AsyncPipe,
  CurrencyPipe,
  DatePipe,
  LowerCasePipe,
  NgIf,
  NgOptimizedImage,
} from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiSvgModule } from '@taiga-ui/core';
import {
  TransactionModel,
  TransactionStatus,
  TransactionType,
} from 'core/data/domain/transaction.model';
import { TransactionRepository } from 'core/data/repository/transaction/transaction.repository';
import { map } from 'rxjs';
import { LoaderComponent } from 'shared/components/loader/loader.component';
import { ProjectStaticData } from 'features/user/constants/user.constants';
import { TransactionKeys } from 'features/transactions/constants/transaction.constants';
import { CdnUrlPipe } from 'core/base/utils/cdn-url.pipe';
import { ClipboardDirective } from 'features/auth/directives/clipboard.directive';
import { OpenChatDirective } from 'shared/directives/open-chat.directive';
import { AmpEventsName } from 'shared/services/amplitude.service';
import { SendAnalyticsDirective } from 'shared/directives/send-analytics.directive';

type PaymentData = {
  cardMask: string;
};

type TransactionFullModel = TransactionModel & { payment?: PaymentData };

@Component({
  selector: 'fid-transaction-dialog',
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
    TranslocoDirective,
    TuiSvgModule,
    LoaderComponent,
    CurrencyPipe,
    LowerCasePipe,
    DatePipe,
    LoaderComponent,
    CdnUrlPipe,
    NgOptimizedImage,
    ClipboardDirective,
    OpenChatDirective,
    SendAnalyticsDirective,
  ],
  templateUrl: './transaction-dialog.component.html',
  styleUrl: './transaction-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionDialogComponent {
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly transactionRepository = inject(TransactionRepository);

  protected readonly projectStaticData = ProjectStaticData;
  protected readonly transactionStatus = TransactionStatus;
  protected readonly transactionType = TransactionType;
  protected readonly transactionKeys = TransactionKeys;
  protected currentTransaction?: TransactionFullModel;
  protected readonly ampEventsName = AmpEventsName;

  protected readonly transaction$ = this.transactionRepository
    .getInfoResponse(this.activatedRoute.snapshot.params['id'])
    .pipe(
      map(txRes => {
        const tx = txRes.data as TransactionModel & { payment?: PaymentData };

        if (tx.paymentData) {
          try {
            tx.payment = JSON.parse(tx.paymentData) as PaymentData;
          } finally {
            void 0;
          }
        }
        this.currentTransaction = tx;

        return tx as TransactionFullModel;
      }),
    );

  protected readonly statusIconsMap: Record<TransactionStatus, string | null> = {
    [TransactionStatus.Init]: null,
    [TransactionStatus.Pending]: 'fidClock',
    [TransactionStatus.Done]: 'fidCheck',
    [TransactionStatus.Canceled]: 'fidCancel',
    [TransactionStatus.Fail]: null,
    [TransactionStatus.Processing]: null,
  };

  protected readonly typeIconsMap: Record<TransactionType, string> = {
    [TransactionType.Deposit]: 'fidDeposit',
    [TransactionType.Withdraw]: 'fidArrowDown',
  };
}
