/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getOffers } from '../fn/offer/get-offers';
import { GetOffers$Params } from '../fn/offer/get-offers';
import { getOffersId } from '../fn/offer/get-offers-id';
import { GetOffersId$Params } from '../fn/offer/get-offers-id';
import { OfferListResponse } from '../models/offer-list-response';
import { OfferResponse } from '../models/offer-response';

@Injectable({ providedIn: 'root' })
export class OfferService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getOffers()` */
  static readonly GetOffersPath = '/offers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOffers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOffers$Response(params?: GetOffers$Params, context?: HttpContext): Observable<StrictHttpResponse<OfferListResponse>> {
    return getOffers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOffers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOffers(params?: GetOffers$Params, context?: HttpContext): Observable<OfferListResponse> {
    return this.getOffers$Response(params, context).pipe(
      map((r: StrictHttpResponse<OfferListResponse>): OfferListResponse => r.body)
    );
  }

  /** Path part for operation `getOffersId()` */
  static readonly GetOffersIdPath = '/offers/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOffersId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOffersId$Response(params: GetOffersId$Params, context?: HttpContext): Observable<StrictHttpResponse<OfferResponse>> {
    return getOffersId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOffersId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOffersId(params: GetOffersId$Params, context?: HttpContext): Observable<OfferResponse> {
    return this.getOffersId$Response(params, context).pipe(
      map((r: StrictHttpResponse<OfferResponse>): OfferResponse => r.body)
    );
  }

}
