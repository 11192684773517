/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AvatarListResponse } from '../models/avatar-list-response';
import { AvatarResponse } from '../models/avatar-response';
import { getAvatars } from '../fn/avatar/get-avatars';
import { GetAvatars$Params } from '../fn/avatar/get-avatars';
import { getAvatarsId } from '../fn/avatar/get-avatars-id';
import { GetAvatarsId$Params } from '../fn/avatar/get-avatars-id';

@Injectable({ providedIn: 'root' })
export class AvatarService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAvatars()` */
  static readonly GetAvatarsPath = '/avatars';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAvatars()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvatars$Response(params?: GetAvatars$Params, context?: HttpContext): Observable<StrictHttpResponse<AvatarListResponse>> {
    return getAvatars(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAvatars$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvatars(params?: GetAvatars$Params, context?: HttpContext): Observable<AvatarListResponse> {
    return this.getAvatars$Response(params, context).pipe(
      map((r: StrictHttpResponse<AvatarListResponse>): AvatarListResponse => r.body)
    );
  }

  /** Path part for operation `getAvatarsId()` */
  static readonly GetAvatarsIdPath = '/avatars/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAvatarsId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvatarsId$Response(params: GetAvatarsId$Params, context?: HttpContext): Observable<StrictHttpResponse<AvatarResponse>> {
    return getAvatarsId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAvatarsId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvatarsId(params: GetAvatarsId$Params, context?: HttpContext): Observable<AvatarResponse> {
    return this.getAvatarsId$Response(params, context).pipe(
      map((r: StrictHttpResponse<AvatarResponse>): AvatarResponse => r.body)
    );
  }

}
