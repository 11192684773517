import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { AmountCalculatorComponent } from 'shared/components/amount-calc/amount-calculator.component';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaymentsCarouselComponent } from 'shared/components/payments/components/payments-carousel/payments-carousel.component';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiButtonModule, TuiErrorModule, TuiLinkModule } from '@taiga-ui/core';
import { TuiCheckboxLabeledModule, TuiFieldErrorPipeModule } from '@taiga-ui/kit';
import { PaymentMethodsDto } from 'core/fapi/models/payment-methods-dto';
import { TransactionType } from 'features/project/model/project.model';
import { CasinoModel } from 'core/data/domain/casino.model';

@Component({
  selector: 'fid-cashier-calculator',
  standalone: true,
  imports: [
    AmountCalculatorComponent,
    FormsModule,
    PaymentsCarouselComponent,
    TranslocoDirective,
    TuiButtonModule,
    TuiCheckboxLabeledModule,
    TuiErrorModule,
    TuiFieldErrorPipeModule,
    TuiLinkModule,
    ReactiveFormsModule,
  ],
  templateUrl: './cashier-calculator.component.html',
  styleUrl: './cashier-calculator.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CashierCalculatorComponent implements OnInit {
  private readonly rootFormGroup = inject(FormGroupDirective);

  @Input({ required: true }) public casino!: CasinoModel;
  @Input({ required: true }) public payments!: PaymentMethodsDto[] | null;
  @Input() public activeTab: TransactionType = TransactionType.Deposit;

  @Output() public paymentChanged: EventEmitter<PaymentMethodsDto> = new EventEmitter();

  protected selectedPayment!: PaymentMethodsDto;
  protected paymentCalculatorGroup!: FormGroup;
  protected transactionType = TransactionType;

  public ngOnInit(): void {
    this.paymentCalculatorGroup = this.rootFormGroup.control.get('paymentCalculator') as FormGroup;

    if (this.payments) {
      this.selectedPayment = this.payments[0];
    }

    this.paymentChanged.emit(this.selectedPayment);
  }

  protected setPayment(payment: PaymentMethodsDto): void {
    this.selectedPayment = payment;
  }
}
