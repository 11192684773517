import { ApiResponseDto } from 'core/fapi/models/api-response-dto';
import { AuthCodeResponseDto } from 'core/fapi/models/auth-code-response-dto';
import { AuthRefreshResponseDto } from 'core/fapi/models/auth-refresh-response-dto';
import { InstantAuthResponseDto } from 'core/fapi/models/instant-auth-response-dto';
import { InstantInitResponseDto } from 'core/fapi/models/instant-init-response-dto';
import { LoginResponseDto } from 'core/fapi/models/login-response-dto';
import { map, OperatorFunction, pipe } from 'rxjs';

// TODO: temporary mappers (until swagger fixed)
export const mapLoginResponse: OperatorFunction<
  ApiResponseDto & {
    data?: LoginResponseDto;
  },
  LoginResponseDto
> = pipe(map(dto => dto as unknown as LoginResponseDto));

export const mapRefreshResponse: OperatorFunction<
  ApiResponseDto & {
    data?: AuthRefreshResponseDto;
  },
  AuthRefreshResponseDto
> = pipe(map(dto => dto as unknown as AuthRefreshResponseDto));

export const mapCodeResponse: OperatorFunction<
  ApiResponseDto & {
    data?: AuthCodeResponseDto;
  },
  AuthCodeResponseDto
> = pipe(map(dto => dto as unknown as AuthCodeResponseDto));

export const mapInstantInitResponse: OperatorFunction<
  ApiResponseDto & {
    data?: InstantInitResponseDto;
  },
  InstantInitResponseDto
> = pipe(map(dto => dto as unknown as InstantInitResponseDto));

export const mapInstantAuthResponse: OperatorFunction<
  ApiResponseDto & {
    data?: InstantAuthResponseDto;
  },
  InstantAuthResponseDto
> = pipe(map(dto => dto as unknown as InstantAuthResponseDto));
