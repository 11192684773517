import { Injectable } from '@angular/core';
import { ListMapper, Mapper } from 'core/base/model/mapper.model';
import { ScreenshotEntity } from 'core/data/common/screenshot/screenshot.entity';
import { ScreenshotModel } from 'core/data/common/screenshot/screenshot.model';

@Injectable({
  providedIn: 'root',
})
export class ScreenshotMapper
  implements
    Mapper<ScreenshotEntity, ScreenshotModel>,
    ListMapper<ScreenshotEntity, ScreenshotModel>
{
  public mapFrom(param: ScreenshotEntity): ScreenshotModel {
    return { ...param?.attributes };
  }

  public mapFromList(params: ScreenshotEntity[]): ScreenshotModel[] {
    return params.map(this.mapFrom);
  }
}
