import { Injectable } from '@angular/core';
import { ListMapper, Mapper } from 'core/base/model/mapper.model';
import { TransactionModel } from 'core/data/domain/transaction.model';
import { TransactionDto } from 'core/fapi/models/transaction-dto';

@Injectable({
  providedIn: 'root',
})
export class TransactionMapper
  implements ListMapper<TransactionDto, TransactionModel>, Mapper<TransactionDto, TransactionModel>
{
  public mapFrom(dto: TransactionDto): TransactionModel {
    return {
      ...dto,
    };
  }

  public mapFromList(items: TransactionDto[]): TransactionModel[] {
    return items.map(item => this.mapFrom(item));
  }
}
