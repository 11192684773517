import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PopulationService {
  public populate(name: string, fields: string[]): string {
    return name + `,${name}.` + fields.join(`,${name}.`);
  }
}
