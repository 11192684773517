import { inject, Injectable } from '@angular/core';
import { ResponseMeta } from 'core/base/model/base-api.model';
import { ListMapper, Mapper, ResponseMapper } from 'core/base/model/mapper.model';
import { AvatarResponse } from 'core/capi/models';
import { AvatarListResponse } from 'core/capi/models/avatar-list-response';
import { AvatarListResponseDataItem } from 'core/capi/models/avatar-list-response-data-item';
import { ImageMapper } from 'core/data/common/image/image.mapper';
import { AvatarModel, AvatarsResponseModel } from 'core/data/domain/avatar.model';

@Injectable({
  providedIn: 'root',
})
export class AvatarMapper
  implements
    ResponseMapper<AvatarListResponse, AvatarsResponseModel>,
    ListMapper<AvatarListResponseDataItem, AvatarModel>,
    Mapper<AvatarListResponseDataItem, AvatarModel>
{
  private readonly imageMapper: ImageMapper = inject(ImageMapper);

  public mapFrom(param: AvatarListResponseDataItem): AvatarModel {
    return {
      code: param.attributes?.code || '',
      backgroundColor: param.attributes?.backgroundColor,
      image: this.imageMapper.mapFrom(param.attributes?.image),
    };
  }

  public mapFromList(items: AvatarListResponseDataItem[]): AvatarModel[] {
    return items.map(item => this.mapFrom(item));
  }

  public mapFromResponse({ data, meta }: AvatarListResponse): AvatarsResponseModel {
    return {
      meta: meta as ResponseMeta,
      data: this.mapFromList(data || []),
    };
  }

  public mapFromAvatarResponse({ data }: AvatarResponse): AvatarModel {
    return {
      code: data?.attributes?.code || '',
      backgroundColor: data?.attributes?.backgroundColor,
      image: this.imageMapper.mapFrom(data?.attributes?.image),
    };
  }
}
