import { inject, Injectable } from '@angular/core';
import { CasinoResponseDataObject } from 'core/capi/models/casino-response-data-object';
import { DiscoveryPageResponse } from 'core/capi/models/discovery-page-response';
import { ResponseMapper } from 'core/base/model/mapper.model';
import { ImageMapper } from 'core/data/common/image/image.mapper';
import { DiscoveryPageModel } from 'core/data/domain/discovery.model';
import { CasinoMapper } from 'core/data/repository/casino/casino.mapper';
import { GameMapper } from 'core/data/repository/game/game.mapper';
import { HowItWorksMapper } from 'core/data/repository/how-it-works/how-it-works.mapper';
import { OfferMapper } from 'core/data/repository/offer/offer.mapper';
import { SpecialFeatureMapper } from 'core/data/repository/special-feature/special-feature.mapper';
import { SportCategoryMapper } from 'core/data/repository/sport-category/sport-category.mapper';
import { SportMapper } from 'core/data/repository/sport/sport.mapper';

@Injectable({
  providedIn: 'root',
})
export class DiscoveryMapper implements ResponseMapper<DiscoveryPageResponse, DiscoveryPageModel> {
  private readonly imageMapper: ImageMapper = inject(ImageMapper);
  private readonly casinoMapper: CasinoMapper = inject(CasinoMapper);
  private readonly gameMapper: GameMapper = inject(GameMapper);
  private readonly offerMapper: OfferMapper = inject(OfferMapper);
  private readonly sportCategoryMapper: SportCategoryMapper = inject(SportCategoryMapper);
  private readonly sportMapper: SportMapper = inject(SportMapper);
  private readonly specialFeatureMapper: SpecialFeatureMapper = inject(SpecialFeatureMapper);
  private readonly howItWorksMapper: HowItWorksMapper = inject(HowItWorksMapper);

  public mapFromResponse({ data }: DiscoveryPageResponse): DiscoveryPageModel {
    return {
      mainBanner: {
        videoDesktop: this.imageMapper.mapFrom(data?.attributes?.mainBanner?.videoDesktop),
        videoMobile: this.imageMapper.mapFrom(data?.attributes?.mainBanner?.videoMobile),
      },
      topCasinos: this.casinoMapper.mapFromList(
        (data?.attributes?.topCasinos?.data as CasinoResponseDataObject[]) || [],
      ),
      mostPlayed: this.gameMapper.mapFromList(data?.attributes?.mostPlayed?.data || []),
      detectedCountry: data?.attributes?.detectedCountry || '',
      liveCasinos: this.gameMapper.mapFromList(data?.attributes?.liveCasino?.data || []),
      specialOffers: this.offerMapper.mapFromList(data?.attributes?.specialOffers?.data || []),
      jackpotBanner: {
        title: data?.attributes?.jackpotBanner?.title || '',
        buttonText: data?.attributes?.jackpotBanner?.buttonText || '',
        video: this.imageMapper.mapFrom(data?.attributes?.jackpotBanner?.video),
        amount: data?.attributes?.jackpotBanner?.amount || '',
      },
      crabBanner: {
        title: data?.attributes?.crabBanner?.title || '',
        buttonText: data?.attributes?.crabBanner?.buttonText || '',
        image: this.imageMapper.mapFrom(data?.attributes?.crabBanner?.image),
        game: this.gameMapper.mapFrom(data?.attributes?.crabBanner?.game?.data || {}),
      },
      activeTournaments:
        data?.attributes?.activeTournaments?.data?.map(activeTournament => ({
          label: activeTournament.attributes?.label,
          name: activeTournament.attributes?.name,
          backgroundGradient: activeTournament.attributes?.backgroundGradient,
          textGradient: activeTournament.attributes?.textGradient,
          reward: activeTournament.attributes?.reward,
          image: this.imageMapper.mapFrom(activeTournament.attributes?.image),
          showRewardIcon: this.isValidNumber(activeTournament.attributes?.reward),
          casino: this.casinoMapper.mapFrom(
            (activeTournament.attributes?.casino?.data as CasinoResponseDataObject) || {},
          ),
        })) || [],
      specialFeatures: this.specialFeatureMapper.mapFromList(
        data?.attributes?.specialFeatures?.data || [],
      ),
      newGames: this.gameMapper.mapFromList(data?.attributes?.newGames?.data || []),
      topSports: this.sportMapper.mapFromList(data?.attributes?.topSports?.data || []),
      sportCategories: this.sportCategoryMapper.mapFromList(
        data?.attributes?.sportCategories?.data || [],
      ),
      sportEvents: this.sportMapper.mapFromList(data?.attributes?.sportEvents?.data || []),
      howItWorks: this.howItWorksMapper.mapFromList(data?.attributes?.hiws?.data || []),
    };
  }

  private isValidNumber(value: unknown): boolean {
    if (typeof value !== 'string') {
      return false;
    }

    const withoutSpecialChars: string = value.replace(/[.,]/gi, '');

    return !isNaN(Number(withoutSpecialChars));
  }
}
