export const InitialMaxTransactions = 3;

export const HistoryTuiDayFormat = {
  dayFormat: 'DMY',
  separator: '/',
};

export const TransactionKeys: { [key: string]: string } = {
  /**
   * t(transaction.status.init)
   */
  INIT: 'transaction.status.init',
  /**
   * t(transaction.status.pending)
   */
  PENDING: 'transaction.status.pending',
  /**
   * t(transaction.status.cancel)
   */
  CANCELED: 'transaction.status.cancel',
  /**
   * t(transaction.status.done)
   */
  DONE: 'transaction.status.done',
  /**
   * t(transaction.status.fail)
   */
  FAIL: 'transaction.status.fail',
  /**
   * t(transaction.status.deposit)
   */
  DEPOSIT: 'transaction.type.deposit',
  /**
   * t(transaction.type.withdraw)
   */
  WITHDRAW: 'transaction.type.withdraw',
  /**
   * t(transaction.type.platformDeposit)
   */
  PLATFORM_DEPOSIT: 'transaction.type.platformDeposit',
  /**
   * t(transaction.type.platformWithdraw)
   */
  PLATFORM_WITHDRAW: 'transaction.type.platformWithdraw',
  /**
   * t(transaction.type.identify)
   */
  IDENTIFY: 'transaction.type.identify',
  /**
   * t(transaction.type.reward)
   */
  REWARD: 'transaction.type.reward',
};
