import {
  CurrencyPipe,
  NgIf,
  NgOptimizedImage,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
} from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { RouterLinkDirective } from 'core/base/directives/router-link.directive';
import { CdnUrlPipe } from 'core/base/utils/cdn-url.pipe';
import { CasinoModel } from 'core/data/domain/casino.model';
import { UserProjectModel } from 'features/user/model/user.model';
import { BadgeComponent } from 'shared/components/badge/badge.component';

type Size = 'small' | 'large';
type Variant = 'base' | 'highlighted' | 'flat' | 'flat-base' | 'base-logged' | 'base-logged-flat';

@Component({
  selector: 'fid-casino-card',
  standalone: true,
  imports: [
    NgOptimizedImage,
    NgIf,
    CdnUrlPipe,
    RouterLinkDirective,
    CurrencyPipe,
    BadgeComponent,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
  ],
  templateUrl: './casino-card.component.html',
  styleUrl: './casino-card.component.scss',
})
export class CasinoCardComponent {
  @Input({ required: true }) public casino!: CasinoModel & Partial<UserProjectModel>;
  @Input() public labelKey?: string;
  @Input() public size: Size = 'small';
  @Input() public variant: Variant = 'base';

  @HostBinding('class') public get elementClasses() {
    return [this.size, this.variant];
  }

  public readonly imageSizeMap: Record<Size, number> = {
    small: 40,
    large: 60,
  };
}
