import { Injectable } from '@angular/core';
import { ListMapper, Mapper } from 'core/base/model/mapper.model';
import { IconEntity } from 'core/data/common/icon/icon.entity';
import { IconModel } from 'core/data/common/icon/icon.model';

@Injectable({
  providedIn: 'root',
})
export class IconMapper
  implements Mapper<IconEntity, IconModel>, ListMapper<IconEntity, IconModel>
{
  public mapFrom(param: IconEntity): IconModel {
    return {
      ...param?.data?.attributes,
      alternativeText: param?.data?.attributes?.alternativeText || '',
      url: param?.data?.attributes?.url || '',
      width: param?.data?.attributes?.width || 1,
      height: param?.data?.attributes?.height || 1,
    };
  }

  public mapFromList(params: IconEntity[]): IconModel[] {
    return params.map(this.mapFrom);
  }
}
