<div *ngIf="avatarsList$ | async as avatarsList" class="avatars" [formGroup]="formGroup">
  <button
    *ngFor="let avatar of avatarsList; index as index; trackBy: 'code' | trackByProp"
    type="button"
    class="avatars--item"
    [ngClass]="{ _active: index === activeIndex }"
    [style]="avatar.backgroundColor ? '--hover-color: ' + avatar.backgroundColor : ''"
    (click)="chooseAvatar(index)"
  >
    <img
      *ngIf="avatar.image.url as url"
      class="avatars--image"
      [ngSrc]="url | cdnUrl"
      [alt]="avatar.image.alternativeText"
      width="80"
      height="80"
    />
    <input type="radio" class="avatars--input" [formControlName]="'avatar'" [value]="avatar.code" />
  </button>
</div>
