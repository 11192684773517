/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ApiResponseDto } from '../models/api-response-dto';
import { BrandsListDto } from '../models/brands-list-dto';
import { GamesItemDto } from '../models/games-item-dto';
import { GamesListDto } from '../models/games-list-dto';
import { OffersListDto } from '../models/offers-list-dto';
import { PaginationProjectDto } from '../models/pagination-project-dto';
import { PaymentMethodsDto } from '../models/payment-methods-dto';
import { PaymentResultDto } from '../models/payment-result-dto';
import { ProjectBalanceDto } from '../models/project-balance-dto';
import { projectControllerGetBalance } from '../fn/project/project-controller-get-balance';
import { ProjectControllerGetBalance$Params } from '../fn/project/project-controller-get-balance';
import { projectControllerGetBrandList } from '../fn/project/project-controller-get-brand-list';
import { ProjectControllerGetBrandList$Params } from '../fn/project/project-controller-get-brand-list';
import { projectControllerGetFeaturesUrl } from '../fn/project/project-controller-get-features-url';
import { ProjectControllerGetFeaturesUrl$Params } from '../fn/project/project-controller-get-features-url';
import { projectControllerGetGamesItem } from '../fn/project/project-controller-get-games-item';
import { ProjectControllerGetGamesItem$Params } from '../fn/project/project-controller-get-games-item';
import { projectControllerGetGamesList } from '../fn/project/project-controller-get-games-list';
import { ProjectControllerGetGamesList$Params } from '../fn/project/project-controller-get-games-list';
import { projectControllerGetOfferItem } from '../fn/project/project-controller-get-offer-item';
import { ProjectControllerGetOfferItem$Params } from '../fn/project/project-controller-get-offer-item';
import { projectControllerGetOfferList } from '../fn/project/project-controller-get-offer-list';
import { ProjectControllerGetOfferList$Params } from '../fn/project/project-controller-get-offer-list';
import { projectControllerGetSportList } from '../fn/project/project-controller-get-sport-list';
import { ProjectControllerGetSportList$Params } from '../fn/project/project-controller-get-sport-list';
import { projectControllerGetSportUrl } from '../fn/project/project-controller-get-sport-url';
import { ProjectControllerGetSportUrl$Params } from '../fn/project/project-controller-get-sport-url';
import { projectControllerGetTournamentUrl } from '../fn/project/project-controller-get-tournament-url';
import { ProjectControllerGetTournamentUrl$Params } from '../fn/project/project-controller-get-tournament-url';
import { projectControllerList } from '../fn/project/project-controller-list';
import { ProjectControllerList$Params } from '../fn/project/project-controller-list';
import { projectControllerOpenOffer } from '../fn/project/project-controller-open-offer';
import { ProjectControllerOpenOffer$Params } from '../fn/project/project-controller-open-offer';
import { projectControllerPaymentDeposit } from '../fn/project/project-controller-payment-deposit';
import { ProjectControllerPaymentDeposit$Params } from '../fn/project/project-controller-payment-deposit';
import { projectControllerPaymentMethod } from '../fn/project/project-controller-payment-method';
import { ProjectControllerPaymentMethod$Params } from '../fn/project/project-controller-payment-method';
import { projectControllerPaymentWithdrawal } from '../fn/project/project-controller-payment-withdrawal';
import { ProjectControllerPaymentWithdrawal$Params } from '../fn/project/project-controller-payment-withdrawal';
import { projectControllerPlayProject } from '../fn/project/project-controller-play-project';
import { ProjectControllerPlayProject$Params } from '../fn/project/project-controller-play-project';
import { projectControllerPlayProjectGame } from '../fn/project/project-controller-play-project-game';
import { ProjectControllerPlayProjectGame$Params } from '../fn/project/project-controller-play-project-game';
import { projectControllerProjectConnect } from '../fn/project/project-controller-project-connect';
import { ProjectControllerProjectConnect$Params } from '../fn/project/project-controller-project-connect';
import { projectControllerRegister } from '../fn/project/project-controller-register';
import { ProjectControllerRegister$Params } from '../fn/project/project-controller-register';
import { ProjectPlayResponseDto } from '../models/project-play-response-dto';
import { ProjectRegisterResponseDto } from '../models/project-register-response-dto';
import { ProjectUrlDto } from '../models/project-url-dto';
import { SportListDto } from '../models/sport-list-dto';

@Injectable({ providedIn: 'root' })
export class ProjectService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `projectControllerList()` */
  static readonly ProjectControllerListPath = '/api/project';

  /**
   * Returns projects list with whole information
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectControllerList()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectControllerList$Response(params?: ProjectControllerList$Params, context?: HttpContext): Observable<StrictHttpResponse<PaginationProjectDto>> {
    return projectControllerList(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns projects list with whole information
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectControllerList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectControllerList(params?: ProjectControllerList$Params, context?: HttpContext): Observable<PaginationProjectDto> {
    return this.projectControllerList$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaginationProjectDto>): PaginationProjectDto => r.body)
    );
  }

  /** Path part for operation `projectControllerRegister()` */
  static readonly ProjectControllerRegisterPath = '/api/project/register';

  /**
   * Register on a specified project
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectControllerRegister()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectControllerRegister$Response(params: ProjectControllerRegister$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiResponseDto & {
'data'?: ProjectRegisterResponseDto;
}>> {
    return projectControllerRegister(this.http, this.rootUrl, params, context);
  }

  /**
   * Register on a specified project
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectControllerRegister$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectControllerRegister(params: ProjectControllerRegister$Params, context?: HttpContext): Observable<ApiResponseDto & {
'data'?: ProjectRegisterResponseDto;
}> {
    return this.projectControllerRegister$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiResponseDto & {
'data'?: ProjectRegisterResponseDto;
}>): ApiResponseDto & {
'data'?: ProjectRegisterResponseDto;
} => r.body)
    );
  }

  /** Path part for operation `projectControllerProjectConnect()` */
  static readonly ProjectControllerProjectConnectPath = '/api/project/connect';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectControllerProjectConnect()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectControllerProjectConnect$Response(params: ProjectControllerProjectConnect$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiResponseDto & {
'data'?: ProjectPlayResponseDto;
}>> {
    return projectControllerProjectConnect(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectControllerProjectConnect$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectControllerProjectConnect(params: ProjectControllerProjectConnect$Params, context?: HttpContext): Observable<ApiResponseDto & {
'data'?: ProjectPlayResponseDto;
}> {
    return this.projectControllerProjectConnect$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiResponseDto & {
'data'?: ProjectPlayResponseDto;
}>): ApiResponseDto & {
'data'?: ProjectPlayResponseDto;
} => r.body)
    );
  }

  /** Path part for operation `projectControllerPaymentMethod()` */
  static readonly ProjectControllerPaymentMethodPath = '/api/project/payment/method';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectControllerPaymentMethod()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectControllerPaymentMethod$Response(params: ProjectControllerPaymentMethod$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiResponseDto & {
'data'?: PaymentMethodsDto;
}>> {
    return projectControllerPaymentMethod(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectControllerPaymentMethod$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectControllerPaymentMethod(params: ProjectControllerPaymentMethod$Params, context?: HttpContext): Observable<ApiResponseDto & {
'data'?: PaymentMethodsDto;
}> {
    return this.projectControllerPaymentMethod$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiResponseDto & {
'data'?: PaymentMethodsDto;
}>): ApiResponseDto & {
'data'?: PaymentMethodsDto;
} => r.body)
    );
  }

  /** Path part for operation `projectControllerPaymentWithdrawal()` */
  static readonly ProjectControllerPaymentWithdrawalPath = '/api/project/payment/withdrawal';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectControllerPaymentWithdrawal()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectControllerPaymentWithdrawal$Response(params: ProjectControllerPaymentWithdrawal$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiResponseDto & {
'data'?: PaymentResultDto;
}>> {
    return projectControllerPaymentWithdrawal(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectControllerPaymentWithdrawal$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectControllerPaymentWithdrawal(params: ProjectControllerPaymentWithdrawal$Params, context?: HttpContext): Observable<ApiResponseDto & {
'data'?: PaymentResultDto;
}> {
    return this.projectControllerPaymentWithdrawal$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiResponseDto & {
'data'?: PaymentResultDto;
}>): ApiResponseDto & {
'data'?: PaymentResultDto;
} => r.body)
    );
  }

  /** Path part for operation `projectControllerPaymentDeposit()` */
  static readonly ProjectControllerPaymentDepositPath = '/api/project/payment/deposit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectControllerPaymentDeposit()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectControllerPaymentDeposit$Response(params: ProjectControllerPaymentDeposit$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiResponseDto & {
'data'?: PaymentResultDto;
}>> {
    return projectControllerPaymentDeposit(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectControllerPaymentDeposit$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectControllerPaymentDeposit(params: ProjectControllerPaymentDeposit$Params, context?: HttpContext): Observable<ApiResponseDto & {
'data'?: PaymentResultDto;
}> {
    return this.projectControllerPaymentDeposit$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiResponseDto & {
'data'?: PaymentResultDto;
}>): ApiResponseDto & {
'data'?: PaymentResultDto;
} => r.body)
    );
  }

  /** Path part for operation `projectControllerGetBalance()` */
  static readonly ProjectControllerGetBalancePath = '/api/project/balance';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectControllerGetBalance()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectControllerGetBalance$Response(params: ProjectControllerGetBalance$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiResponseDto & {
'data'?: ProjectBalanceDto;
}>> {
    return projectControllerGetBalance(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectControllerGetBalance$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectControllerGetBalance(params: ProjectControllerGetBalance$Params, context?: HttpContext): Observable<ApiResponseDto & {
'data'?: ProjectBalanceDto;
}> {
    return this.projectControllerGetBalance$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiResponseDto & {
'data'?: ProjectBalanceDto;
}>): ApiResponseDto & {
'data'?: ProjectBalanceDto;
} => r.body)
    );
  }

  /** Path part for operation `projectControllerPlayProject()` */
  static readonly ProjectControllerPlayProjectPath = '/api/project/play';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectControllerPlayProject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectControllerPlayProject$Response(params: ProjectControllerPlayProject$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiResponseDto & {
'data'?: ProjectPlayResponseDto;
}>> {
    return projectControllerPlayProject(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectControllerPlayProject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectControllerPlayProject(params: ProjectControllerPlayProject$Params, context?: HttpContext): Observable<ApiResponseDto & {
'data'?: ProjectPlayResponseDto;
}> {
    return this.projectControllerPlayProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiResponseDto & {
'data'?: ProjectPlayResponseDto;
}>): ApiResponseDto & {
'data'?: ProjectPlayResponseDto;
} => r.body)
    );
  }

  /** Path part for operation `projectControllerPlayProjectGame()` */
  static readonly ProjectControllerPlayProjectGamePath = '/api/project/game/play';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectControllerPlayProjectGame()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectControllerPlayProjectGame$Response(params: ProjectControllerPlayProjectGame$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiResponseDto & {
'data'?: ProjectUrlDto;
}>> {
    return projectControllerPlayProjectGame(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectControllerPlayProjectGame$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectControllerPlayProjectGame(params: ProjectControllerPlayProjectGame$Params, context?: HttpContext): Observable<ApiResponseDto & {
'data'?: ProjectUrlDto;
}> {
    return this.projectControllerPlayProjectGame$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiResponseDto & {
'data'?: ProjectUrlDto;
}>): ApiResponseDto & {
'data'?: ProjectUrlDto;
} => r.body)
    );
  }

  /** Path part for operation `projectControllerGetTournamentUrl()` */
  static readonly ProjectControllerGetTournamentUrlPath = '/api/project/tournament/open';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectControllerGetTournamentUrl()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectControllerGetTournamentUrl$Response(params: ProjectControllerGetTournamentUrl$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiResponseDto & {
'data'?: ProjectUrlDto;
}>> {
    return projectControllerGetTournamentUrl(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectControllerGetTournamentUrl$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectControllerGetTournamentUrl(params: ProjectControllerGetTournamentUrl$Params, context?: HttpContext): Observable<ApiResponseDto & {
'data'?: ProjectUrlDto;
}> {
    return this.projectControllerGetTournamentUrl$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiResponseDto & {
'data'?: ProjectUrlDto;
}>): ApiResponseDto & {
'data'?: ProjectUrlDto;
} => r.body)
    );
  }

  /** Path part for operation `projectControllerGetSportList()` */
  static readonly ProjectControllerGetSportListPath = '/api/project/sport/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectControllerGetSportList()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectControllerGetSportList$Response(params?: ProjectControllerGetSportList$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiResponseDto & {
'data'?: SportListDto;
}>> {
    return projectControllerGetSportList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectControllerGetSportList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectControllerGetSportList(params?: ProjectControllerGetSportList$Params, context?: HttpContext): Observable<ApiResponseDto & {
'data'?: SportListDto;
}> {
    return this.projectControllerGetSportList$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiResponseDto & {
'data'?: SportListDto;
}>): ApiResponseDto & {
'data'?: SportListDto;
} => r.body)
    );
  }

  /** Path part for operation `projectControllerGetSportUrl()` */
  static readonly ProjectControllerGetSportUrlPath = '/api/project/sport/play';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectControllerGetSportUrl()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectControllerGetSportUrl$Response(params: ProjectControllerGetSportUrl$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiResponseDto & {
'data'?: ProjectUrlDto;
}>> {
    return projectControllerGetSportUrl(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectControllerGetSportUrl$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectControllerGetSportUrl(params: ProjectControllerGetSportUrl$Params, context?: HttpContext): Observable<ApiResponseDto & {
'data'?: ProjectUrlDto;
}> {
    return this.projectControllerGetSportUrl$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiResponseDto & {
'data'?: ProjectUrlDto;
}>): ApiResponseDto & {
'data'?: ProjectUrlDto;
} => r.body)
    );
  }

  /** Path part for operation `projectControllerGetFeaturesUrl()` */
  static readonly ProjectControllerGetFeaturesUrlPath = '/api/project/feature/open';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectControllerGetFeaturesUrl()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectControllerGetFeaturesUrl$Response(params: ProjectControllerGetFeaturesUrl$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiResponseDto & {
'data'?: ProjectUrlDto;
}>> {
    return projectControllerGetFeaturesUrl(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectControllerGetFeaturesUrl$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectControllerGetFeaturesUrl(params: ProjectControllerGetFeaturesUrl$Params, context?: HttpContext): Observable<ApiResponseDto & {
'data'?: ProjectUrlDto;
}> {
    return this.projectControllerGetFeaturesUrl$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiResponseDto & {
'data'?: ProjectUrlDto;
}>): ApiResponseDto & {
'data'?: ProjectUrlDto;
} => r.body)
    );
  }

  /** Path part for operation `projectControllerGetGamesItem()` */
  static readonly ProjectControllerGetGamesItemPath = '/api/project/game/item';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectControllerGetGamesItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectControllerGetGamesItem$Response(params?: ProjectControllerGetGamesItem$Params, context?: HttpContext): Observable<StrictHttpResponse<GamesItemDto>> {
    return projectControllerGetGamesItem(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectControllerGetGamesItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectControllerGetGamesItem(params?: ProjectControllerGetGamesItem$Params, context?: HttpContext): Observable<GamesItemDto> {
    return this.projectControllerGetGamesItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<GamesItemDto>): GamesItemDto => r.body)
    );
  }

  /** Path part for operation `projectControllerGetGamesList()` */
  static readonly ProjectControllerGetGamesListPath = '/api/project/game/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectControllerGetGamesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectControllerGetGamesList$Response(params?: ProjectControllerGetGamesList$Params, context?: HttpContext): Observable<StrictHttpResponse<GamesListDto>> {
    return projectControllerGetGamesList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectControllerGetGamesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectControllerGetGamesList(params?: ProjectControllerGetGamesList$Params, context?: HttpContext): Observable<GamesListDto> {
    return this.projectControllerGetGamesList$Response(params, context).pipe(
      map((r: StrictHttpResponse<GamesListDto>): GamesListDto => r.body)
    );
  }

  /** Path part for operation `projectControllerGetBrandList()` */
  static readonly ProjectControllerGetBrandListPath = '/api/project/game/brand/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectControllerGetBrandList()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectControllerGetBrandList$Response(params: ProjectControllerGetBrandList$Params, context?: HttpContext): Observable<StrictHttpResponse<BrandsListDto>> {
    return projectControllerGetBrandList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectControllerGetBrandList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectControllerGetBrandList(params: ProjectControllerGetBrandList$Params, context?: HttpContext): Observable<BrandsListDto> {
    return this.projectControllerGetBrandList$Response(params, context).pipe(
      map((r: StrictHttpResponse<BrandsListDto>): BrandsListDto => r.body)
    );
  }

  /** Path part for operation `projectControllerGetOfferItem()` */
  static readonly ProjectControllerGetOfferItemPath = '/api/project/offer/item';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectControllerGetOfferItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectControllerGetOfferItem$Response(params?: ProjectControllerGetOfferItem$Params, context?: HttpContext): Observable<StrictHttpResponse<OffersListDto>> {
    return projectControllerGetOfferItem(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectControllerGetOfferItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectControllerGetOfferItem(params?: ProjectControllerGetOfferItem$Params, context?: HttpContext): Observable<OffersListDto> {
    return this.projectControllerGetOfferItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffersListDto>): OffersListDto => r.body)
    );
  }

  /** Path part for operation `projectControllerGetOfferList()` */
  static readonly ProjectControllerGetOfferListPath = '/api/project/offer/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectControllerGetOfferList()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectControllerGetOfferList$Response(params?: ProjectControllerGetOfferList$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OffersListDto>>> {
    return projectControllerGetOfferList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectControllerGetOfferList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectControllerGetOfferList(params?: ProjectControllerGetOfferList$Params, context?: HttpContext): Observable<Array<OffersListDto>> {
    return this.projectControllerGetOfferList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OffersListDto>>): Array<OffersListDto> => r.body)
    );
  }

  /** Path part for operation `projectControllerOpenOffer()` */
  static readonly ProjectControllerOpenOfferPath = '/api/project/offer/open';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectControllerOpenOffer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectControllerOpenOffer$Response(params: ProjectControllerOpenOffer$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiResponseDto & {
'data'?: ProjectUrlDto;
}>> {
    return projectControllerOpenOffer(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectControllerOpenOffer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectControllerOpenOffer(params: ProjectControllerOpenOffer$Params, context?: HttpContext): Observable<ApiResponseDto & {
'data'?: ProjectUrlDto;
}> {
    return this.projectControllerOpenOffer$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiResponseDto & {
'data'?: ProjectUrlDto;
}>): ApiResponseDto & {
'data'?: ProjectUrlDto;
} => r.body)
    );
  }

}
