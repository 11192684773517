{
  "name": "funid-site",
  "version": "0.0.0",
  "scripts": {
    "start": "npm run openapi-gen:dev && ng serve",
    "start:noreload": "npm run openapi-gen:dev && ng serve --live-reload false",
    "start:development": "npm run openapi-gen:dev && ng serve --configuration development",
    "start:devprod": "npm run openapi-gen:prod && ng serve --configuration devprod",
    "start:devprod:noreload": "npm run openapi-gen:prod && ng serve --configuration devprod --live-reload false",
    "start:no-api": "ng serve",
    "build": "npm run openapi-gen:dev && ng build --configuration production",
    "build:ci": "npm run openapi-gen:ci && ng build --configuration production",
    "local-build": "npm run openapi-gen:dev && ng build --watch --configuration local-build",
    "local-build:no-api": "ng build --watch --configuration local-build",
    "watch": "npm run openapi-gen:dev && ng build --watch --configuration development",
    "watch:no-api": "ng build --watch --configuration development",
    "serve:ssr:funid-site": "node dist/funid-site/server/server.mjs",
    "ng": "ng",
    "ng-openapi-gen": "ng-openapi-gen",
    "openapi-gen:dev": "concurrently \"npm:*-openapi-gen:dev\"",
    "openapi-gen:ci": "concurrently \"npm:*-openapi-gen:ci\"",
    "openapi-gen:prod": "concurrently \"npm:*-openapi-gen:prod\"",
    "capi-openapi-gen:dev": "ng-openapi-gen -c .openapi/capi-gen.dev.json",
    "capi-openapi-gen:ci": "ng-openapi-gen -c .openapi/capi-gen.ci.json",
    "capi-openapi-gen:prod": "ng-openapi-gen -c .openapi/capi-gen.prod.json",
    "fapi-openapi-gen:dev": "ng-openapi-gen -c .openapi/fapi-gen.dev.json",
    "fapi-openapi-gen:ci": "ng-openapi-gen -c .openapi/fapi-gen.ci.json",
    "fapi-openapi-gen:prod": "ng-openapi-gen -c .openapi/fapi-gen.prod.json",
    "test": "ng test",
    "lint": "ng lint",
    "stylelint:check": "npx stylelint '**/*.scss'",
    "stylelint:fix": "npx stylelint '**/*.scss' --fix",
    "prettier": "prettier --write \"**/*.{js,json,css,scss,less,md,ts,html,component.html}\"",
    "i18n:extract": "transloco-keys-manager extract",
    "i18n:find": "transloco-keys-manager find",
    "prepare": "husky"
  },
  "private": true,
  "dependencies": {
    "@amplitude/analytics-browser": "^2.11.9",
    "@angular/animations": "^18.2.4",
    "@angular/cdk": "^17.2.2",
    "@angular/common": "^18.2.4",
    "@angular/compiler": "^18.2.4",
    "@angular/core": "^18.2.4",
    "@angular/forms": "^18.2.4",
    "@angular/platform-browser": "^18.2.4",
    "@angular/platform-browser-dynamic": "^18.2.4",
    "@angular/platform-server": "^18.2.4",
    "@angular/router": "^18.2.4",
    "@angular/ssr": "^18.2.4",
    "@dotlottie/player-component": "^2.7.10",
    "@firebase/analytics": "^0.10.10",
    "@firebase/app": "^0.10.17",
    "@funid/auth": "^1.0.6",
    "@ng-web-apis/common": "^3.0.6",
    "@ng-web-apis/universal": "3.0.6",
    "@ngneat/transloco": "^6.0.4",
    "@taiga-ui/addon-commerce": "3.93.0",
    "@taiga-ui/cdk": "^3.93.0",
    "@taiga-ui/core": "^3.93.0",
    "@taiga-ui/experimental": "3.93.0",
    "@taiga-ui/icons": "^3.93.0",
    "@taiga-ui/kit": "3.93.0",
    "@types/accept-language-parser": "^1.5.6",
    "@types/google-libphonenumber": "^7.4.30",
    "accept-language-parser": "^1.5.0",
    "angularx-qrcode": "^18.0.1",
    "concurrently": "^8.2.2",
    "express": "^4.18.2",
    "google-libphonenumber": "^3.2.38",
    "memo-decorator": "^2.0.1",
    "ngx-markdown": "^18.0.0",
    "rxjs": "~7.8.0",
    "socket.io": "^4.7.5",
    "swiper": "^11.1.4",
    "the-new-css-reset": "1.11.2",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.4",
    "@angular-eslint/builder": "18.1.0",
    "@angular-eslint/eslint-plugin": "18.1.0",
    "@angular-eslint/eslint-plugin-template": "18.1.0",
    "@angular-eslint/schematics": "18.1.0",
    "@angular-eslint/template-parser": "18.1.0",
    "@angular/cli": "^18.2.4",
    "@angular/compiler-cli": "^18.2.4",
    "@ngneat/transloco-keys-manager": "^3.8.0",
    "@schuchard/prettier": "^5.1.0",
    "@types/express": "^4.17.17",
    "@types/jasmine": "~5.1.0",
    "@types/node": "^18.18.0",
    "@typescript-eslint/eslint-plugin": "^7.2.0",
    "@typescript-eslint/parser": "^7.2.0",
    "eslint": "^8.57.0",
    "husky": "^9.0.11",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "lint-staged": "latest",
    "ng-openapi-gen": "^0.51.0",
    "prettier": "latest",
    "prettier-plugin-organize-attributes": "^1.0.0",
    "pretty-quick": "^4.0.0",
    "stylelint": "^16.2.0",
    "stylelint-config-recess-order": "^4.4.0",
    "stylelint-config-standard-scss": "^13.0.0",
    "tslint-config-prettier": "latest",
    "typescript": "~5.4.5"
  },
  "volta": {
    "node": "20.11.0",
    "npm": "10.3.0"
  },
  "husky": {
    "hooks": {
      "pre-commit": "lint-staged"
    }
  },
  "lint-staged": {
    "*.{js,json,css,scss,less,md,ts,html,component.html}": [
      "prettier --write"
    ]
  }
}
