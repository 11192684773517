const lowerChars: string = 'abcdefghijklmnopqrstuvwxyz';
const upperChars: string = lowerChars.toUpperCase();
const numberChars: string = '0123456789';

export function generate(length: number, prefix?: string): string {
  const chars = lowerChars + upperChars + numberChars;
  let result = '';
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return (prefix || '') + result;
}
