/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ApiResponseDto } from '../../models/api-response-dto';
import { PaymentResultDto } from '../../models/payment-result-dto';
import { ProjectPaymentDepositDto } from '../../models/project-payment-deposit-dto';

export interface ProjectControllerPaymentDeposit$Params {
  'x-platform-name'?: any;
  'x-platform-version'?: any;
  'x-platform-timezone'?: any;
      body: ProjectPaymentDepositDto
}

export function projectControllerPaymentDeposit(http: HttpClient, rootUrl: string, params: ProjectControllerPaymentDeposit$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiResponseDto & {
'data'?: PaymentResultDto;
}>> {
  const rb = new RequestBuilder(rootUrl, projectControllerPaymentDeposit.PATH, 'post');
  if (params) {
    rb.header('x-platform-name', params['x-platform-name'], {});
    rb.header('x-platform-version', params['x-platform-version'], {});
    rb.header('x-platform-timezone', params['x-platform-timezone'], {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ApiResponseDto & {
      'data'?: PaymentResultDto;
      }>;
    })
  );
}

projectControllerPaymentDeposit.PATH = '/api/project/payment/deposit';
