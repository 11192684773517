import { CommonModule } from '@angular/common';
import {
  afterNextRender,
  AfterRenderPhase,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  HostBinding,
  inject,
  Input,
  OnInit,
  QueryList,
  signal,
  TemplateRef,
  ViewChildren,
  WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiButtonComponent, TuiButtonModule } from '@taiga-ui/core';
import { FidQueryParam } from 'core/base/constants/query-params.constants';
import { QueryParamsService } from 'core/base/services/query-params.service';
import { FilterOption } from 'core/features/filter/model/filter.model';
import { distinctUntilChanged, Observable, tap } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AmpEventsName } from 'shared/services/amplitude.service';
import { SendAnalyticsDirective } from 'shared/directives/send-analytics.directive';

@Component({
  selector: 'fid-filter',
  standalone: true,
  imports: [CommonModule, TuiButtonModule, TranslocoDirective, SendAnalyticsDirective],
  templateUrl: './filter.component.html',
  styleUrl: './filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterComponent implements OnInit {
  private destroyRef: DestroyRef = inject(DestroyRef);
  private queryParams: QueryParamsService = inject(QueryParamsService);
  private selectedOption: WritableSignal<FilterOption | null> = signal(null);

  @Input() public template?: TemplateRef<unknown>;
  @Input({ required: true }) public filterOptions!: FilterOption[];
  @Input({ required: true }) public filterBy!: FidQueryParam;

  @HostBinding('className') @Input() public appearance: 'row' | 'grid' = 'row';

  @ViewChildren('tab') protected tabs!: QueryList<TuiButtonComponent>;

  protected readonly ampEventsName = AmpEventsName;

  constructor() {
    afterNextRender(
      () => {
        this.tabs.changes.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
          this.handleScroll();
        });
      },
      {
        phase: AfterRenderPhase.Write,
      },
    );
  }

  public selectedOption$: Observable<FilterOption> = toObservable(this.selectedOption).pipe(
    filter(Boolean),
    distinctUntilChanged(),
    tap((option: FilterOption) =>
      this.queryParams.setParameter(this.filterBy, option.queryParamName),
    ),
  );

  public ngOnInit(): void {
    this.queryParams
      .selectParameter(this.filterBy)
      .pipe(
        map(
          (field: string | null) =>
            this.filterOptions.find((option: FilterOption) => option.queryParamName === field) ||
            this.filterOptions[0],
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((option: FilterOption) => {
        this.select(option);
      });
  }

  public select(option: FilterOption): void {
    this.selectedOption.set(option);
  }

  private handleScroll(): void {
    const activeTab = this.tabs?.find(tab => Boolean(tab.pseudoActive || tab.focused));
    if (!activeTab) return;

    activeTab.nativeFocusableElement?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
  }
}
