import { inject, Injectable } from '@angular/core';
import { GameProviderListResponse } from 'core/capi/models/game-provider-list-response';
import { SortOrder } from 'core/base/model/base-api.model';
import {
  GameProviderModel,
  GameProvidersResponseModel,
} from 'core/data/domain/game-provider.model';
import { GameProviderMapper } from 'core/data/repository/game-provider/game-provider.mapper';
import { GameProvidersApiService } from 'core/data/repository/game-provider/game-providers-api.service';
import { FilterOptionFactoryService } from 'core/features/filter/services/filter-option-factory.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalizationService } from 'core/features/localization/services/localization.service';

@Injectable({
  providedIn: 'root',
})
export class GameProviderRepository {
  private gameProvidersApi: GameProvidersApiService = inject(GameProvidersApiService);
  private mapper: GameProviderMapper = inject(GameProviderMapper);
  private readonly filterFactory: FilterOptionFactoryService = inject(FilterOptionFactoryService);
  private readonly lang = inject(LocalizationService);

  public getListResponse(order: SortOrder = SortOrder.Asc): Observable<GameProvidersResponseModel> {
    return this.lang.trackChanges(
      this.gameProvidersApi
        .getGameProviders({
          sort: `name:${order}`,
          'populate[icon]': 'icon',
          'populate[games][count]': 'true',
          'pagination[limit]': 1000,
          'pagination[withCount]': 'true',
        })
        .pipe(map((response: GameProviderListResponse) => this.mapper.mapFromResponse(response))),
    );
  }

  public getList(order: SortOrder = SortOrder.Asc): Observable<GameProviderModel[]> {
    return this.getListResponse(order).pipe(map(({ data }) => data));
  }

  public getFullList(order: SortOrder = SortOrder.Asc): Observable<GameProviderModel[]> {
    return this.getList(order).pipe(
      map((providers: GameProviderModel[]) => [
        this.filterFactory.createOption(
          /**
           * t(global.btn.allProviders)
           */
          'global.btn.allProviders',
          '/assets/icons/settings.svg',
          providers.reduce((count, provider) => provider.count + count, 0),
        ),
        ...providers,
      ]),
    );
  }
}
