<ng-container *ngIf="inputsFormArrayControls.length">
  <div class="otp-inputs-wrapper">
    <input
      *ngFor="let inputControl of inputsFormArrayControls; let i = index; trackBy: '' | trackByProp"
      #inputEl
      type="password"
      class="otp-input"
      [attr.autofocus]="i === 0"
      [attr.autocomplete]="i === 0 ? 'one-time-code' : 'off'"
      [formControl]="inputControl"
      (blur)="onBlur()"
      (keydown)="handleKeyDown($event, i)"
      (beforeinput)="handleBeforeInput($event)"
      inputmode="numeric"
      placeholder=" "
    />
  </div>
</ng-container>
