/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getWallets } from '../fn/wallet/get-wallets';
import { GetWallets$Params } from '../fn/wallet/get-wallets';
import { getWalletsId } from '../fn/wallet/get-wallets-id';
import { GetWalletsId$Params } from '../fn/wallet/get-wallets-id';
import { WalletListResponse } from '../models/wallet-list-response';
import { WalletResponse } from '../models/wallet-response';

@Injectable({ providedIn: 'root' })
export class WalletService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getWallets()` */
  static readonly GetWalletsPath = '/wallets';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWallets()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWallets$Response(params?: GetWallets$Params, context?: HttpContext): Observable<StrictHttpResponse<WalletListResponse>> {
    return getWallets(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWallets$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWallets(params?: GetWallets$Params, context?: HttpContext): Observable<WalletListResponse> {
    return this.getWallets$Response(params, context).pipe(
      map((r: StrictHttpResponse<WalletListResponse>): WalletListResponse => r.body)
    );
  }

  /** Path part for operation `getWalletsId()` */
  static readonly GetWalletsIdPath = '/wallets/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWalletsId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWalletsId$Response(params: GetWalletsId$Params, context?: HttpContext): Observable<StrictHttpResponse<WalletResponse>> {
    return getWalletsId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWalletsId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWalletsId(params: GetWalletsId$Params, context?: HttpContext): Observable<WalletResponse> {
    return this.getWalletsId$Response(params, context).pipe(
      map((r: StrictHttpResponse<WalletResponse>): WalletResponse => r.body)
    );
  }

}
