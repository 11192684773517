<ng-container *transloco="let t">
  <ng-container *ngIf="page$ | async as page">
    <ng-container *ngIf="page.connected.length">
      <ng-container *ngIf="page.connected as connected">
        <fid-casino-card
          *ngFor="let casino of connected; trackBy: 'id' | trackByProp"
          [casino]="casino"
          [labelKey]="t('casino.recommended')"
          [size]="'large'"
          [variant]="'base-logged-flat'"
        >
          <button
            appearance="secondary"
            type="button"
            [attr.aria-label]="t('global.btn.play')"
            [playData]="{
              casino,
              game: dialogData?.game,
              sport: dialogData?.sport,
            }"
            [fidSendAnalytics]="[
              ampEventsName.BrandPlayClick,
              {
                source: 'checkUrl',
                action: 'play',
                gameCode: dialogData?.game?.alias || '',
                sportEventId: dialogData?.sport?.EventId || '',
                brandDomain: dialogData?.casino?.domain || '',
                isUserExist: true,
              },
            ]"
            shape="rounded"
            size="s"
            tuiButton
            fidPlayButton
          >
            {{ t('global.btn.play') }}
          </button>
        </fid-casino-card>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="page.recommended.length">
      <ng-container *ngIf="page.recommended as recommended">
        <fid-casino-card
          *ngFor="let casino of recommended; trackBy: 'id' | trackByProp"
          [casino]="casino"
          [labelKey]="t('casino.recommended')"
          [size]="'large'"
          [variant]="'flat'"
        >
          <button
            appearance="accent"
            type="button"
            [attr.aria-label]="t('casino.btn.connect')"
            [dialogConfig]="{ size: 'm', data: { casino, user: page.user } }"
            [fidSendAnalytics]="[
              ampEventsName.ConnectClick,
              {
                source: 'checkUrl',
                sportEventId: '',
                brandDomain: casino?.domain || '',
                isUserExist: false,
                gameCode: '',
              },
            ]"
            shape="rounded"
            size="s"
            tuiButton
            fidOpenDialog="cashier"
          >
            {{ t('casino.btn.connect') }}
          </button>
        </fid-casino-card>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #loader>
    <fid-loader></fid-loader>
  </ng-template>
</ng-container>
