import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { ProjectDialogHeaderComponent } from 'features/project/components/project-dialog-header/project-dialog-header.component';
import { DialogCloseService } from 'core/features/dialog/services/dialog-close.service';
import { BrandLoginDialogData } from 'features/project/model/project.model';
import { ProjectLoginFormComponent } from 'features/project/components/project-login-form/project-login-form.component';
import { NgIf } from '@angular/common';
import { AmpEventsName, AmplitudeService } from 'shared/services/amplitude.service';

@Component({
  selector: 'fid-project-login-dialog',
  standalone: true,
  imports: [ProjectDialogHeaderComponent, ProjectLoginFormComponent, NgIf],
  templateUrl: './project-login-dialog.component.html',
  styleUrl: './project-login-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectLoginDialogComponent implements OnInit {
  private dialogClose: DialogCloseService = inject(DialogCloseService);
  private readonly amplitude = inject(AmplitudeService);

  public dialogData: BrandLoginDialogData | null = null;

  public ngOnInit(): void {
    if (!this.dialogData) {
      this.dialogClose.close();
    } else {
      this.amplitude.send(AmpEventsName.LoginBrandStart, {
        isUserExist: false,
        brandDomain: this.dialogData.casino.domain || '',
      });
    }
  }
}
