import { DestroyRef, inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import * as amplitude from '@amplitude/analytics-browser';
import { environment } from '@environments/environment';
import { Router } from '@angular/router';
import { DialogComponentName } from 'core/features/dialog/constants/dialogs-list.constants';
import { AppUserService } from 'features/user/services/app-user.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs';

export enum AmpEventsName {
  OnboardingOpen = 'OnboardingOpen',
  OnboardingStoryViewed = 'OnboardingStoryViewed',
  AuthStart = 'AuthStart',
  AuthSuccess = 'AuthSuccess',
  AuthError = 'AuthError',
  AuthEnterPhoneNextClick = 'AuthEnterPhoneNextClick',
  AuthSmsCodeWritten = 'AuthSmsCodeWritten',
  AuthResentSmsCode = 'AuthResentSmsCode',
  AuthBackClick = 'AuthBackClick',
  ApplicationStateChanged = 'ApplicationStateChanged',
  RegistrationStart = 'RegistrationStart',
  RegistrationSuccess = 'RegistrationSuccess',
  RegistrationError = 'RegistrationError',
  RegistrationHomeAddressNextClick = 'RegistrationHomeAddressNextClick',
  RegistrationPersonalDetailsNextClick = 'RegistrationPersonalDetailsNextClick',
  CreatePasscodeStart = 'CreatePasscodeStart',
  CreatePasscodeSuccess = 'CreatePasscodeSuccess',
  RegistrationSelectAvatarYeahClick = 'RegistrationSelectAvatarYeahClick',
  EmailPasswordRecoverySent = 'EmailPasswordRecoverySent',
  PaymentStart = 'PaymentStart',
  PaymentError = 'PaymentError',
  PaymentSuccess = 'PaymentSuccess',
  PaymentWebViewBackClick = 'PaymentWebViewBackClick',
  PaymentShowConfirmation = 'PaymentShowConfirmation',
  PaymentPrimaryClick = 'PaymentPrimaryClick',
  PaymentSecondaryClick = 'PaymentSecondaryClick',
  PaymentConfirmClick = 'PaymentConfirmClick',
  PaymentConfirmationCancelClick = 'PaymentConfirmationCancelClick',
  PaymentResultPrimaryClick = 'PaymentResultPrimaryClick',
  PaymentResultSecondaryClick = 'PaymentResultSecondaryClick',
  PaymentAmountDefaultButtonsClick = 'PaymentAmountDefaultButtonsClick',
  PaymentAmountMinusButtonClick = 'PaymentAmountMinusButtonClick',
  PaymentAmountPlusButtonClick = 'PaymentAmountPlusButtonClick',
  PaymentMethodClick = 'PaymentMethodClick',
  PaymentMethodsScreenOpen = 'PaymentMethodsScreenOpen',
  PaymentMethodsScreenBackClick = 'PaymentMethodsScreenBackClick',
  PaymentMethodsReloadClick = 'PaymentMethodsReloadClick',
  WalletCardPlusClick = 'WalletCardPlusClick',
  WalletTransactionClick = 'WalletTransactionClick',
  WalletTransactionsReload = 'WalletTransactionsReload',
  WalletTransactionsShowMoreClick = 'WalletTransactionsShowMoreClick',
  TransactionDetailsCopyClick = 'TransactionDetailsCopyClick',
  WalletCalendarSelect = 'WalletCalendarSelect',
  WalletSearchShow = 'WalletSearchShow',
  HowToOpen = 'HowToOpen',
  HowToCloseClick = 'HowToCloseClick',
  HowToClearClick = 'HowToClearClick',
  HowToStoryViewed = 'HowToStoryViewed',
  DiscoverBrandsClick = 'DiscoverBrandsClick',
  DiscoverGamesClick = 'DiscoverGamesClick',
  DiscoverBonusesAllClick = 'DiscoverBonusesAllClick',
  DiscoverSearchClick = 'DiscoverSearchClick',
  DiscoverBarcodeScannerClick = 'DiscoverBarcodeScannerClick',
  CashCrabPosterClick = 'CashCrabPosterClick',
  SantaSlopeBannerClick = 'SantaSlopeBannerClick',
  LegianoBannerClick = 'LegianoBannerClick',
  GameBannerClick = 'GameBannerClick',
  FunIdBalanceItemClick = 'FunIdBalanceItemClick',
  AddNewBrandClick = 'AddNewBrandClick',
  BrandBalanceItemClick = 'BrandBalanceItemClick',
  GameListReloadClick = 'GameListReloadClick',
  TournamentClick = 'TournamentClick',
  FeatureClick = 'FeatureClick',
  BrandItemClick = 'BrandItemClick',
  CloseClick = 'CloseClick',
  BrandPlayClick = 'BrandPlayClick',
  Click = 'Click',
  ReloadClick = 'ReloadClick',
  ChangeCategoryClick = 'ChangeCategoryClick',
  LoadingMore = 'LoadingMore',
  AllGamesClick = 'AllGamesClick',
  FilterClick = 'FilterClick',
  GameDetailsBackClick = 'GameDetailsBackClick',
  GameItemClick = 'GameItemClick',
  BrandPlayError = 'BrandPlayError',
  ConnectClick = 'ConnectClick',
  GamePlayClick = 'GamePlayClick',
  BrandDetailsCloseClick = 'BrandDetailsCloseClick',
  BrandRecoverPasswordClick = 'BrandRecoverPasswordClick',
  BrandListChangeCategoryClick = 'BrandListChangeCategoryClick',
  BrandListBackClick = 'BrandListBackClick',
  LoginBrandStart = 'LoginBrandStart',
  LoginBrandError = 'LoginBrandError',
  LoginBrandSuccess = 'LoginBrandSuccess',
  LoginBrandChangePasswordVisible = 'LoginBrandChangePasswordVisible',
  LoginBrandLoginAndPlay = 'LoginBrandLoginAndPlay',
  LoginBrandForgotPassword = 'LoginBrandForgotPassword',
  BottomBarClick = 'BottomBarClick',
  LanguageClick = 'LanguageClick',
  TermsOpenClick = 'TermsOpenClick',
  PrivacyOpenClick = 'PrivacyOpenClick',
  ZendeskClick = 'ZendeskClick',
  ChangeBiometricClick = 'ChangeBiometricClick',
  SendEmailClick = 'SendEmailClick',
  WebViewError = 'WebViewError',
  WebViewBackClick = 'WebViewBackClick',
  WebViewOpenExternalBrowserClick = 'WebViewOpenExternalBrowserClick',
  WebViewGoForwardClick = 'WebViewGoForwardClick',
  WebViewGoBackClick = 'WebViewGoBackClick',
  WebViewRefreshClick = 'WebViewRefreshClick',
  ScreenPullToRefresh = 'ScreenPullToRefresh',
  SwiperGameLaunched = 'SwiperGameLaunched',
  SwiperGameSwiped = 'SwiperGameSwiped',
  SwiperTabClick = 'SwiperTabClick',
  PromoCatalogItemClick = 'PromoCatalogItemClick',
  PromoCatalogFilterClick = 'PromoCatalogFilterClick',
  PromoDetailsGetClick = 'PromoDetailsGetClick',
  PromoDetailsBackClick = 'PromoDetailsBackClick',
  PromoDetailsTermsClick = 'PromoDetailsTermsClick',
  PromoClick = 'PromoClick',
  SpecialOffersAllClick = 'SpecialOffersAllClick',
  SpecialOffersReloadClick = 'SpecialOffersReloadClick',
  SpecialFeatureClick = 'SpecialFeatureClick',
  BrandConnectionBonusBannerClick = 'BrandConnectionBonusBannerClick',
  BonusDetailsGetClick = 'BonusDetailsGetClick',
  BannerClick = 'BannerClick',
  FirstTimeDeposit = 'FirstTimeDeposit',
  KycStatusChanged = 'KycStatusChanged',
  AuthEnterPhone = 'AuthEnterPhone',
  AuthConfirmPhone = 'AuthConfirmPhone',
  RegistrationHomeAddress = 'RegistrationHomeAddress',
  RegistrationPersonalDetails = 'RegistrationPersonalDetails',
  Payment = 'Payment',
  PaymentResult = 'PaymentResult',
  Wallet = 'Wallet',
  WalletCard = 'WalletCard',
  WalletLink = 'WalletLink',
  DiscoverNewGameCategory = 'DiscoverNewGameCategory',
  DiscoverLiveCasinoCategory = 'DiscoverLiveCasinoCategory',
  DiscoverPopularCategory = 'DiscoverPopularCategory',
  TopBrandsAllClick = 'TopBrandsAllClick',
  TopBrands = 'TopBrands',
  GameDetails = 'GameDetails',
  GameDetailsGameList = 'GameDetailsGameList',
  Search = 'Search',
  SearchBrands = 'SearchBrands',
  SearchGames = 'SearchGames',
  SearchRecommendedGames = 'SearchRecommendedGames',
  SearchRecommendedBrands = 'SearchRecommendedBrands',
  BrandDetails = 'BrandDetails',
  BrandLogin = 'BrandLogin',
  AfterPayment = 'AfterPayment',
  Reconnect = 'Reconnect',
  FirstDeposit = 'FirstDeposit',
  LoginBrand = 'LoginBrand',
  BlockedBrand = 'BlockedBrand',
  OpenBrand = 'OpenBrand',
  Bonus = 'Bonus',
  DiscoverBonus = 'DiscoverBonus',
  WalletBonus = 'WalletBonus',
  KycBonus = 'KycBonus',
  PersonalTab = 'PersonalTab',
  BrandFeature = 'BrandFeature',
  Discover = 'Discover',
  BrandList = 'BrandList',
  Connected = 'Connected',
  NotConnected = 'NotConnected',
  BrandItemSettings = 'BrandItemSettings',
  DiscoverBalances = 'DiscoverBalances',
  BrandBalanceItem = 'BrandBalanceItem',
  Profile = 'Profile',
  ProfileConnected = 'ProfileConnected',
  ProfileNotConnected = 'ProfileNotConnected',
  GameCatalog = 'GameCatalog',
  Tournament = 'Tournament',
  SpecialFeature = 'SpecialFeature',
  Play = 'Play',
  Offers = 'Offers',
  Passcode = 'Passcode',
  Settings = 'Settings',
  WebView = 'WebView',
  PromoDetails = 'PromoDetails',
  DownloadFunidApp = 'DownloadFunidApp',
  SideBarClick = 'SideBarClick',
}

export enum AmpEventsSource {
  AuthEnterPhone = 'AuthEnterPhone',
  AuthConfirmPhone = 'AuthConfirmPhone',
  RegistrationHomeAddress = 'RegistrationHomeAddress',
  RegistrationPersonalDetails = 'RegistrationPersonalDetails',
  Payment = 'Payment',
  PaymentResult = 'PaymentResult',
  Wallet = 'Wallet',
  WalletCard = 'WalletCard',
  WalletLink = 'WalletLink',
  DiscoverNewGameCategory = 'DiscoverNewGameCategory',
  DiscoverLiveCasinoCategory = 'DiscoverLiveCasinoCategory',
  DiscoverPopularCategory = 'DiscoverPopularCategory',
  TopBrandsAllClick = 'TopBrandsAllClick',
  TopBrands = 'TopBrands',
  GameDetails = 'GameDetails',
  GameDetailsGameList = 'GameDetailsGameList',
  Search = 'Search',
  SearchBrands = 'SearchBrands',
  SearchGames = 'SearchGames',
  SearchRecommendedGames = 'SearchRecommendedGames',
  SearchRecommendedBrands = 'SearchRecommendedBrands',
  BrandDetails = 'BrandDetails',
  BrandLogin = 'BrandLogin',
  AfterPayment = 'AfterPayment',
  Reconnect = 'Reconnect',
  FirstDeposit = 'FirstDeposit',
  LoginBrand = 'LoginBrand',
  BlockedBrand = 'BlockedBrand',
  OpenBrand = 'OpenBrand',
  Bonus = 'Bonus',
  DiscoverBonus = 'DiscoverBonus',
  WalletBonus = 'WalletBonus',
  KycBonus = 'KycBonus',
  PersonalTab = 'PersonalTab',
  BrandFeature = 'BrandFeature',
  Discover = 'Discover',
  BrandList = 'BrandList',
  Connected = 'Connected',
  NotConnected = 'NotConnected',
  BrandItemSettings = 'BrandItemSettings',
  DiscoverBalances = 'DiscoverBalances',
  BrandBalanceItem = 'BrandBalanceItem',
  Profile = 'Profile',
  ProfileConnected = 'ProfileConnected',
  ProfileNotConnected = 'ProfileNotConnected',
  GameCatalog = 'GameCatalog',
  Tournament = 'Tournament',
  SpecialFeature = 'SpecialFeature',
  Play = 'Play',
  Offers = 'Offers',
  Passcode = 'Passcode',
  Settings = 'Settings',
  WebView = 'WebView',
  PromoDetails = 'PromoDetails',
}

@Injectable({
  providedIn: 'root',
})
export class AmplitudeService {
  private readonly platformId: object = inject(PLATFORM_ID);
  private readonly router: Router = inject(Router);
  private readonly appUser: AppUserService = inject(AppUserService);
  private destroyRef: DestroyRef = inject(DestroyRef);

  protected readonly user$ = this.appUser.user$;

  public init(): void {
    if (isPlatformBrowser(this.platformId)) {
      amplitude.init(environment.amplitudeKey, {
        autocapture: true,
        serverZone: 'EU',
      });

      this.user$
        .pipe(
          tap(user => {
            amplitude.setUserId(user?.externalId);
          }),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe();
    }
  }

  public send(name: AmpEventsName, event?: Record<string, string | number | boolean>): void {
    if (event && event['source'] === 'checkUrl') {
      event['source'] = this.buildSource();
    }

    if (event) {
      amplitude.track(name, event);
      // eslint-disable-next-line no-console
      console.log('track: ', name, event);
    } else {
      amplitude.track(name);
      // eslint-disable-next-line no-console
      console.log('track: ', name);
    }

    // TODO: temp push
    amplitude.flush();
  }

  private buildSource(): string {
    const currentUrl = this.router.url;
    const dialogNames = Object.values(DialogComponentName);

    const dialogMatch = dialogNames.find(name => currentUrl.includes(name));
    const dialogSuffix = dialogMatch ? `:${dialogMatch}Dialog` : '';

    const filteredUrl = currentUrl.includes('dialog')
      ? this.extractUrlSegment(currentUrl)
      : this.removeLanguagePrefix(currentUrl);

    return this.checkSourceEnum(filteredUrl) + dialogSuffix;
  }

  private extractUrlSegment(url: string): string {
    const match = url.match(/^\/[a-z]{2}\/([^()]+)\(/i);
    return match?.[1] || '';
  }

  private removeLanguagePrefix(url: string): string {
    return url.replace(/^\/[a-z]{2}\//, '');
  }

  private checkSourceEnum(value: string): string {
    switch (value) {
      case 'discovery':
        return AmpEventsSource.Discover;
      default:
        return value;
    }
  }
}
