/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { supportControllerInitWidget } from '../fn/support/support-controller-init-widget';
import { SupportControllerInitWidget$Params } from '../fn/support/support-controller-init-widget';

@Injectable({ providedIn: 'root' })
export class SupportService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `supportControllerInitWidget()` */
  static readonly SupportControllerInitWidgetPath = '/api/support/init-widget';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `supportControllerInitWidget()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  supportControllerInitWidget$Response(params: SupportControllerInitWidget$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return supportControllerInitWidget(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `supportControllerInitWidget$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  supportControllerInitWidget(params: SupportControllerInitWidget$Params, context?: HttpContext): Observable<string> {
    return this.supportControllerInitWidget$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}
