import { inject, Injectable } from '@angular/core';
import { CasinoListResponse } from 'core/capi/models/casino-list-response';
import { CasinoService } from 'core/capi/services/casino.service';
import { DefaultListRequestOptions, ListRequestOptions } from 'core/base/model/list-options.model';
import { PopulationService } from 'core/base/services/population.service';
import {
  CasinoFilterOptionModel,
  CasinoModel,
  CasinosResponseModel,
} from 'core/data/domain/casino.model';
import { CasinoMapper } from 'core/data/repository/casino/casino.mapper';
import { FilterOptionFactoryService } from 'core/features/filter/services/filter-option-factory.service';
import { FilterParserService } from 'core/features/filter/services/filter-parser.service';
import { LocalizationService } from 'core/features/localization/services/localization.service';
import { NotFoundService } from 'features/not-found/not-found.service';
import { Observable, tap } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CasinoRepository {
  private readonly casinoApi: CasinoService = inject(CasinoService);
  private readonly mapper: CasinoMapper = inject(CasinoMapper);
  private readonly requestParser: FilterParserService = inject(FilterParserService);
  private readonly filterFactory: FilterOptionFactoryService = inject(FilterOptionFactoryService);
  private readonly localization: LocalizationService = inject(LocalizationService);
  private readonly population: PopulationService = inject(PopulationService);
  private readonly notFoundService: NotFoundService = inject(NotFoundService);

  public getListResponse(
    options: ListRequestOptions = DefaultListRequestOptions,
  ): Observable<CasinosResponseModel> {
    return this.localization.trackChanges(
      this.casinoApi
        .getCasinos({
          'pagination[limit]': options.limit ? Number(options.limit) : undefined,
          filters: this.requestParser.parse({
            name: {
              $containsi: options.search,
            },
            casinoCategory: {
              name: {
                $eqi: options.category,
              },
            },
          }),
          populate: this.getPopulation(),
        })
        .pipe(map((response: CasinoListResponse) => this.mapper.mapFromResponse(response))),
    );
  }

  public getList(options?: ListRequestOptions): Observable<CasinoModel[]> {
    return this.getListResponse(options).pipe(map(({ data }: CasinosResponseModel) => data));
  }

  public getItem(options: { id: number | string }): Observable<CasinoModel> {
    return this.localization.trackChanges(
      this.casinoApi
        .getCasinos({
          populate: this.getPopulation(),
          filters: this.requestParser.parse({
            slug: {
              $eq: options.id,
            },
          }),
        })
        .pipe(
          tap(response => this.notFoundService.redirect(response?.data)),
          map(response => this.mapper.mapFrom(response.data?.[0] || {})),
        ),
    );
  }

  public getCasinoFilterOptions(
    options?: ListRequestOptions,
  ): Observable<CasinoFilterOptionModel[]> {
    return this.localization.trackChanges(
      this.getList(options).pipe(
        map((options: CasinoModel[]) =>
          options.map((option: CasinoModel) => ({
            ...option,
            queryParamName: option.name.toLowerCase(),
            count: option.offers?.length || 0,
          })),
        ),
        map((options: CasinoFilterOptionModel[]) => [
          this.filterFactory.createOption(
            /**
             * t(global.btn.allCasinos)
             */
            'global.btn.allCasinos',
            '/assets/icons/settings.svg',
            options.reduce((count, provider) => (provider?.offers?.length || 0) + count, 0),
          ),
          ...options,
        ]),
      ),
    );
  }

  private getPopulation(): Record<string, string> {
    const populateOffers = this.population.populate('offers', [
      'slug',
      'image',
      'offerCategory',
      'casino',
      'casino.icon',
      'casino.image',
      'casino.casinoCategory',
    ]);
    const populateHIWS = this.population.populate('hiws', ['image', 'steps', 'steps.image']);
    const populateFeatures = this.population.populate('features', [
      'image',
      'animation',
      'casino',
      'casino.domain',
      'casino.icon',
      'casino.image',
      'casino.casinoCategory',
    ]);

    return {
      '0': `
      logo,
      icon,
      image,
      video,
      screenshots,
      casinoDetails,
      casinoCategory,
      animation,
      games,
      ${populateOffers},
      ${populateHIWS},
      ${populateFeatures}
     `,
    };
  }
}
