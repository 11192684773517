import { inject, Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { CasinoFilterOptionModel } from 'core/data/domain/casino.model';

@Injectable({
  providedIn: 'root',
})
export class FilterOptionFactoryService {
  private transLoco: TranslocoService = inject(TranslocoService);

  public createOption(nameKey: string, url: string, count: number = 0): CasinoFilterOptionModel {
    const name: string = this.transLoco.translate(nameKey);

    return {
      name,
      count,
      id: '',
      queryParamName: null,
      icon: {
        url,
        alternativeText: name,
        height: 40,
        width: 40,
      },
    };
  }
}
