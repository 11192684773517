<ng-container *transloco="let t" [formGroup]="paymentGroup">
  <div *ngIf="heading" class="payments-title">{{ heading }}</div>
  <div *ngIf="payments" class="payments hidden-scrollbar">
    <ng-container *ngFor="let payment of payments | slice: 0 : maxPayments; index as index">
      <ng-container *ngTemplateOutlet="paymentCard; context: { payment, index }"></ng-container>
    </ng-container>

    <div #placholderContainer class="payment-placeholder"></div>

    <button
      *ngIf="payments.length > maxPayments"
      type="button"
      class="payment-btn"
      (click)="switchPaymentList()"
    >
      <tui-svg class="payment-btn--icon" [src]="'fidDropdown'"></tui-svg>
      {{ t('dialog.btn.showMore') }}
    </button>
  </div>

  <div class="payments-dropdown" [ngClass]="{ _hide: !isPaymentListShowed() }">
    <div class="payments-dropdown--head">
      <div class="payments-dropdown--title">{{ t('dialog.choosePayment') }}</div>
      <button
        appearance="icon"
        type="button"
        class="payments-dropdown--close"
        [fidSendAnalytics]="[ampEventsName.PaymentMethodsScreenBackClick, {}]"
        (click)="switchPaymentList()"
        tuiButton
      >
        <tui-svg [src]="'fidCross'"></tui-svg>
      </button>
    </div>
    <div class="payments-dropdown--list">
      <ng-container *ngFor="let payment of payments; index as index">
        <ng-container *ngTemplateOutlet="paymentCard; context: { payment, index }"></ng-container>
      </ng-container>
    </div>
  </div>

  <ng-template #paymentCard let-payment="payment" let-index="index">
    <label class="payment">
      <input
        type="radio"
        class="payment--radio"
        [value]="payment.alias"
        [fidSendAnalytics]="[
          ampEventsName.PaymentMethodsScreenBackClick,
          {
            onlyFunID: payment.alias === 'pgw_funid',
            paymentMethodTitle: payment.alias,
            paymentMethodId: payment.alias,
            paymentMethodCurrency: payment.currency,
            paymentMethodMinAmount: payment.min,
            paymentMethodMaxAmount: payment.max,
          },
        ]"
        tuiRadio
        formControlName="payment"
      />
      <div class="payment--pic">
        <img alt="" class="payment--image" [ngSrc]="payment.icon" fill />
      </div>
      <ul class="payment--info">
        <li>
          {{ t('global.text.min') }}:
          <span class="payment--amount">{{
            payment.min | currency: payment.currency : 'symbol' : '1.0-2'
          }}</span>
        </li>
        <li>
          {{ t('global.text.max') }}:
          <span class="payment--amount">{{
            payment.max | currency: payment.currency : 'symbol' : '1.0-2'
          }}</span>
        </li>
      </ul>
    </label>
  </ng-template>
</ng-container>
