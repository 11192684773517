import { DestroyRef, inject, Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, take, tap } from 'rxjs';
import { ApiService } from 'core/fapi/services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

interface clientInfoCountryModel {
  code: string;
  name: string;
}

interface clientInfoModel {
  ip: string;
  country: clientInfoCountryModel;
}

@Injectable({ providedIn: 'root' })
export class ClientInfoService {
  private readonly apiService: ApiService = inject(ApiService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  public info$: BehaviorSubject<clientInfoModel | null> =
    new BehaviorSubject<clientInfoModel | null>(null);

  constructor() {
    this.getInfo().pipe(take(1), takeUntilDestroyed(this.destroyRef)).subscribe();
  }

  public getInfo(): Observable<clientInfoModel> {
    return this.apiService.infoControllerGetInfo().pipe(
      map(response => response.data),
      tap(response => this.info$.next(response)),
    );
  }
}
