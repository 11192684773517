/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CasinoListResponse } from '../models/casino-list-response';
import { CasinoResponse } from '../models/casino-response';
import { getCasinos } from '../fn/casino/get-casinos';
import { GetCasinos$Params } from '../fn/casino/get-casinos';
import { getCasinosId } from '../fn/casino/get-casinos-id';
import { GetCasinosId$Params } from '../fn/casino/get-casinos-id';

@Injectable({ providedIn: 'root' })
export class CasinoService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCasinos()` */
  static readonly GetCasinosPath = '/casinos';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCasinos()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCasinos$Response(params?: GetCasinos$Params, context?: HttpContext): Observable<StrictHttpResponse<CasinoListResponse>> {
    return getCasinos(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCasinos$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCasinos(params?: GetCasinos$Params, context?: HttpContext): Observable<CasinoListResponse> {
    return this.getCasinos$Response(params, context).pipe(
      map((r: StrictHttpResponse<CasinoListResponse>): CasinoListResponse => r.body)
    );
  }

  /** Path part for operation `getCasinosId()` */
  static readonly GetCasinosIdPath = '/casinos/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCasinosId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCasinosId$Response(params: GetCasinosId$Params, context?: HttpContext): Observable<StrictHttpResponse<CasinoResponse>> {
    return getCasinosId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCasinosId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCasinosId(params: GetCasinosId$Params, context?: HttpContext): Observable<CasinoResponse> {
    return this.getCasinosId$Response(params, context).pipe(
      map((r: StrictHttpResponse<CasinoResponse>): CasinoResponse => r.body)
    );
  }

}
