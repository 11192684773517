<div *ngIf="howItWorksItem$ | async as howItWorksItem; else loader" class="connect-dialog">
  <div class="connect-dialog--body">
    <p class="connect-dialog--description">{{ howItWorksItem.description }}</p>

    <div
      class="connect-dialog--progress progress"
      [attr.style]="'--transition-speed: ' + carouselTimerConfig + 'ms'"
    ></div>

    <fid-carousel #carousel [data]="howItWorksItem.steps">
      <ng-template #slide let-slide>
        <div class="connect-dialog--step">
          <p class="connect-dialog--info">{{ slide.description }}</p>

          <img
            *ngIf="slide.image.url as url"
            class="connect-dialog--image"
            [ngSrc]="url | cdnUrl"
            [alt]="slide.image.alternativeText"
            [width]="slide.image.width"
            [height]="slide.image.height"
          />
        </div>
      </ng-template>
    </fid-carousel>

    <button
      appearance="whiteblock"
      class="connect-dialog--btn"
      (click)="nextSlide(howItWorksItem.steps.length)"
      tuiButton
    >
      {{ howItWorksItem.button }}
    </button>
  </div>
</div>

<ng-template #loader>
  <fid-loader></fid-loader>
</ng-template>
