<ng-container *transloco="let t" [formGroup]="paymentCalculatorGroup">
  <fid-amount-calculator
    class="form--row"
    [formGroupName]="'amountGroup'"
    [selectedPayment]="selectedPayment"
  ></fid-amount-calculator>

  <fid-payments-carousel
    class="form--row"
    [heading]="t('global.text.yourPayment')"
    [payments]="payments"
    [formGroupName]="'paymentGroup'"
    (paymentChanged)="setPayment($event)"
  ></fid-payments-carousel>
</ng-container>
