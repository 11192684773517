import { inject, Injectable } from '@angular/core';
import { HiwService } from 'core/capi/services/hiw.service';
import { HowItWorksItemType, HowItWorksModel } from 'core/data/domain/how-it-works.model';
import { HowItWorksMapper } from 'core/data/repository/how-it-works/how-it-works.mapper';
import { FilterParserService } from 'core/features/filter/services/filter-parser.service';
import { LocalizationService } from 'core/features/localization/services/localization.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HowItWorksRepository {
  private readonly howItWorksApi: HiwService = inject(HiwService);
  private readonly filtersParser: FilterParserService = inject(FilterParserService);
  private readonly mapper: HowItWorksMapper = inject(HowItWorksMapper);
  private readonly localization: LocalizationService = inject(LocalizationService);

  private readonly populate = {
    '0': 'image,steps,steps.image',
  };

  public getByType(type: HowItWorksItemType): Observable<HowItWorksModel[]> {
    return this.localization.trackChanges(
      this.howItWorksApi
        .getHiws({
          populate: this.populate,
          filters: this.filtersParser.parse({
            type: {
              $eq: type,
            },
          }),
        })
        .pipe(map(({ data }) => this.mapper.mapFromList(data || []))),
    );
  }

  public getItem(options: { id: string | number }): Observable<HowItWorksModel> {
    return this.localization.trackChanges(
      this.howItWorksApi
        .getHiws({
          populate: this.populate,
          filters: this.filtersParser.parse({
            slug: {
              $eq: options.id,
            },
          }),
        })
        .pipe(map(item => this.mapper.mapFrom(item.data?.[0] || {}))),
    );
  }
}
