export interface ListRequestOptions {
  search?: string | null;
  category?: string | null;
  limit?: string | null;
  provider?: string | null;
  casino?: string | null;
}

export const DefaultListRequestOptions: ListRequestOptions = {
  search: null,
  category: null,
};

export interface CountableListEntity {
  data: {
    attributes: {
      count: number;
    };
  };
}
