import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'fid-badge',
  standalone: true,
  imports: [],
  templateUrl: './badge.component.html',
  styleUrl: './badge.component.scss',
})
export class BadgeComponent {
  @Input({ required: true })
  public title!: string;

  @Input()
  @HostBinding('class')
  public variant: 'neutral' | 'danger' = 'neutral';
}
