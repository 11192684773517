<ng-container *ngIf="onelink$ | async as link">
  <a
    *transloco="let t"
    appearance="outline"
    class="app-link"
    [icon]="funidIcon"
    [href]="link"
    [fidSendAnalytics]="[
      ampEventsName.DownloadFunidApp,
      { source: 'checkUrl', isUserExist: isAuth() },
    ]"
    target="_blank"
    size="l"
    tuiButton
  >
    {{ t('global.link.app') }}
  </a>
</ng-container>

<ng-template #funidIcon>
  <tui-svg src="fidFunid"></tui-svg>
</ng-template>
