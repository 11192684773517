import { Injectable } from '@angular/core';
import { ListMapper, Mapper, ResponseMapper } from 'core/base/model/mapper.model';
import { UserProjectModel, UserProjectResponseModel } from 'features/user/model/user.model';
import { UserProjectDto } from 'core/fapi/models/user-project-dto';
import { UserProjectResponseDto } from 'core/fapi/models/user-project-response-dto';

@Injectable({
  providedIn: 'root',
})
export class UserMapper
  implements
    ResponseMapper<UserProjectResponseDto, UserProjectResponseModel>,
    ListMapper<UserProjectDto, UserProjectModel>,
    Mapper<UserProjectDto, UserProjectModel>
{
  public mapFrom(dto: UserProjectDto): UserProjectModel {
    return {
      ...dto,
    };
  }

  public mapFromList(items: UserProjectDto[]): UserProjectModel[] {
    return items.map(item => this.mapFrom(item));
  }

  public mapFromResponse(response: UserProjectResponseDto): UserProjectResponseModel {
    return {
      data: this.mapFromList(response.data || []),
    };
  }
}
