import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FidQueryParam } from 'core/base/constants/query-params.constants';
import { distinctUntilChanged, map, Observable } from 'rxjs';
import { WINDOW } from '@ng-web-apis/common';

@Injectable({
  providedIn: 'root',
})
export class QueryParamsService {
  private readonly window = inject(WINDOW);
  private router: Router = inject(Router);
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  public selectParameter(name: FidQueryParam): Observable<string | null> {
    return this.activatedRoute.queryParams.pipe(
      map((params: Params) => (params[name] ? params[name] : null)),
      distinctUntilChanged(),
    );
  }

  public selectParameters(...names: FidQueryParam[]): Observable<(string | null)[]> {
    return this.activatedRoute.queryParams.pipe(
      map((params: Params) => names.map(name => params[name] ?? null)),
    );
  }

  public setParameter(
    name: FidQueryParam,
    value: string | number | null,
    replaceUrl?: boolean,
  ): void {
    const currentScrollPosition = this.window.scrollY;

    this.router
      .navigate([], {
        replaceUrl,
        queryParams: { [name]: value },
        queryParamsHandling: 'merge',
      })
      .then(() => {
        this.window.scrollTo(0, currentScrollPosition);
      });
  }

  public removeParameter(name: FidQueryParam): void {
    const currentScrollPosition = window.scrollY;

    this.router
      .navigate([], {
        queryParams: { [name]: null },
        queryParamsHandling: 'merge',
      })
      .then(() => {
        window.scrollTo(0, currentScrollPosition);
      });
  }
}
