import { inject, Injectable } from '@angular/core';
import { ProjectService } from 'core/fapi/services/project.service';
import { map, Observable } from 'rxjs';
import { ProjectBalanceDto } from 'core/fapi/models/project-balance-dto';
import { ProjectLoginDto } from 'core/fapi/models/project-login-dto';
import { ProjectPlayResponseDto } from 'core/fapi/models/project-play-response-dto';

@Injectable({ providedIn: 'root' })
export class BrandService {
  private readonly projectService: ProjectService = inject(ProjectService);

  public getPlayUrl(domain: string, code?: string, id?: number): Observable<string> {
    if (id) {
      return this.getSportUrl(domain, id);
    } else if (code) {
      return this.getGameUrl(domain, code);
    } else {
      return this.getBrandUrl(domain);
    }
  }

  public getOpenUrl(domain: string, id?: string): Observable<string> {
    if (id) {
      return this.getOfferUrl(domain, id);
    } else {
      return this.getBrandUrl(domain);
    }
  }

  public getBalance(params: string): Observable<ProjectBalanceDto> {
    return this.projectService
      .projectControllerGetBalance({ domain: params })
      .pipe(map(response => response.data));
  }

  public connectBrand(params: ProjectLoginDto): Observable<ProjectPlayResponseDto> {
    return this.projectService
      .projectControllerProjectConnect({ body: params })
      .pipe(map(response => response.data));
  }

  private getBrandUrl(domain: string): Observable<string> {
    return this.projectService
      .projectControllerPlayProject({ body: { domain } })
      .pipe(map(item => item.data.url));
  }

  private getOfferUrl(domain: string, id: string): Observable<string> {
    return this.projectService
      .projectControllerOpenOffer({ body: { domain, id } })
      .pipe(map(item => item.data.url));
  }

  private getGameUrl(domain: string, code: string): Observable<string> {
    return this.projectService
      .projectControllerPlayProjectGame({ body: { domain, code } })
      .pipe(map(item => item.data.url));
  }

  private getSportUrl(domain: string, id: number): Observable<string> {
    return this.projectService
      .projectControllerGetSportUrl({ body: { domain, id } })
      .pipe(map(item => item.data.url));
  }
}
