import { Injectable } from '@angular/core';
import { ListMapper, Mapper, ResponseMapper } from 'core/base/model/mapper.model';
import {
  AllowedCountryModel,
  AllowedCountriesItemResponse,
} from 'core/data/domain/allowed-country.model';
import { CountryCurrencyResponseDto } from 'core/fapi/models/country-currency-response-dto';

@Injectable({
  providedIn: 'root',
})
export class AllowedCountriesMapper
  implements
    Mapper<AllowedCountriesItemResponse, AllowedCountryModel>,
    ListMapper<AllowedCountriesItemResponse, AllowedCountryModel>,
    ResponseMapper<CountryCurrencyResponseDto, AllowedCountryModel[]>
{
  public mapFrom(data: AllowedCountriesItemResponse): AllowedCountryModel {
    return {
      code: data.code,
      currency: data.currency,
      name: data.name,
      prefix: data.prefix,
    };
  }

  public mapFromList(data: AllowedCountriesItemResponse[]): AllowedCountryModel[] {
    return data.map((item: AllowedCountriesItemResponse) => this.mapFrom(item));
  }

  public mapFromResponse({ result }: CountryCurrencyResponseDto): AllowedCountryModel[] {
    return this.mapFromList(result);
  }
}
