<swiper-container #sliderRef class="slider" init="false">
  <ng-container *ngIf="slidesRowsCount$ | async as slidesRowsCount">
    <swiper-slide
      *ngFor="let slideGroup of data | chunk: slidesRowsCount; trackBy: '' | trackByProp"
      class="slide"
    >
      <ng-container *ngFor="let item of slideGroup">
        <ng-container
          *ngTemplateOutlet="slideTemplateRef; context: { $implicit: item }"
        ></ng-container>
      </ng-container>
    </swiper-slide>
  </ng-container>
</swiper-container>
