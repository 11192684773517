import { inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';

enum DeviceType {
  Unknown = 'unknown',
  Android = 'android',
  IOS = 'ios',
  Desktop = 'web',
}

@Injectable({ providedIn: 'root' })
export class DeviceInfoService {
  private readonly window = inject(WINDOW);

  private readonly prefix = 'website+';
  private readonly isAndroid = /Android/i.test(this.window.navigator.userAgent);
  private readonly isIOS = /iPhone|iPad/i.test(this.window.navigator.userAgent);
  private readonly isDesktop = !this.isAndroid && !this.isIOS;

  public getDeviceType(): string {
    let deviceType: string = DeviceType.Unknown;
    if (this.isAndroid) {
      deviceType = DeviceType.Android;
    } else if (this.isIOS) {
      deviceType = DeviceType.IOS;
    } else if (this.isDesktop) {
      deviceType = DeviceType.Desktop;
    }
    return this.prefix + deviceType;
  }
}
