import { ChangeDetectionStrategy, Component, inject, Input, signal } from '@angular/core';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { combineLatest, map, tap } from 'rxjs';
import { UserRepository } from 'core/data/repository/user/user.repository';
import { CasinoCardComponent } from 'features/casino/casino-card/casino-card.component';
import { PlayButtonDirective } from 'shared/directives/play-button.directive';
import { TrackByPropPipe } from 'core/base/utils/track-by-prop.pipe';
import { TuiButtonModule } from '@taiga-ui/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { OpenDialogDirective } from 'core/features/dialog/directives/open-dialog.directive';
import { LoaderComponent } from 'shared/components/loader/loader.component';
import { AppUserService } from 'features/user/services/app-user.service';
import { DialogData } from 'features/project/model/project.model';
import { SendAnalyticsDirective } from 'shared/directives/send-analytics.directive';
import { AmpEventsName } from 'shared/services/amplitude.service';

@Component({
  selector: 'fid-project-choose-list',
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    CasinoCardComponent,
    NgForOf,
    PlayButtonDirective,
    TrackByPropPipe,
    TuiButtonModule,
    TranslocoDirective,
    OpenDialogDirective,
    LoaderComponent,
    SendAnalyticsDirective,
  ],
  templateUrl: './project-choose-list.component.html',
  styleUrl: './project-choose-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectChooseListComponent {
  private readonly userRepository = inject(UserRepository);
  private readonly appUser: AppUserService = inject(AppUserService);

  protected readonly connected$ = this.userRepository.userConnectedProjects$;
  protected readonly recommended$ = this.userRepository.userNotConnectedProjects$;
  protected readonly user$ = this.appUser.user$;

  protected readonly isLoading = signal(true);

  protected readonly ampEventsName = AmpEventsName;

  @Input() public dialogData!: DialogData | null;

  protected readonly page$ = combineLatest([this.connected$, this.recommended$, this.user$]).pipe(
    map(([connected, recommended, user]) => ({ connected, recommended, user })),
    tap(() => this.isLoading.set(false)),
  );
}
