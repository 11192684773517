import { DestroyRef, inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthCodeResponseDto } from 'core/fapi/models/auth-code-response-dto';
import { AuthRefreshResponseDto } from 'core/fapi/models/auth-refresh-response-dto';
import { InstantAuthResponseDto } from 'core/fapi/models/instant-auth-response-dto';
import { InstantInitResponseDto } from 'core/fapi/models/instant-init-response-dto';
import { LoginResponseDto } from 'core/fapi/models/login-response-dto';
import { AuthService } from 'core/fapi/services/auth.service';
import {
  mapCodeResponse,
  mapInstantAuthResponse,
  mapInstantInitResponse,
  mapLoginResponse,
  mapRefreshResponse,
} from 'features/auth/helpers/response-mappers';
import {
  LoginHolder,
  LoginInitOptions,
  RegistrationDataResponse,
} from 'features/auth/model/auth.model';
import { AuthStorageService } from 'features/auth/services/auth-storage.service';
import { delay, map, Observable, take, tap } from 'rxjs';
import { RegistrationRequest } from 'core/fapi/models/registration-request';
import { DialogCloseService } from 'core/features/dialog/services/dialog-close.service';
import { DialogOpenService } from 'core/features/dialog/services/dialog-open.service';
import { AuthTempHolderService } from 'features/auth/services/auth-temp-storage.service';
import { AlertService } from 'core/features/alerts/services/alert.service';
import { AlertStatus } from 'core/features/alerts/constants/alert.constants';
import { AppUserService } from 'features/user/services/app-user.service';
import { AvatarRepository } from 'core/data/repository/avatar/avatar.repository';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DialogComponentName } from 'core/features/dialog/constants/dialogs-list.constants';
import { TranslocoService } from '@ngneat/transloco';
import { DiscoveryRepository } from 'core/data/repository/discovery/discovery.repository';

@Injectable({
  providedIn: 'root',
})
export class AppAuthService {
  private router: Router = inject(Router);
  private alert: AlertService = inject(AlertService);
  private destroyRef: DestroyRef = inject(DestroyRef);
  private authService: AuthService = inject(AuthService);
  private appUserService: AppUserService = inject(AppUserService);
  private storage: AuthStorageService = inject(AuthStorageService);
  private dialogOpen: DialogOpenService = inject(DialogOpenService);
  private dialogClose: DialogCloseService = inject(DialogCloseService);
  private avatarsRepository: AvatarRepository = inject(AvatarRepository);
  private authTempHolder: AuthTempHolderService = inject(AuthTempHolderService);
  private transloco: TranslocoService = inject(TranslocoService);
  private discoveryRepository: DiscoveryRepository = inject(DiscoveryRepository);

  public isAuthenticated(): Observable<boolean> {
    return this.storage.storage$.pipe(map(data => Boolean(data.authToken)));
  }

  public loginInitQr(): Observable<InstantInitResponseDto> {
    return this.authService.authControllerInstantInit().pipe(mapInstantInitResponse);
  }

  public loginAuthQr(qrcAuthToken: string): Observable<InstantAuthResponseDto> {
    return this.authService.authControllerInstantAuth({ body: { token: qrcAuthToken } }).pipe(
      mapInstantAuthResponse,
      tap(tokens => this.setTokens(tokens)),
    );
  }

  public loginInit(phone: string, actionToken?: string): Observable<AuthCodeResponseDto> {
    return this.authService
      .authControllerCreateCode({
        body: {
          phone,
          actionToken,
        },
      })
      .pipe(mapCodeResponse);
  }

  public loginAuth({ code, phone, actionToken }: LoginInitOptions): Observable<LoginResponseDto> {
    return this.authService
      .authControllerLogin({
        body: {
          code,
          phone,
          actionToken,
        },
      })
      .pipe(
        mapLoginResponse,
        tap(response => {
          if (response.registration.status === 'required') {
            this.authTempHolder.set({
              refreshToken: response.refreshToken,
              accessToken: response.accessToken,
              phone: phone,
            });

            this.dialogOpen.open(DialogComponentName.Registration);
          } else {
            this.setTokens(response);

            setTimeout(() => {
              this.dialogClose.close();
              this.authAlertWithAvatar();
            }, 500);
          }
        }),
      );
  }

  public refreshAuthToken(refreshToken: string): Observable<AuthRefreshResponseDto> {
    return this.authService.authControllerRefreshToken({ body: { refreshToken } }).pipe(
      mapRefreshResponse,
      tap(tokens => this.setTokens(tokens)),
    );
  }

  public registration(registrationData: RegistrationRequest): RegistrationDataResponse {
    return this.authService.authControllerRegistration({ body: registrationData }).pipe(
      tap(() => {
        this.setTokens(this.authTempHolder.get());
      }),
      delay(500),
      tap(() => {
        this.dialogClose.close();
      }),
    );
  }

  public redirect(): void {
    this.router.navigate(['/']);
    this.discoveryRepository.getPageResponse();
  }

  public logout(): void {
    this.storage.removeTokens();
    this.redirect();
    this.authService
      .authControllerLogout()
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  private setTokens(tokens: LoginHolder | null): void {
    if (tokens?.accessToken) {
      this.storage.setAuthToken(tokens.accessToken);
    }

    if (tokens?.refreshToken) {
      this.storage.setRefreshToken(tokens.refreshToken);
    }

    this.authTempHolder.clear();
  }

  private authAlertWithAvatar(): void {
    this.appUserService.user$
      .pipe(
        take(1),
        tap(user => {
          if (user) {
            this.avatarsRepository
              .getAvatar(Number(user.avatar))
              .pipe(
                take(1),
                tap(avatar => {
                  this.alert.show({
                    status: AlertStatus.Neutral,
                    /**
                     * t(global.alert.welcomeUser, { user: user.name })
                     */
                    title: this.transloco.translate('global.alert.welcomeUser', {
                      user: user.name,
                    }),
                    /**
                     * t(global.alert.alreadyHaveAccount)
                     */
                    text: this.transloco.translate('global.alert.alreadyHaveAccount'),
                    image: avatar.image,
                    imageBgColor: avatar.backgroundColor,
                  });
                }),
                takeUntilDestroyed(this.destroyRef),
              )
              .subscribe();
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }
}
