import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiButtonModule, TuiFlagPipeModule, TuiSvgModule } from '@taiga-ui/core';
import { LoginFormComponent } from 'features/auth/components/login-form/login-form.component';
import { AmpEventsName, AmplitudeService } from 'shared/services/amplitude.service';

@Component({
  selector: 'fid-login-dialog',
  standalone: true,
  imports: [
    NgOptimizedImage,
    TranslocoDirective,
    TuiButtonModule,
    TuiFlagPipeModule,
    TuiSvgModule,
    LoginFormComponent,
  ],
  templateUrl: './login-dialog.component.html',
  styleUrl: '../styles/auth-dialog.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginDialogComponent implements OnInit {
  private readonly amplitudeService: AmplitudeService = inject(AmplitudeService);

  public ngOnInit() {
    this.amplitudeService.send(AmpEventsName.AuthStart, { type: 'sms', source: 'checkUrl' });
  }
}
