/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CommonResponse } from '../models/common-response';
import { getCommon } from '../fn/common/get-common';
import { GetCommon$Params } from '../fn/common/get-common';

@Injectable({ providedIn: 'root' })
export class CommonService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCommon()` */
  static readonly GetCommonPath = '/common';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCommon()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCommon$Response(params?: GetCommon$Params, context?: HttpContext): Observable<StrictHttpResponse<CommonResponse>> {
    return getCommon(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCommon$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCommon(params?: GetCommon$Params, context?: HttpContext): Observable<CommonResponse> {
    return this.getCommon$Response(params, context).pipe(
      map((r: StrictHttpResponse<CommonResponse>): CommonResponse => r.body)
    );
  }

}
