import { Validators } from '@angular/forms';

export const ValidationErrorsText = {
  Required: 'dialog.form.requiredField',
  MinLength: 'dialog.form.minLength',
  MaxLength: 'dialog.form.maxLength',
};

export const LOGIN_VALIDATOR = [Validators.required, Validators.minLength(5)];
export const PASSWORD_VALIDATOR = [Validators.required, Validators.minLength(8)];
export const NAME_VALIDATOR = [
  Validators.required,
  Validators.minLength(2),
  Validators.maxLength(20),
];
