import { RegistrationData } from 'features/auth/model/auth.model';

export const RegistrationStepTitlesKeys = [
  /**
   * t(dialog.reg.personalDetails)
   */
  'dialog.reg.personalDetails',
  /**
   * t(dialog.reg.homeAddress)
   */
  'dialog.reg.homeAddress',
  /**
   * t(dialog.reg.chosePic)
   */
  'dialog.reg.chosePic',
];

export const RegistrationTuiDayFormat = {
  dayFormat: 'DMY',
  separator: '/',
};

export const InitialFormValues: RegistrationData = {
  acceptTermsAndConditions: true,
  acceptPrivacyPolicy: true,
  gender: 'male',
};
