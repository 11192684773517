export type OffsetPagination = {
  limit?: number;
  start?: number;
  total?: number;
};

export interface ResponseMeta {
  pagination: OffsetPagination;
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}
