<div *transloco="let t" class="form" [formGroup]="formGroup">
  <div class="form--row">
    <div class="form--field">
      <tui-select
        [tuiTextfieldLabelOutside]="true"
        [valueContent]="personValueContent"
        [tuiDropdownLimitWidth]="'min'"
        [tuiDropdownAlign]="'left'"
        [formControlName]="'country'"
        ngSkipHydration
      >
        {{ t('dialog.form.country') }}
        <ng-container *ngIf="allowedCountries$ | async as allowedCountries">
          {{ allowedCountries[0] }}
          <fid-country-search-list
            *tuiDataList
            [items]="allowedCountries"
          ></fid-country-search-list>
        </ng-container>
      </tui-select>
      <ng-template #personValueContent let-data>
        <div class="uppercase-name">{{ data }}</div>
      </ng-template>
      <tui-error [formControlName]="'country'" [error]="[] | tuiFieldError | async" />
    </div>
  </div>

  <div class="form--row">
    <div class="form--field">
      <tui-select
        [tuiTextfieldLabelOutside]="true"
        [disabled]="formGroup.controls['currency'].value"
        formControlName="currency"
      >
        {{ t('dialog.form.currency') }}
        <ng-container *ngIf="countryCurrencies$ | async as countryCurrencies">
          <tui-data-list *tuiDataList>
            <button
              *ngFor="let item of countryCurrencies"
              type="button"
              [value]="item.code"
              tuiOption
            >
              {{ item.code }}
            </button>
          </tui-data-list>
        </ng-container>
      </tui-select>
      <tui-error [formControlName]="'currency'" [error]="[] | tuiFieldError | async" />
    </div>
  </div>

  <div class="form--row">
    <div class="form--field">
      <tui-input [formControlName]="'address'" [tuiTextfieldLabelOutside]="true">
        {{ t('dialog.form.address') }}
      </tui-input>
      <tui-error [formControlName]="'address'" [error]="[] | tuiFieldError | async" />
    </div>
  </div>

  <div class="form--row">
    <div class="form--field">
      <tui-input [formControlName]="'city'" [tuiTextfieldLabelOutside]="true">
        {{ t('dialog.form.city') }}
      </tui-input>
      <tui-error [formControlName]="'city'" [error]="[] | tuiFieldError | async" />
    </div>
  </div>

  <div class="form--row">
    <div class="form--field">
      <tui-input [formControlName]="'postcode'" [tuiTextfieldLabelOutside]="true">
        {{ t('dialog.form.postcode') }}
      </tui-input>
      <tui-error [formControlName]="'postcode'" [error]="[] | tuiFieldError | async" />
    </div>
  </div>

  <div class="form--group">
    <div class="form--row">
      <div class="form--field">
        <tui-checkbox-labeled [formControlName]="'acceptTermsAndConditions'" [size]="'l'">
          {{ t('global.text.iAm18') }}
          <a href="{{ currentLang }}/info/privacy-policy" target="_blank" tuiLink>
            {{ t('global.text.privacyPolicy') }}
          </a>
          <a href="{{ currentLang }}/info/terms" target="_blank" tuiLink>
            {{ t('global.text.termsAndConditions') }}
          </a>
        </tui-checkbox-labeled>
        <tui-error
          [formControlName]="'acceptTermsAndConditions'"
          [error]="[] | tuiFieldError | async"
        />
      </div>
    </div>

    <div class="form--row">
      <div class="form--field">
        <tui-checkbox-labeled [formControlName]="'acceptPrivacyPolicy'" [size]="'l'">
          {{ t('global.text.recievePromos') }}
        </tui-checkbox-labeled>
        <tui-error [formControlName]="'acceptPrivacyPolicy'" [error]="[] | tuiFieldError | async" />
      </div>
    </div>
  </div>
</div>
