<div class="alert">
  <picture class="alert--image" [attr.style]="'--bg-color: ' + data.imageBgColor">
    <img
      *ngIf="data.image?.url as url; else defaultAlertImage"
      class="alert--pic"
      [alt]="data.image?.alternativeText || ''"
      [ngSrc]="url | cdnUrl"
      width="60"
      height="60"
    />
    <ng-template #defaultAlertImage>
      <img alt="" class="alert--pic" [ngSrc]="alertIconUrl[data.status]" width="60" height="60" />
    </ng-template>
  </picture>
  <div class="alert--content">
    <p *ngIf="data.title" class="alert--title">{{ data.title }}</p>
    <p *ngIf="data.text" class="alert--text">{{ data.text }}</p>
  </div>
</div>
