import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalizationService } from 'core/features/localization/services/localization.service';

@Injectable({
  providedIn: 'root',
})
export class NotFoundService {
  private readonly router: Router = inject(Router);
  private readonly localization: LocalizationService = inject(LocalizationService);

  public redirect(data: unknown[] | undefined): void {
    if (Array.isArray(data) && !data.length) {
      this.router.navigate([this.localization.getActiveLangCode(), '404']);
    }
  }
}
