import { CasinoModel } from 'core/data/domain/casino.model';
import { UserProjectModel } from 'features/user/model/user.model';
import { ProjectPaymentDepositDto } from 'core/fapi/models/project-payment-deposit-dto';
import { GamesListGameDto } from 'core/fapi/models/games-list-game-dto';
import { SportDto } from 'core/fapi/models/sport-dto';
import { WalletFullModel } from 'core/data/domain/wallet.model';

export enum TransactionType {
  Deposit = 'deposit',
  Withdrawal = 'withdrawal',
}

export interface CalcConfigModel {
  values: number[];
  defaultValue: number;
  step: number;
  buttons: {
    amount: boolean;
    controls: boolean;
  };
}

export type BrandConnectData = Partial<
  ProjectPaymentDepositDto & {
    passData: boolean;
    termsAndPolicy: boolean;
  }
>;

export interface BrandDialogData {
  casino: CasinoModel & Partial<UserProjectModel>;
  game?: GamesListGameDto;
  wallet?: WalletFullModel;
}

export interface BrandLoginDialogData {
  casino: CasinoModel;
}

export interface BrandPasswordRecoveryDialogData {
  casino: CasinoModel;
}

export interface DialogData {
  casino: CasinoModel;
  game: GamesListGameDto | undefined;
  sport: SportDto | undefined;
}
