import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const POSTCODE_PATTERN =
  /^(?:(?:[A-Za-z]{1,2}[0-9]{1,2}[A-Za-z0-9]{1,3}|[A-Za-z0-9]{1,5})[-\s]?[A-Za-z0-9]{1,4}|\d{4,10})$/;
export const EMAIL_PATTERN = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export function CustomPatternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!control.value) {
      return null;
    }
    const valid = regex.test(control.value);
    return valid ? null : error;
  };
}
