import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { RegistrationFormGroupName } from 'features/auth/model/auth.model';
import { AvatarRepository } from 'core/data/repository/avatar/avatar.repository';
import { AsyncPipe, NgClass, NgForOf, NgIf, NgOptimizedImage } from '@angular/common';
import { TrackByPropPipe } from 'core/base/utils/track-by-prop.pipe';
import { CdnUrlPipe } from 'core/base/utils/cdn-url.pipe';

@Component({
  selector: 'fid-registration-form-avatar',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgForOf,
    TrackByPropPipe,
    AsyncPipe,
    NgIf,
    CdnUrlPipe,
    NgOptimizedImage,
    NgClass,
  ],
  templateUrl: './registration-form-avatar.component.html',
  styleUrl: './registration-form-avatar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistrationFormAvatarComponent implements OnInit {
  private rootFormGroup = inject(FormGroupDirective);
  private readonly avatarsRepository = inject(AvatarRepository);

  protected formGroup!: FormGroup;
  protected activeIndex?: number;
  protected readonly avatarsList$ = this.avatarsRepository.getList();

  public ngOnInit(): void {
    this.formGroup = this.rootFormGroup.control.get(
      RegistrationFormGroupName.AvatarFormGroup,
    ) as FormGroup;
  }

  protected chooseAvatar(index: number): void {
    this.activeIndex = index;
  }
}
