<ng-container *transloco="let t">
  <p class="login-dialog-title">{{ t('dialog.login.title') }}</p>

  <form class="login-phone-form form" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="form--group _gap-s">
      <div class="form--title">{{ t('dialog.login.form.title') }}</div>

      <div class="form--row _multi-fields">
        <div class="form--field _multi-field _multi-field-1-3">
          <tui-select
            [tuiTextfieldLabelOutside]="true"
            [valueContent]="personValueContent"
            [tuiDropdownLimitWidth]="'min'"
            [tuiDropdownAlign]="'left'"
            [formControlName]="'prefix'"
            ngSkipHydration
          >
            {{ t('dialog.form.code') }}
            <ng-container *ngIf="allowedCountries$ | async as allowedCountries">
              {{ allowedCountries[0] }}
              <fid-phone-search-list
                *tuiDataList
                [items]="allowedCountries"
              ></fid-phone-search-list>
            </ng-container>
          </tui-select>
          <ng-template #personValueContent let-data>
            <div class="uppercase-name">{{ data }}</div>
          </ng-template>
        </div>
        <div class="form--field _multi-field">
          <tui-input-number
            [tuiTextfieldLabelOutside]="true"
            [formControlName]="'phone'"
            ngSkipHydration
          >
            {{ t('dialog.form.phone') }}
          </tui-input-number>
        </div>
      </div>
    </div>
    <div class="form--buttons login-phone-form--buttons">
      <button
        type="submit"
        [appearance]="'secondary'"
        [showLoader]="isPending()"
        [disabled]="isFormDisabled()"
        tuiButton
      >
        {{ t('global.btn.signIn') }}
      </button>
    </div>
  </form>

  <div class="login-phone-form--qr">
    <p class="login-phone-form--description">{{ t('dialog.login.scanqr') }}</p>
    <fid-qr-image [size]="120"></fid-qr-image>
  </div>

  <div class="login-steps">
    <div class="login-steps--item">
      <tui-svg class="login-steps--icon" [src]="'fidDownloadApp'"></tui-svg>
      {{ t('dialog.login.steps.step-1') }}
    </div>
    <div class="login-steps--item">
      <tui-svg class="login-steps--icon" [src]="'fidIdentify'"></tui-svg>
      {{ t('dialog.login.steps.step-2') }}
    </div>
    <div class="login-steps--item">
      <tui-svg class="login-steps--icon" [src]="'fidFunidOutline'"></tui-svg>
      {{ t('dialog.login.steps.step-3') }}
    </div>
  </div>

  <div class="login-footer">
    <fid-app-link class="login-footer--app-link"></fid-app-link>

    <button class="login-footer--btn" fidOpenDialog="funid-info">
      <tui-svg [style.height.px]="16" [style.width.px]="16" [src]="'fidInfo'"></tui-svg>
      {{ t('dialog.login.more') }}
    </button>
  </div>
</ng-container>
