import { inject, Injectable } from '@angular/core';
import { CommonResponse } from 'core/capi/models/common-response';
import { CommonService } from 'core/capi/services/common.service';
import { CommonModel } from 'core/data/domain/common.model';
import { CommonMapper } from 'core/data/repository/common/common.mapper';
import { map, Observable, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommonRepository {
  private readonly commonApi: CommonService = inject(CommonService);
  private readonly commonMapper: CommonMapper = inject(CommonMapper);

  private common$: Observable<CommonModel> = this.commonApi
    .getCommon({
      populate: {
        '0': 'qrcode,onelink',
      },
    })
    .pipe(
      map((response: CommonResponse) => this.commonMapper.mapFromResponse(response)),
      shareReplay(1),
    );

  public get(): Observable<CommonModel> {
    return this.common$;
  }
}
