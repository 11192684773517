<ng-container *transloco="let t">
  <div class="dialog-header">{{ t('casino.dialog.chooseCasino') }}</div>

  <div class="dialog-body">
    <fid-filter
      *ngIf="casinos$ | async as casinos; else loading"
      [template]="optionTemplate"
      [filterOptions]="casinos"
      [filterBy]="filterBy"
      [appearance]="'grid'"
    ></fid-filter>

    <ng-template #optionTemplate let-casino="option" let-isActive="isActive">
      <fid-filter-dialog-item
        [name]="casino.name"
        [alternativeText]="casino.icon.alternativeText"
        [url]="casino.queryParamName ? (casino.icon.url | cdnUrl) : casino.icon.url"
        [width]="casino.icon.width"
        [height]="casino.icon.height"
        [count]="casino.count"
        [isActive]="isActive"
        [fidSendAnalytics]="[
          ampEventsName.PromoCatalogFilterClick,
          {
            uniqueName: casino.name,
            brandDomain: casino.domain,
          },
        ]"
        (click)="close()"
      ></fid-filter-dialog-item>
    </ng-template>
  </div>

  <ng-template #loading>
    <fid-loader height="576"></fid-loader>
  </ng-template>
</ng-container>
