import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TuiHintModule, TuiSvgModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { TuiInputModule } from '@taiga-ui/kit';
import { FidQueryParam } from 'core/base/constants/query-params.constants';
import { QueryParamsService } from 'core/base/services/query-params.service';
import { SearchConfig } from 'core/features/search/model/search.model';
import { SEARCH_CONFIG } from 'core/features/search/tokens/search.tokens';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'fid-search',
  standalone: true,
  imports: [
    TuiInputModule,
    TuiTextfieldControllerModule,
    TuiHintModule,
    ReactiveFormsModule,
    CommonModule,
    TuiSvgModule,
  ],
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent {
  private config: SearchConfig = inject(SEARCH_CONFIG);
  private queryParams: QueryParamsService = inject(QueryParamsService);
  private destroyRef: DestroyRef = inject(DestroyRef);

  @Input() public placeholder: string = '';

  public searchControl: FormControl<string | null> = new FormControl('');

  constructor() {
    this.updateControlOnQueryChange();
    this.updateQueryOnControlChange();
  }

  private updateQueryOnControlChange(): void {
    this.searchControl.valueChanges
      .pipe(
        map(value => (!value?.length ? null : value)),
        filter(value => this.isValid(value)),
        debounceTime(this.config.debounceTimeInMs),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(value => this.queryParams.setParameter(FidQueryParam.Search, value));
  }

  private updateControlOnQueryChange(): void {
    this.queryParams
      .selectParameter(FidQueryParam.Search)
      .pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
      .subscribe(search => this.searchControl.setValue(search));
  }

  private isValid(value: string | null): boolean {
    return value === null || value.length >= this.config.minChar;
  }
}
