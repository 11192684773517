import { InjectionToken } from '@angular/core';
import { FidBreakpoint } from 'shared/services/breakpoints.service';
import { SwiperOptions } from 'swiper/types';

export type FidCarouselConfig = SwiperOptions & {
  slidesRowsCount: number;
  slidesRowsCountBreakpoints?: Partial<Record<FidBreakpoint, number>>;
};

export class CarouselConfigFactory {
  // Fabric method needed for token typing
  public static create(config: FidCarouselConfig): FidCarouselConfig {
    return config;
  }
}

export const CAROUSEL_CONFIG: InjectionToken<FidCarouselConfig> =
  new InjectionToken<FidCarouselConfig>('CarouselConfig', {
    factory: (): FidCarouselConfig => ({
      slidesRowsCount: 1,
      slidesPerView: 'auto',
      freeMode: {
        enabled: true,
        sticky: false,
      },
      breakpoints: {
        1280: {
          slidesPerView: 3,
          freeMode: false,
        },
      },
    }),
  });
