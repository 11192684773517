import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoginHolder } from 'features/auth/model/auth.model';

@Injectable({
  providedIn: 'root',
})
export class AuthTempHolderService {
  private readonly holderSubject$ = new BehaviorSubject<LoginHolder | null>(null);

  public set({ accessToken, refreshToken, phone }: LoginHolder): void {
    this.holderSubject$.next({
      accessToken: accessToken,
      refreshToken: refreshToken,
      phone: phone,
    });
  }

  public get(): LoginHolder | null {
    return this.holderSubject$.getValue();
  }

  public clear(): void {
    this.holderSubject$.next(null);
  }
}
