<ng-container *transloco="let t">
  <div class="dialog-header">{{ t('casino.dialog.chooseProvider') }}</div>

  <div class="dialog-body providers-dialog-body">
    <div class="providers-sort">
      <p class="providers-sort--title">{{ t('casino.sortBy') }}</p>

      <div class="providers-sort--buttons-group">
        <!-- TODO: when backend will be ready add 2 another buttons here -->
        <button appearance="tertiary" [size]="'m'" (click)="toggleOrder()" tuiButton>
          {{ t('casino.btn.alphabeticSort') }}

          <tui-svg
            class="arrow"
            [ngClass]="{ _up: alphabeticalOrder() === order.Desc }"
            [src]="'fidArrowDown'"
          ></tui-svg>
        </button>
      </div>
    </div>

    <fid-filter
      *ngIf="gameProviders$ | async as gameProviders; else loading"
      [template]="optionTemplate"
      [filterOptions]="gameProviders"
      [filterBy]="filterBy"
      [appearance]="'grid'"
    ></fid-filter>

    <ng-template #optionTemplate let-provider="option" let-isActive="isActive">
      <fid-filter-dialog-item
        [name]="provider.name"
        [alternativeText]="provider.icon.alternativeText"
        [url]="provider.queryParamName ? (provider.icon.url | cdnUrl) : provider.icon.url"
        [width]="provider.icon.width"
        [height]="provider.icon.height"
        [count]="provider.count"
        [isActive]="isActive"
        (click)="close()"
      ></fid-filter-dialog-item>
    </ng-template>
  </div>

  <ng-template #loading>
    <fid-loader></fid-loader>
  </ng-template>
</ng-container>
