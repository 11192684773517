/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface HealthControllerCheck$Params {
  'x-platform-name'?: any;
  'x-platform-version'?: any;
  'x-platform-timezone'?: any;
}

export function healthControllerCheck(http: HttpClient, rootUrl: string, params?: HealthControllerCheck$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'status'?: string;
'info'?: ({
[key: string]: {
'status': string;
[key: string]: any;
};
}) | null;
'error'?: ({
[key: string]: {
'status': string;
[key: string]: any;
};
}) | null;
'details'?: {
[key: string]: {
'status': string;
[key: string]: any;
};
};
}>> {
  const rb = new RequestBuilder(rootUrl, healthControllerCheck.PATH, 'get');
  if (params) {
    rb.header('x-platform-name', params['x-platform-name'], {});
    rb.header('x-platform-version', params['x-platform-version'], {});
    rb.header('x-platform-timezone', params['x-platform-timezone'], {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<{
      'status'?: string;
      'info'?: ({
      [key: string]: {
      'status': string;
      [key: string]: any;
      };
      }) | null;
      'error'?: ({
      [key: string]: {
      'status': string;
      [key: string]: any;
      };
      }) | null;
      'details'?: {
      [key: string]: {
      'status': string;
      [key: string]: any;
      };
      };
      }>;
    })
  );
}

healthControllerCheck.PATH = '/health';
