import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'includes',
  standalone: true,
})
export class IncludesPipe implements PipeTransform {
  public transform(value: string, keys: string[]): boolean {
    const result = [];
    for (let i = 0; i < keys.length; i++) {
      if (value.toLowerCase().includes(keys[i].toLowerCase())) {
        result.push(keys[i]);
      }
    }
    return !!result.length;
  }
}
