export enum WSEvents {
  TransactionSubscribe = 'transaction:subscribe', // all через uuid
  TransactionStatus = 'transaction:status',
  BalanceUpdate = 'balance:update',
  WalletTransactionUpdate = 'wallet:transaction:update',
  ProjectTransactionUpdate = 'project:transaction:update',
  ProjectBalanceUpdate = 'project:balance:update',
  UserVerificationUpdate = 'user:verification:update',
}

export enum WebSocketStatus {
  Done = 'DONE',
  Fail = 'FAIL',
  Init = 'INIT',
}
