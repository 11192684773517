import { inject, Injectable } from '@angular/core';
import { HiwListResponseDataItem } from 'core/capi/models/hiw-list-response-data-item';
import { HiwStepComponent } from 'core/capi/models/hiw-step-component';
import { ListMapper, Mapper } from 'core/base/model/mapper.model';
import { IconMapper } from 'core/data/common/icon/icon.mapper';
import { ImageMapper } from 'core/data/common/image/image.mapper';
import { HowItWorksItemType, HowItWorksModel } from 'core/data/domain/how-it-works.model';

@Injectable({
  providedIn: 'root',
})
export class HowItWorksMapper
  implements
    Mapper<HiwListResponseDataItem, HowItWorksModel>,
    ListMapper<HiwListResponseDataItem, HowItWorksModel>
{
  private imageMapper: ImageMapper = inject(ImageMapper);
  private iconMapper: IconMapper = inject(IconMapper);

  public mapFromList(params: HiwListResponseDataItem[]): HowItWorksModel[] {
    return params.map(dto => this.mapFrom(dto));
  }

  public mapFrom({ attributes }: HiwListResponseDataItem): HowItWorksModel {
    return {
      id: attributes?.slug || '',
      name: attributes?.name || '',
      type: attributes?.type as HowItWorksItemType,
      description: attributes?.description || '',
      image: this.iconMapper.mapFrom(attributes?.image),
      buttonIcon: Boolean(attributes?.buttonIcon),
      button: attributes?.button || '',
      steps:
        attributes?.steps?.map((step: HiwStepComponent) => ({
          image: this.imageMapper.mapFrom(step.image),
          description: step.description || '',
        })) || [],
    };
  }
}
