<form class="registration-form" [formGroup]="form">
  <button type="button" class="registration-form--steps" (click)="backStep()">
    <tui-svg [style.height.px]="32" [style.width.px]="32" [src]="'fidIconChevronLeft'"></tui-svg>
    {{ stepTitleKey[activeStep] }}
    <div class="registration-form--steps-counter">
      <span class="registration-form--current-step">{{ activeStep + 1 }}</span
      >/{{ totalSteps }}
    </div>
  </button>

  <fid-registration-form-details
    class="registration-form--step"
    [ngClass]="{ _active: activeStep === registrationSteps.Details }"
    [formGroupName]="registrationFormGroupName.DetailsFormGroup"
  ></fid-registration-form-details>
  <fid-registration-form-address
    class="registration-form--step"
    [ngClass]="{ _active: activeStep === registrationSteps.Address }"
    [formGroupName]="registrationFormGroupName.AddressFormGroup"
  ></fid-registration-form-address>
  <fid-registration-form-avatar
    class="registration-form--step"
    [ngClass]="{ _active: activeStep === registrationSteps.Avatar }"
    [formGroupName]="registrationFormGroupName.AvatarFormGroup"
  ></fid-registration-form-avatar>

  <div class="registration-form--footer">
    <button
      [type]="activeStep === registrationSteps.Avatar ? 'submit' : 'button'"
      [disabled]="
        (activeStep === registrationSteps.Details &&
          form.controls[registrationFormGroupName.DetailsFormGroup].status === 'INVALID') ||
        (activeStep === registrationSteps.Address &&
          form.controls[registrationFormGroupName.AddressFormGroup].status === 'INVALID') ||
        (activeStep === registrationSteps.Avatar &&
          form.controls[registrationFormGroupName.AvatarFormGroup].status === 'INVALID')
      "
      (click)="nextStep()"
      tuiButton
    >
      {{ activeStep === registrationSteps.Avatar ? 'Yeaah!' : 'Next' }}
    </button>
  </div>
</form>
