import { inject, Injectable } from '@angular/core';
import { CommonResponse } from 'core/capi/models/common-response';
import { ResponseMapper } from 'core/base/model/mapper.model';
import { ImageMapper } from 'core/data/common/image/image.mapper';
import { CommonModel } from 'core/data/domain/common.model';

@Injectable({
  providedIn: 'root',
})
export class CommonMapper implements ResponseMapper<CommonResponse, CommonModel> {
  private readonly imageMapper: ImageMapper = inject(ImageMapper);

  public mapFromResponse({ data }: CommonResponse): CommonModel {
    return {
      qrcode: this.imageMapper.mapFrom(data?.attributes?.qrcode),
      onelink: data?.attributes?.onelink,
    };
  }
}
