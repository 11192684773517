/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CasinoCategoryListResponse } from '../models/casino-category-list-response';
import { CasinoCategoryResponse } from '../models/casino-category-response';
import { getCasinoCategories } from '../fn/casino-category/get-casino-categories';
import { GetCasinoCategories$Params } from '../fn/casino-category/get-casino-categories';
import { getCasinoCategoriesId } from '../fn/casino-category/get-casino-categories-id';
import { GetCasinoCategoriesId$Params } from '../fn/casino-category/get-casino-categories-id';

@Injectable({ providedIn: 'root' })
export class CasinoCategoryService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCasinoCategories()` */
  static readonly GetCasinoCategoriesPath = '/casino-categories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCasinoCategories()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCasinoCategories$Response(params?: GetCasinoCategories$Params, context?: HttpContext): Observable<StrictHttpResponse<CasinoCategoryListResponse>> {
    return getCasinoCategories(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCasinoCategories$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCasinoCategories(params?: GetCasinoCategories$Params, context?: HttpContext): Observable<CasinoCategoryListResponse> {
    return this.getCasinoCategories$Response(params, context).pipe(
      map((r: StrictHttpResponse<CasinoCategoryListResponse>): CasinoCategoryListResponse => r.body)
    );
  }

  /** Path part for operation `getCasinoCategoriesId()` */
  static readonly GetCasinoCategoriesIdPath = '/casino-categories/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCasinoCategoriesId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCasinoCategoriesId$Response(params: GetCasinoCategoriesId$Params, context?: HttpContext): Observable<StrictHttpResponse<CasinoCategoryResponse>> {
    return getCasinoCategoriesId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCasinoCategoriesId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCasinoCategoriesId(params: GetCasinoCategoriesId$Params, context?: HttpContext): Observable<CasinoCategoryResponse> {
    return this.getCasinoCategoriesId$Response(params, context).pipe(
      map((r: StrictHttpResponse<CasinoCategoryResponse>): CasinoCategoryResponse => r.body)
    );
  }

}
