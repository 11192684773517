import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LoginFormComponent } from 'features/auth/components/login-form/login-form.component';
import { NgOptimizedImage } from '@angular/common';
import { TranslocoDirective } from '@ngneat/transloco';
import { RegistrationFormComponent } from 'features/auth/components/registration-form/registration-form.component';

@Component({
  selector: 'fid-registration-dialog',
  standalone: true,
  imports: [LoginFormComponent, NgOptimizedImage, TranslocoDirective, RegistrationFormComponent],
  templateUrl: './registration-dialog.component.html',
  styleUrl: '../styles/auth-dialog.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistrationDialogComponent {}
