import { inject, Injectable } from '@angular/core';
import { CasinoListResponse } from 'core/capi/models/casino-list-response';
import { CasinoResponseDataObject } from 'core/capi/models/casino-response-data-object';
import { OfferListResponseDataItem } from 'core/capi/models/offer-list-response-data-item';
import { ResponseMeta } from 'core/base/model/base-api.model';
import { ListMapper, Mapper, ResponseMapper } from 'core/base/model/mapper.model';
import { CasinoDetailsMapper } from 'core/data/common/casino-details/casino-details.mapper';
import { IconMapper } from 'core/data/common/icon/icon.mapper';
import { ImageMapper } from 'core/data/common/image/image.mapper';
import { LogoMapper } from 'core/data/common/logo/logo.mapper';
import { ScreenshotMapper } from 'core/data/common/screenshot/screenshot.mapper';
import { CasinoModel, CasinosResponseModel } from 'core/data/domain/casino.model';
import { HowItWorksMapper } from 'core/data/repository/how-it-works/how-it-works.mapper';

@Injectable({
  providedIn: 'root',
})
export class CasinoMapper
  implements
    Mapper<CasinoResponseDataObject, CasinoModel>,
    ListMapper<CasinoResponseDataObject, CasinoModel>,
    ResponseMapper<CasinoListResponse, CasinosResponseModel>
{
  private iconMapper: IconMapper = inject(IconMapper);
  private imageMapper: ImageMapper = inject(ImageMapper);
  private logoMapper: LogoMapper = inject(LogoMapper);
  private screenshotMapper: ScreenshotMapper = inject(ScreenshotMapper);
  private casinoDetailsMapper: CasinoDetailsMapper = inject(CasinoDetailsMapper);
  private howItWorksMapper: HowItWorksMapper = inject(HowItWorksMapper);

  public mapFromResponse({ meta, data }: CasinoListResponse): CasinosResponseModel {
    return {
      meta: (meta || {}) as ResponseMeta,
      data: this.mapFromList(data || []),
    };
  }

  public mapFromList(params: CasinoResponseDataObject[]): CasinoModel[] {
    return params.map((dto: CasinoResponseDataObject) => this.mapFrom(dto));
  }

  public mapFrom({ attributes }: CasinoResponseDataObject): CasinoModel {
    return {
      ...attributes,
      id: attributes?.slug || '',
      name: attributes?.name || '',
      domain: attributes?.domain,
      category: attributes?.casinoCategory?.data?.attributes?.name,
      icon: this.iconMapper.mapFrom(attributes?.icon),
      image: this.imageMapper.mapFrom(attributes?.image),
      animation: this.imageMapper.mapFrom(attributes?.animation),
      video: this.imageMapper.mapFrom(attributes?.video),
      logo: this.logoMapper.mapFrom(attributes?.logo),
      screenshots: this.screenshotMapper.mapFromList(attributes?.screenshots?.data || []),
      casinoDetails: this.casinoDetailsMapper.mapFromList(attributes?.casinoDetails || []),
      offers: attributes?.offers?.data?.map(
        ({ id: offerId, attributes: offerAttributes }: OfferListResponseDataItem) => ({
          id: offerId || '',
          name: offerAttributes?.name,
          slug: offerAttributes?.slug,
          offer: offerAttributes?.offer,
          image: this.imageMapper.mapFrom(offerAttributes?.image || {}),
          casino: {
            id: offerAttributes?.casino?.data?.attributes?.slug || '',
            name: offerAttributes?.casino?.data?.attributes?.name || '',
            image: this.imageMapper.mapFrom(offerAttributes?.casino?.data?.attributes?.image),
            icon: this.iconMapper.mapFrom(offerAttributes?.casino?.data?.attributes?.icon),
            category:
              offerAttributes?.casino?.data?.attributes?.casinoCategory?.data?.attributes?.name,
          },
        }),
      ),
      features: attributes?.features?.data?.map(feature => ({
        name: feature.attributes?.name,
        backgroundGradient: feature.attributes?.backgroundGradient,
        textGradient: feature.attributes?.textGradient,
        label: feature.attributes?.label,
        image: this.imageMapper.mapFrom(feature.attributes?.image),
        casino: {
          id: feature.attributes?.casino?.data?.attributes?.slug || '',
          name: feature.attributes?.casino?.data?.attributes?.name || '',
          domain: feature.attributes?.casino?.data?.attributes?.domain || '',
          image: this.imageMapper.mapFrom(feature.attributes?.casino?.data?.attributes?.image),
          icon: this.iconMapper.mapFrom(feature.attributes?.casino?.data?.attributes?.icon),
          category:
            feature.attributes?.casino?.data?.attributes?.casinoCategory?.data?.attributes?.name,
        },
      })),
      howItWorks: this.howItWorksMapper.mapFromList(attributes?.hiws?.data || []),
    };
  }
}
