/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ApiPaginatedDto } from '../../models/api-paginated-dto';
import { TransactionDto } from '../../models/transaction-dto';

export interface TransactionControllerHistory$Params {
  type?: 'DEPOSIT' | 'WITHDRAW' | 'PLATFORM_DEPOSIT' | 'PLATFORM_WITHDRAW' | 'IDENTIFY' | 'REWARD';
  status?: 'INIT' | 'PENDING' | 'CANCEL' | 'DONE' | 'FAIL' | 'PROCESSING';
  limit?: number;
  offset?: number;
  createdFrom?: string;
  createdTo?: string;
  'x-platform-name'?: any;
  'x-platform-version'?: any;
  'x-platform-timezone'?: any;
}

export function transactionControllerHistory(http: HttpClient, rootUrl: string, params?: TransactionControllerHistory$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiPaginatedDto & {
'data'?: Array<TransactionDto>;
}>> {
  const rb = new RequestBuilder(rootUrl, transactionControllerHistory.PATH, 'get');
  if (params) {
    rb.query('type', params.type, {});
    rb.query('status', params.status, {});
    rb.query('limit', params.limit, {});
    rb.query('offset', params.offset, {});
    rb.query('createdFrom', params.createdFrom, {});
    rb.query('createdTo', params.createdTo, {});
    rb.header('x-platform-name', params['x-platform-name'], {});
    rb.header('x-platform-version', params['x-platform-version'], {});
    rb.header('x-platform-timezone', params['x-platform-timezone'], {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ApiPaginatedDto & {
      'data'?: Array<TransactionDto>;
      }>;
    })
  );
}

transactionControllerHistory.PATH = '/api/transaction/history';
