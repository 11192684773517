import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
  signal,
  WritableSignal,
} from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { OpenDialogDirective } from 'core/features/dialog/directives/open-dialog.directive';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiButtonModule, TuiErrorModule, TuiLinkModule } from '@taiga-ui/core';
import { TuiCheckboxLabeledModule, TuiFieldErrorPipeModule } from '@taiga-ui/kit';
import { CasinoModel } from 'core/data/domain/casino.model';
import { map, Observable, take, tap } from 'rxjs';
import { PaymentMethodsDto } from 'core/fapi/models/payment-methods-dto';
import { BrandConnectData, TransactionType } from 'features/project/model/project.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ProjectPaymentDepositDto } from 'core/fapi/models/project-payment-deposit-dto';
import { UserProjectModel } from 'features/user/model/user.model';
import { PlayButtonDirective } from 'shared/directives/play-button.directive';
import { LoaderComponent } from 'shared/components/loader/loader.component';
import { CashierTabsComponent } from 'features/cashier/components/cashier-tabs/cashier-tabs.component';
import { CashierCalculatorComponent } from 'features/cashier/components/cashier-calculator/cashier-calculator.component';
import { LocalizationService } from 'core/features/localization/services/localization.service';
import { ProjectRegisterResponseDto } from 'core/fapi/models/project-register-response-dto';
import { generate } from 'core/base/utils/random-string-generator';
import { UserRepository } from 'core/data/repository/user/user.repository';
import { CashierService } from 'features/cashier/services/cashier.service';
import { InitialValues, RegConfig } from 'features/cashier/constants/cashier.constants';
import { GamesListGameDto } from 'core/fapi/models/games-list-game-dto';
import { AmpEventsName, AmplitudeService } from 'shared/services/amplitude.service';
import { SendAnalyticsDirective } from 'shared/directives/send-analytics.directive';

@Component({
  selector: 'fid-cashier-form',
  standalone: true,
  imports: [
    AsyncPipe,
    FormsModule,
    NgIf,
    OpenDialogDirective,
    TranslocoDirective,
    TuiButtonModule,
    TuiCheckboxLabeledModule,
    TuiErrorModule,
    TuiFieldErrorPipeModule,
    TuiLinkModule,
    ReactiveFormsModule,
    PlayButtonDirective,
    LoaderComponent,
    CashierTabsComponent,
    CashierCalculatorComponent,
    SendAnalyticsDirective,
  ],
  templateUrl: './cashier-form.component.html',
  styleUrl: './cashier-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CashierFormComponent implements OnInit, AfterViewInit {
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly user: UserRepository = inject(UserRepository);
  private readonly cashierService: CashierService = inject(CashierService);
  private readonly localization: LocalizationService = inject(LocalizationService);
  private readonly amplitude: AmplitudeService = inject(AmplitudeService);

  @Input({ required: true }) public casino!: CasinoModel & Partial<UserProjectModel>;
  @Input() public game?: GamesListGameDto;

  protected readonly currentLang = this.localization.getActiveLangCode();
  protected readonly form: FormGroup = this.createForm(InitialValues);
  protected ampEventsName = AmpEventsName;
  protected transactionType = TransactionType;
  protected paymentMethods$?: Observable<PaymentMethodsDto[]>;
  protected isPending: WritableSignal<boolean> = signal(false);
  protected activeTab: WritableSignal<TransactionType> = signal(TransactionType.Deposit);
  protected isCasinoConnected: WritableSignal<boolean> = signal(false);
  protected paymentType: WritableSignal<string> = signal('');

  private selectedPayment!: PaymentMethodsDto;

  public ngOnInit(): void {
    const isFunID = this.casino.name === 'FunID';

    this.paymentMethods$ = isFunID
      ? this.cashierService.platformPaymentsMethod({ type: this.transactionType.Deposit }).pipe(
          take(1),
          map((payments: PaymentMethodsDto[]) => {
            return isFunID ? payments.slice(1) : payments;
          }),
          takeUntilDestroyed(this.destroyRef),
        )
      : this.cashierService
          .paymentsMethod({ domain: this.casino.domain!, type: this.transactionType.Deposit })
          .pipe(
            take(1),
            map((payments: PaymentMethodsDto[]) => {
              return isFunID ? payments.slice(1) : payments;
            }),
            takeUntilDestroyed(this.destroyRef),
          );

    this.user.userConnectedProjects$
      .pipe(
        tap(projects => {
          if (isFunID) {
            this.isCasinoConnected.set(true);
          } else {
            this.isCasinoConnected.set(projects.some(project => project.name === this.casino.name));
          }

          this.amplitude.send(AmpEventsName.PaymentStart, {
            transactionType: this.activeTab(),
            brandDomain: isFunID ? 'FunID' : this.casino.domain!,
            isUserExist: this.isCasinoConnected(),
          });
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  public ngAfterViewInit(): void {
    this.paymentType.set(this.getPaymentType());
  }

  protected switchTab(tab: TransactionType): void {
    this.activeTab.set(tab);
  }

  protected setPayment(payment: PaymentMethodsDto): PaymentMethodsDto {
    return (this.selectedPayment = payment);
  }

  protected submitForm(): void {
    this.isPending.set(true);
    const formData = this.buildFormData(this.form);

    this.amplitude.send(AmpEventsName.PaymentPrimaryClick, {
      transactionType: this.activeTab(),
      brandDomain: formData.domain,
      isQrCodeScanned: false,
      isBrandConnection: this.isCasinoConnected(),
      paymentType: this.paymentType(),
      paymentMethod: formData.method,
      amount: formData.amount,
      currency: formData.currency,
    });

    if (this.activeTab() === TransactionType.Deposit) {
      if (this.isCasinoConnected()) {
        if (formData.domain) {
          this.deposit(formData);
        } else {
          this.platformDeposit(formData);
        }
      } else {
        this.registration(formData)
          .pipe(
            take(1),
            tap(() => {
              this.deposit(formData);
            }),
            takeUntilDestroyed(this.destroyRef),
          )
          .subscribe({
            error: error => {
              this.isPending.set(false);

              this.amplitude.send(AmpEventsName.PaymentStart, {
                transactionType: this.activeTab(),
                brandDomain: formData.domain,
                error: error.exception.message,
                isQrCodeScanned: false,
                isBrandConnection: '',
                paymentType: this.paymentType(),
                paymentMethod: '',
                amount: '',
                currency: '',
              });
            },
            next: () => {
              this.amplitude.send(AmpEventsName.PaymentStart, {
                transactionType: this.activeTab(),
                brandDomain: formData.domain,
                isQrCodeScanned: false,
                isBrandConnection: '',
                paymentType: this.paymentType(),
                paymentMethod: '',
                amount: '',
                currency: '',
              });
            },
          });
      }
    } else {
      if (formData.domain) {
        this.withdrawal(formData);
      } else {
        this.platformWithdrawal(formData);
      }
    }
  }

  private buildFormData(data: FormGroup): ProjectPaymentDepositDto {
    const formValue = {
      ...data.value,
      ...data.value.paymentCalculator.amountGroup,
      ...data.value.paymentCalculator.paymentGroup,
    };

    return {
      domain: this.casino?.domain || '',
      amount: formValue.amount,
      method: formValue.payment,
      currency: this.selectedPayment.currency as ProjectPaymentDepositDto['currency'],
    };
  }

  private createForm(initialValues?: BrandConnectData): FormGroup {
    return new FormGroup({
      paymentCalculator: new FormGroup({
        paymentGroup: new FormGroup({
          payment: new FormControl<string | null>(
            initialValues?.method || null,
            Validators.required,
          ),
        }),
        amountGroup: new FormGroup({
          amount: new FormControl<number | null>(
            initialValues?.amount || null,
            Validators.required,
          ),
        }),
      }),
      passData: new FormControl<boolean | null>(
        initialValues?.passData || null,
        Validators.requiredTrue,
      ),
      termsAndPolicy: new FormControl<boolean | null>(
        initialValues?.termsAndPolicy || null,
        Validators.requiredTrue,
      ),
    });
  }

  private withdrawal(formData: ProjectPaymentDepositDto): void {
    this.cashierService
      .withdraw(formData)
      .pipe(
        take(1),
        tap(() => this.isPending.set(false)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        error: error => {
          this.isPending.set(false);

          this.amplitude.send(AmpEventsName.PaymentError, {
            transactionType: this.activeTab(),
            brandDomain: formData.domain,
            isQrCodeScanned: false,
            isBrandConnection: true,
            paymentType: this.paymentType(),
            paymentMethod: formData.method,
            amount: formData.amount,
            currency: formData.currency,
            error: error.exception.message,
          });
        },
        next: () => {
          this.amplitude.send(AmpEventsName.PaymentSuccess, {
            transactionType: this.activeTab(),
            brandDomain: formData.domain,
            isQrCodeScanned: false,
            isBrandConnection: true,
            paymentType: this.paymentType(),
            paymentMethod: formData.method,
            amount: formData.amount,
            currency: formData.currency,
          });
        },
      });
  }

  private deposit(formData: ProjectPaymentDepositDto): void {
    this.cashierService
      .deposit(formData)
      .pipe(
        take(1),
        tap(() => this.isPending.set(false)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        error: error => {
          this.isPending.set(false);

          this.amplitude.send(AmpEventsName.PaymentError, {
            transactionType: this.activeTab(),
            brandDomain: formData.domain,
            isQrCodeScanned: false,
            isBrandConnection: true,
            paymentType: this.paymentType(),
            paymentMethod: formData.method,
            amount: formData.amount,
            currency: formData.currency,
            error: error.exception.message,
          });
        },
        next: () => {
          this.amplitude.send(AmpEventsName.PaymentSuccess, {
            transactionType: this.activeTab(),
            brandDomain: formData.domain,
            isQrCodeScanned: false,
            isBrandConnection: true,
            paymentType: this.paymentType(),
            paymentMethod: formData.method,
            amount: formData.amount,
            currency: formData.currency,
          });
        },
      });
  }

  private platformDeposit(formData: ProjectPaymentDepositDto): void {
    this.cashierService
      .platformDeposit(formData)
      .pipe(
        take(1),
        tap(() => this.isPending.set(false)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        error: error => {
          this.isPending.set(false);

          this.amplitude.send(AmpEventsName.PaymentError, {
            transactionType: this.activeTab(),
            brandDomain: formData.domain,
            isQrCodeScanned: false,
            isBrandConnection: true,
            paymentType: this.paymentType(),
            paymentMethod: formData.method,
            amount: formData.amount,
            currency: formData.currency,
            error: error.exception.message,
          });
        },
        next: () => {
          this.amplitude.send(AmpEventsName.PaymentSuccess, {
            transactionType: this.activeTab(),
            brandDomain: formData.domain,
            isQrCodeScanned: false,
            isBrandConnection: true,
            paymentType: this.paymentType(),
            paymentMethod: formData.method,
            amount: formData.amount,
            currency: formData.currency,
          });
        },
      });
  }

  private platformWithdrawal(formData: ProjectPaymentDepositDto): void {
    this.cashierService
      .platformWithdrawal(formData)
      .pipe(
        take(1),
        tap(() => this.isPending.set(false)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        error: error => {
          this.isPending.set(false);

          this.amplitude.send(AmpEventsName.PaymentError, {
            transactionType: this.activeTab(),
            brandDomain: formData.domain,
            isQrCodeScanned: false,
            isBrandConnection: true,
            paymentType: this.paymentType(),
            paymentMethod: formData.method,
            amount: formData.amount,
            currency: formData.currency,
            error: error.exception.message,
          });
        },
        next: () => {
          this.amplitude.send(AmpEventsName.PaymentSuccess, {
            transactionType: this.activeTab(),
            brandDomain: formData.domain,
            isQrCodeScanned: false,
            isBrandConnection: true,
            paymentType: this.paymentType(),
            paymentMethod: formData.method,
            amount: formData.amount,
            currency: formData.currency,
          });
        },
      });
  }

  private registration(formData: ProjectPaymentDepositDto): Observable<ProjectRegisterResponseDto> {
    const params = {
      domain: formData.domain,
      login: generate(RegConfig.LoginCharsLength, RegConfig.Prefix),
      password: generate(RegConfig.PasswordCharsLength),
    };

    return this.cashierService.register(params).pipe(take(1), takeUntilDestroyed(this.destroyRef));
  }

  private getPaymentType(): string {
    if (this.casino.name === 'FunID') {
      if (this.activeTab() === this.transactionType.Deposit) {
        return 'funIdDeposit';
      } else {
        return 'funIdWithdrawal';
      }
    } else {
      if (this.isCasinoConnected()) {
        if (this.activeTab() === this.transactionType.Deposit) {
          return 'connectedBrandDeposit';
        } else {
          return 'brandWithdrawal';
        }
      } else {
        return 'newBrandDeposit';
      }
    }
  }
}
