<ng-container *transloco="let t">
  <tui-primitive-textfield
    #input
    class="search-list-field"
    [(value)]="value"
    [tuiTextfieldLabelOutside]="tuiTextfieldLabelOutside"
    [tuiTextfieldIconLeft]="'fidSearchBlue'"
    [tuiTextfieldSize]="'m'"
    [tuiAutoFocus]="true"
    (keydown.arrowDown.prevent)="onArrowDown(list, $event)"
  ></tui-primitive-textfield>

  <tui-data-list
    #list
    [emptyContent]="t('global.text.nofound')"
    (keydown)="onKeyDown($event.key, input.nativeFocusableElement)"
  >
    <tui-opt-group *ngFor="let item of items; trackBy: 'name' | trackByProp">
      <button
        *ngIf="(item.name | includes: [value]) || (item.prefix | includes: [value])"
        type="button"
        class="search-list-item"
        [value]="item.prefix"
        tuiOption
      >
        <img
          class="search-list-item--flag"
          [alt]="item.name"
          [ngSrc]="item.code | uppercase | tuiFlag"
          width="24"
          height="24"
        />
        <span class="search-list-item--name">{{ item.name }}</span>
        <span class="search-list-item--phone">{{ item.prefix }}</span>
      </button>
    </tui-opt-group>
  </tui-data-list>
</ng-container>
