import { DestroyRef, inject, Injectable } from '@angular/core';
import { Subject, tap } from 'rxjs';
import { AlertStatus } from 'core/features/alerts/constants/alert.constants';
import { AlertService } from 'core/features/alerts/services/alert.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

interface StorageErrorException {
  code: number | string;
  message: string;
  i18n: string;
}

interface StorageError {
  error: string;
  exception: StorageErrorException;
}

interface ErrorResponse {
  error: StorageError;
  message?: string;
  name?: string;
  status?: number | string;
  url?: string;
}

@Injectable({ providedIn: 'root' })
export class ErrorsStorageService {
  private readonly alert: AlertService = inject(AlertService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  public errors$ = new Subject<ErrorResponse>();

  constructor() {
    this.errors$
      .pipe(
        tap(item => this.showErrorAlert(item.error)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  private showErrorAlert(data: StorageError): void {
    this.alert.show({
      status: AlertStatus.Error,
      title: data.exception.message,
    });
  }
}
