/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DiscoveryPageResponse } from '../models/discovery-page-response';
import { getDiscoveryPage } from '../fn/discovery-page/get-discovery-page';
import { GetDiscoveryPage$Params } from '../fn/discovery-page/get-discovery-page';

@Injectable({ providedIn: 'root' })
export class DiscoveryPageService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getDiscoveryPage()` */
  static readonly GetDiscoveryPagePath = '/discovery-page';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDiscoveryPage()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiscoveryPage$Response(params?: GetDiscoveryPage$Params, context?: HttpContext): Observable<StrictHttpResponse<DiscoveryPageResponse>> {
    return getDiscoveryPage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDiscoveryPage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiscoveryPage(params?: GetDiscoveryPage$Params, context?: HttpContext): Observable<DiscoveryPageResponse> {
    return this.getDiscoveryPage$Response(params, context).pipe(
      map((r: StrictHttpResponse<DiscoveryPageResponse>): DiscoveryPageResponse => r.body)
    );
  }

}
