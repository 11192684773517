import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  OnInit,
  signal,
  WritableSignal,
} from '@angular/core';
import { ProjectDialogHeaderComponent } from 'features/project/components/project-dialog-header/project-dialog-header.component';
import { NgIf } from '@angular/common';
import { TuiButtonModule } from '@taiga-ui/core';
import { DialogCloseService } from 'core/features/dialog/services/dialog-close.service';
import { BrandDialogData } from 'features/project/model/project.model';
import { tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CashierFormComponent } from 'features/cashier/components/cashier-form/cashier-form.component';
import { CashierStatusComponent } from 'features/cashier/components/cashier-status/cashier-status.component';
import { CashierService } from 'features/cashier/services/cashier.service';

@Component({
  selector: 'fid-cashier-dialog',
  standalone: true,
  imports: [
    ProjectDialogHeaderComponent,
    NgIf,
    TuiButtonModule,
    CashierFormComponent,
    CashierStatusComponent,
  ],
  templateUrl: './cashier-dialog.component.html',
  styleUrl: './cashier-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CashierDialogComponent implements OnInit {
  private readonly dialogClose: DialogCloseService = inject(DialogCloseService);
  private readonly cashierService: CashierService = inject(CashierService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  public dialogData: BrandDialogData | null = null;
  protected transactionInProcess: WritableSignal<boolean> = signal(false);

  public ngOnInit(): void {
    if (!this.dialogData) {
      this.dialogClose.close();
    }

    this.cashierService.transactionData$
      .pipe(
        tap(item => this.transactionInProcess.set(!!item)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  protected switchToCashier(event: boolean): void {
    this.transactionInProcess.set(event);
  }
}
