import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TuiButtonModule } from '@taiga-ui/core';
import { TransactionType } from 'features/project/model/project.model';

@Component({
  selector: 'fid-cashier-tabs',
  standalone: true,
  imports: [TuiButtonModule],
  templateUrl: './cashier-tabs.component.html',
  styleUrl: './cashier-tabs.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CashierTabsComponent {
  @Input() public activeTab!: TransactionType;

  @Output() protected switchTabEmit = new EventEmitter<TransactionType>();

  protected transactionType = TransactionType;

  protected switchTab(tab: TransactionType): void {
    this.switchTabEmit.emit(tab);
  }
}
