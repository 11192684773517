import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { FidQueryParam } from 'core/base/constants/query-params.constants';
import { CdnUrlPipe } from 'core/base/utils/cdn-url.pipe';
import { CasinoFilterOptionModel } from 'core/data/domain/casino.model';
import { CasinoRepository } from 'core/data/repository/casino/casino.repository';
import { DialogCloseService } from 'core/features/dialog/services/dialog-close.service';
import { FilterComponent } from 'core/features/filter/filter/filter.component';
import { Observable } from 'rxjs';
import { FilterDialogItemComponent } from 'shared/components/filter-dialog-item/filter-dialog-item.component';
import { LoaderComponent } from 'shared/components/loader/loader.component';
import { AmpEventsName } from 'shared/services/amplitude.service';
import { SendAnalyticsDirective } from 'shared/directives/send-analytics.directive';

@Component({
  selector: 'fid-casinos-dialog',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoDirective,
    FilterComponent,
    FilterDialogItemComponent,
    CdnUrlPipe,
    LoaderComponent,
    SendAnalyticsDirective,
  ],
  templateUrl: './casinos-dialog.component.html',
  styleUrl: './casinos-dialog.component.scss',
})
export class CasinosDialogComponent {
  private dialogClose: DialogCloseService = inject(DialogCloseService);
  private casinos: CasinoRepository = inject(CasinoRepository);

  protected casinos$: Observable<CasinoFilterOptionModel[]> = this.casinos.getCasinoFilterOptions();
  protected filterBy: typeof FidQueryParam.Casino = FidQueryParam.Casino;

  public close(): void {
    this.dialogClose.close();
  }

  protected readonly ampEventsName = AmpEventsName;
}
