<ng-container *transloco="let t">
  <form class="connect-form form" [formGroup]="form">
    <ng-container *ngIf="paymentMethods$ | async as payments; else loader">
      <fid-cashier-tabs
        *ngIf="isCasinoConnected()"
        [activeTab]="activeTab()"
        (switchTabEmit)="switchTab($event)"
      ></fid-cashier-tabs>

      <p class="connect-form--title form--title">
        {{ t('global.text.firstTopBalance', { project: casino.name }) }}
      </p>

      <fid-cashier-calculator
        [casino]="casino"
        [payments]="payments"
        [activeTab]="
          activeTab() === transactionType.Deposit
            ? transactionType.Deposit
            : transactionType.Withdrawal
        "
        [formGroupName]="'paymentCalculator'"
        (paymentChanged)="setPayment($event)"
      ></fid-cashier-calculator>

      <div
        *ngIf="!isCasinoConnected() && activeTab() === transactionType.Deposit"
        class="form--group _gap-s"
      >
        <div class="form--row">
          <div class="form--field">
            <tui-checkbox-labeled [formControlName]="'passData'" [size]="'l'">
              {{ t('global.text.passData') }}
            </tui-checkbox-labeled>
            <tui-error
              [formControlName]="'passData'"
              [error]="['required'] | tuiFieldError | async"
            />
          </div>
        </div>

        <div class="form--row">
          <div class="form--field">
            <tui-checkbox-labeled [formControlName]="'termsAndPolicy'" [size]="'l'">
              {{ t('global.text.accept') }}
              <a href="{{ currentLang }}/info/terms" target="_blank" tuiLink>
                {{ t('global.text.termsAndConditions') }}
              </a>
              {{ t('global.text.and') }}
              <a href="{{ currentLang }}/info/privacy-policy" target="_blank" tuiLink>
                {{ t('global.text.privacyPolicy') }}
              </a>
            </tui-checkbox-labeled>
            <tui-error [formControlName]="'termsAndPolicy'" [error]="[] | tuiFieldError | async" />
          </div>
        </div>
      </div>

      <div class="form--row">
        <button
          type="submit"
          class="connect-form--btn"
          [disabled]="form.invalid"
          [showLoader]="isPending()"
          [appearance]="activeTab() === transactionType.Deposit ? 'primary' : 'secondary'"
          (click)="submitForm()"
          tuiButton
        >
          <ng-container
            *ngIf="
              !isCasinoConnected() && activeTab() === transactionType.Deposit;
              else defaultCahierButtonText
            "
          >
            {{ t('global.btn.createAndPlay') }}
          </ng-container>
          <ng-template #defaultCahierButtonText>
            {{
              t(
                activeTab() === transactionType.Deposit
                  ? 'global.btn.deposit'
                  : 'global.btn.withdraw'
              )
            }}
          </ng-template>
        </button>
      </div>

      <div
        *ngIf="
          activeTab() === transactionType.Deposit && isCasinoConnected() && casino.name !== 'FunID'
        "
        class="form--row"
      >
        <button
          appearance="whiteblock"
          type="button"
          class="connect-form--btn"
          [playData]="{ casino, game }"
          [fidSendAnalytics]="[
            ampEventsName.PaymentSecondaryClick,
            {
              transactionType: activeTab(),
              brandDomain: casino.domain!,
              isBrandConnection: isCasinoConnected(),
              paymentType: paymentType(),
            },
          ]"
          tuiButton
          fidPlayButton
          size="s"
        >
          {{ t('global.btn.skipAndPlay') }}
        </button>
      </div>

      <div *ngIf="!isCasinoConnected()" class="form--row">
        <button
          appearance="whiteblock"
          type="button"
          class="connect-form--btn"
          [dialogConfig]="{ data: { casino } }"
          [fidSendAnalytics]="[
            ampEventsName.PaymentSecondaryClick,
            {
              transactionType: activeTab(),
              brandDomain: casino.domain!,
              isBrandConnection: isCasinoConnected(),
              paymentType: paymentType(),
            },
          ]"
          tuiButton
          fidOpenDialog="login-casino"
          size="s"
        >
          {{ t('global.btn.alreadyHaveAccount') }}
        </button>
      </div>
    </ng-container>

    <ng-template #loader>
      <fid-loader [height]="casino.name === 'FunID' ? '462' : '540'"></fid-loader>
    </ng-template>
  </form>
</ng-container>
