import { Injectable } from '@angular/core';

type QueryValue = object | string | number | null;
export type QueryObject = Record<string, QueryValue>;

@Injectable({
  providedIn: 'root',
})
export class FilterParserService {
  /**
   * @input:
   * {
   *   name: {
   *     $containsi: `${value}`,
   *   },
   *   casinoCategory: {
   *     name: {
   *       $eq: `${value}`,
   *     }
   *   },
   * },
   *
   * @output:
   * {
   *   'name][$containsi': `${value}`,
   *   'casinoCategory][name][$eq': `${value}`,
   * }
   * */
  public parse(queries: QueryObject): Record<string, string | number> {
    const result: Record<string, string | number> = {};

    for (const query in queries) {
      if (!(query in queries)) return result;

      const value: QueryValue = queries[query];

      if (this.isObject(value)) {
        const flatObject = this.parse(value);

        for (const key in flatObject) {
          if (key in flatObject && flatObject[key] !== null) {
            result[`${query}][${key}`] = flatObject[key];
          }
        }
      } else {
        result[query] = value as string | number;
      }
    }

    return result;
  }

  private isObject(value: QueryValue): value is QueryObject {
    return value !== null && typeof value === 'object';
  }
}
