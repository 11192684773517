import { isPlatformBrowser, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Input,
  PLATFORM_ID,
  signal,
  WritableSignal,
} from '@angular/core';
import { QRCodeModule } from 'angularx-qrcode';
import { TuiButtonModule } from '@taiga-ui/core';
import { filter } from 'rxjs/operators';
import { take, tap } from 'rxjs';
import { AuthQrService } from 'features/auth/services/auth-qr.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'fid-qr-image',
  standalone: true,
  imports: [NgIf, QRCodeModule, TuiButtonModule],
  templateUrl: './qr-image.component.html',
  styleUrl: './qr-image.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QrImageComponent {
  private readonly authQrService = inject(AuthQrService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  protected readonly platformId: object = inject(PLATFORM_ID);

  @Input() public size: number = 100;

  protected isBrowser = isPlatformBrowser(this.platformId);
  protected qrCodeUrl: WritableSignal<string> = signal('');

  constructor() {
    this.authQrService.instantInitData$
      .pipe(
        filter(item => item.url.length > 0),
        take(1),
        tap(data => {
          this.qrCodeUrl.set(data.url);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }
}
