<ng-container *transloco="let t">
  <ng-container *ngIf="transaction$ | async as transaction; else loader">
    <div class="dialog-header">
      <ng-container *ngIf="transaction.type === transactionType.Deposit">
        {{ t('transaction.type.deposit') }}
      </ng-container>
      <ng-container *ngIf="transaction.type === transactionType.Withdraw">
        {{ t('transaction.type.withdraw') }}
      </ng-container>
      <ng-container *ngIf="transaction.type === transactionType.PlatformDeposit">
        {{ t('transaction.type.platformDeposit') }}
      </ng-container>
      <ng-container *ngIf="transaction.type === transactionType.PlatformWithdraw">
        {{ t('transaction.type.platformWithdraw') }}
      </ng-container>
      <ng-container *ngIf="transaction.type === transactionType.Identify">
        {{ t('transaction.type.identify') }}
      </ng-container>
      <ng-container *ngIf="transaction.type === transactionType.Reward">
        {{ t('transaction.type.reward') }}
      </ng-container>
    </div>
    <div class="dialog-body transaction-dialog-body">
      <div class="transaction-summary">
        <div class="transaction-casino">
          <div class="transaction-media">
            <img
              [alt]="projectStaticData.icon.alternativeText"
              [ngSrc]="projectStaticData.icon.url || '' | cdnUrl"
              height="60"
              width="60"
            />
          </div>
          <div class="transaction-details">
            <span class="transaction-casino-name">
              {{ transaction.projectId ? t('transaction.filter.brand') : projectStaticData.name }}
            </span>
            <span class="transaction-date">
              {{ transaction.createdAt | date: 'short' }}
            </span>
          </div>
        </div>
        <span class="transaction-amount">
          {{ transaction.amount | currency: transaction.currency }}
        </span>
      </div>

      <div class="transaction-meta">
        <div class="transaction-plate">
          <div class="transaction-label">
            {{ t('transaction.status.label') }}
          </div>
          <div class="transaction-value">
            {{ t(transactionKeys[transactionStatus.Done]) }}
            <tui-svg
              *ngIf="statusIconsMap[transaction.status] as statusIcon"
              class="transaction-icon _{{ transaction.status | lowercase }} "
              [src]="statusIcon"
            ></tui-svg>
          </div>
        </div>

        <div
          *ngIf="
            transaction.payment &&
            transaction.status === transactionStatus.Done &&
            transaction.type === transactionType.Deposit
          "
          class="transaction-plate"
        >
          <div class="transaction-label">
            {{ t('transaction.card.label') }}
          </div>
          <div class="transaction-value">
            {{ transaction.payment.cardMask }}
          </div>
        </div>

        <div class="transaction-plate">
          <div class="transaction-label">
            {{ t('transaction.type.label') }}
          </div>
          <div class="transaction-value">
            {{ t(transactionKeys[transaction.type]) }}
            <tui-svg
              *ngIf="typeIconsMap[transaction.type] as typeIcon"
              class="transaction-icon"
              [src]="typeIcon"
            ></tui-svg>
          </div>
        </div>

        <div class="transaction-plate">
          <div class="transaction-label">
            {{ t('transaction.receipt.label') }}
          </div>
          <div class="transaction-value">
            {{ transaction.uuid }}
            <button
              type="button"
              class="transaction-btn"
              [fidClipboard]="transaction.uuid"
              [fidSendAnalytics]="[
                ampEventsName.WalletTransactionsShowMoreClick,
                {
                  copyText: transaction.uuid,
                  title: 'Receipt',
                },
              ]"
            >
              <tui-svg class="transaction-icon" src="fidCopy"></tui-svg>
            </button>
          </div>
        </div>

        <div class="transaction-plate">
          <div class="transaction-value">
            <button type="button" class="transaction-btn" fidOpenChat>
              {{ t('global.btn.getHelp') }}
              <tui-svg class="transaction-icon" src="fidChevronRight"></tui-svg>
            </button>
          </div>
        </div>

        <div *ngIf="transaction.status === transactionStatus.Pending" class="transaction-plate">
          <div class="transaction-value">
            {{ t('transaction.btn.cancel') }}
            <tui-svg class="transaction-icon" src="fidCancel"></tui-svg>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #loader>
    <fid-loader></fid-loader>
  </ng-template>
</ng-container>
