/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { paymentControllerActionProcessPayment } from '../fn/payment/payment-controller-action-process-payment';
import { paymentControllerActionProcessPayment_1 } from '../fn/payment/payment-controller-action-process-payment-1';
import { PaymentControllerActionProcessPayment_1$Params } from '../fn/payment/payment-controller-action-process-payment-1';
import { PaymentControllerActionProcessPayment$Params } from '../fn/payment/payment-controller-action-process-payment';
import { paymentControllerConfirmPayment } from '../fn/payment/payment-controller-confirm-payment';
import { PaymentControllerConfirmPayment$Params } from '../fn/payment/payment-controller-confirm-payment';
import { paymentControllerPreprocessPayment } from '../fn/payment/payment-controller-preprocess-payment';
import { PaymentControllerPreprocessPayment$Params } from '../fn/payment/payment-controller-preprocess-payment';
import { PreprocessPaymentResponse } from '../models/preprocess-payment-response';

@Injectable({ providedIn: 'root' })
export class PaymentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `paymentControllerActionProcessPayment()` */
  static readonly PaymentControllerActionProcessPaymentPath = '/api/payment/processPayment';

  /**
   * Confirm payment method
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentControllerActionProcessPayment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentControllerActionProcessPayment$Response(params: PaymentControllerActionProcessPayment$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return paymentControllerActionProcessPayment(this.http, this.rootUrl, params, context);
  }

  /**
   * Confirm payment method
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentControllerActionProcessPayment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentControllerActionProcessPayment(params: PaymentControllerActionProcessPayment$Params, context?: HttpContext): Observable<void> {
    return this.paymentControllerActionProcessPayment$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `paymentControllerActionProcessPayment_1()` */
  static readonly PaymentControllerActionProcessPayment_1Path = '/api/payment/process-payment';

  /**
   * Confirm payment method
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentControllerActionProcessPayment_1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentControllerActionProcessPayment_1$Response(params: PaymentControllerActionProcessPayment_1$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return paymentControllerActionProcessPayment_1(this.http, this.rootUrl, params, context);
  }

  /**
   * Confirm payment method
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentControllerActionProcessPayment_1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentControllerActionProcessPayment_1(params: PaymentControllerActionProcessPayment_1$Params, context?: HttpContext): Observable<void> {
    return this.paymentControllerActionProcessPayment_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `paymentControllerConfirmPayment()` */
  static readonly PaymentControllerConfirmPaymentPath = '/api/payment/confirm';

  /**
   * New confirm payment method, exception when user did not connected
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentControllerConfirmPayment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentControllerConfirmPayment$Response(params: PaymentControllerConfirmPayment$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return paymentControllerConfirmPayment(this.http, this.rootUrl, params, context);
  }

  /**
   * New confirm payment method, exception when user did not connected
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentControllerConfirmPayment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentControllerConfirmPayment(params: PaymentControllerConfirmPayment$Params, context?: HttpContext): Observable<void> {
    return this.paymentControllerConfirmPayment$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `paymentControllerPreprocessPayment()` */
  static readonly PaymentControllerPreprocessPaymentPath = '/api/payment/preprocessPayment';

  /**
   * Preconfirm payment method
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentControllerPreprocessPayment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentControllerPreprocessPayment$Response(params: PaymentControllerPreprocessPayment$Params, context?: HttpContext): Observable<StrictHttpResponse<PreprocessPaymentResponse>> {
    return paymentControllerPreprocessPayment(this.http, this.rootUrl, params, context);
  }

  /**
   * Preconfirm payment method
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentControllerPreprocessPayment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentControllerPreprocessPayment(params: PaymentControllerPreprocessPayment$Params, context?: HttpContext): Observable<PreprocessPaymentResponse> {
    return this.paymentControllerPreprocessPayment$Response(params, context).pipe(
      map((r: StrictHttpResponse<PreprocessPaymentResponse>): PreprocessPaymentResponse => r.body)
    );
  }

}
