import { Injectable } from '@angular/core';
import { ListMapper, Mapper } from 'core/base/model/mapper.model';
import { GameDetailsEntity } from 'core/data/common/game-details/game-details.entity';
import { GameDetailsModel } from 'core/data/common/game-details/game-details.model';

@Injectable({
  providedIn: 'root',
})
export class GameDetailsMapper
  implements
    Mapper<GameDetailsEntity, GameDetailsModel>,
    ListMapper<GameDetailsEntity, GameDetailsModel>
{
  public mapFrom(param: GameDetailsEntity): GameDetailsModel {
    return { ...param };
  }

  public mapFromList(params: GameDetailsEntity[]): GameDetailsModel[] {
    return params.map(this.mapFrom);
  }
}
