<form *transloco="let t" class="login-code-form form" [formGroup]="form">
  <button type="button" class="login-code-form--steps" (click)="prevStep()">
    <tui-svg [style.height.px]="32" [style.width.px]="32" [src]="'fidIconChevronLeft'"></tui-svg>
    {{ t('dialog.login.confirmPhone') }}
  </button>

  <div class="login-code-form--description">
    <p class="login-code-form--text">
      {{ t('dialog.login.form.sendSms') }}
    </p>

    <p *ngIf="formPhone" class="login-code-form--phone">{{ formPhone }}</p>

    <button type="button" class="login-code-form--btn" (click)="prevStep()" tuiLink>
      {{ t('global.btn.changePhone') }}
    </button>
  </div>

  <fid-input-otp
    [formControl]="form.controls.code"
    (inputsFilled)="sendLoginResponse($event)"
  ></fid-input-otp>

  <fid-counter
    #counter
    class="login-code-form--counter"
    [startDue]="counterStartDue"
    [endDue]="counterEndDue"
    (counterCompleted)="counterCompleted()"
  ></fid-counter>

  <button
    type="button"
    class="login-code-form--btn"
    [disabled]="!isCounterCompleted()"
    (click)="sendPhoneResponse()"
    tuiLink
  >
    {{ t('global.btn.resend') }}
  </button>
</form>
