import { ReverseMap } from 'core/base/utils/types';
import { ApiResponseDto } from 'core/fapi/models/api-response-dto';
import { IntersectionTransactionDtoAdditionalTransactionDto } from 'core/fapi/models/intersection-transaction-dto-additional-transaction-dto';
import { TransactionDto } from 'core/fapi/models/transaction-dto';

export const TransactionType = {
  Deposit: 'DEPOSIT',
  Withdraw: 'WITHDRAW',
  PlatformDeposit: 'PLATFORM_DEPOSIT',
  PlatformWithdraw: 'PLATFORM_WITHDRAW',
  Identify: 'IDENTIFY',
  Reward: 'REWARD',
};

export type TransactionType = ReverseMap<typeof TransactionType>;

export const TransactionStatus = {
  Init: 'INIT',
  Pending: 'PENDING',
  Canceled: 'CANCEL',
  Done: 'DONE',
  Fail: 'FAIL',
  Processing: 'PROCESSING',
} as const;

export type TransactionStatus = ReverseMap<typeof TransactionStatus>;

export interface TransactionModel {
  alias: string | null;
  amount: number;
  applicationCustomerId: string | null;
  callbackUrl: string | null;
  createdAt: string;
  currency: string;
  customerIp: string | null;
  failUrl: string | null;
  id: number;
  isManual: boolean;
  merchantExternalId: string | null;
  paymentData: string | null;
  platformCustomerId: string | null;
  projectId: number | null;
  requestedAmount: number;
  requestedCurrency: string;
  status: TransactionStatus;
  statusChangedAt: string | null;
  subProvider: string | null;
  successUrl: string | null;
  type: TransactionType;
  updatedAt: string | null;
  userId: number | null;
  uuid: string;
}

export interface TransactionHistoryResponseModel {
  data: TransactionModel[];
}

export interface TransactionInfoResponseModel {
  data: TransactionModel;
}

export type TransactionHistoryResponseEntity = ApiResponseDto & {
  data?: TransactionDto[];
};

export type TransactionInfoResponseEntity = ApiResponseDto & {
  data?: IntersectionTransactionDtoAdditionalTransactionDto;
};
