<ng-container *transloco="let t">
  <div class="dialog-header">{{ t('dialog.languages.title') }}</div>

  <div class="dialog-body">
    <ng-container *ngIf="availableLanguages$ | async as langList">
      <ng-container *ngIf="langCode$ | async as langCode">
        <ul class="language-list">
          <li
            *ngFor="let lang of langList; trackBy: 'code' | trackByProp"
            class="language-list--item"
          >
            <button
              appearance="whiteblock"
              type="button"
              class="language-list--btn"
              [ngClass]="{ _active: langCode === lang.code }"
              [pseudoActive]="langCode === lang.code"
              [fidSendAnalytics]="[
                ampEventsName.LanguageClick,
                {
                  source: 'checkUrl',
                  language: lang.code,
                },
              ]"
              (click)="select(lang.code)"
              tuiButton
              size="m"
            >
              <span class="language-list--text">
                <img
                  class="language-list--flag"
                  [alt]="lang.code"
                  [ngSrc]="lang.code | uppercase | tuiFlag"
                  width="40"
                  height="40"
                />
                {{ lang.name }}
              </span>
            </button>
          </li>
        </ul>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
