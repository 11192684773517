/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { userControllerConnectProject } from '../fn/user/user-controller-connect-project';
import { UserControllerConnectProject$Params } from '../fn/user/user-controller-connect-project';
import { userControllerDisconnectProject } from '../fn/user/user-controller-disconnect-project';
import { UserControllerDisconnectProject$Params } from '../fn/user/user-controller-disconnect-project';
import { userControllerProjectList } from '../fn/user/user-controller-project-list';
import { UserControllerProjectList$Params } from '../fn/user/user-controller-project-list';
import { userControllerUpdateUser } from '../fn/user/user-controller-update-user';
import { UserControllerUpdateUser$Params } from '../fn/user/user-controller-update-user';
import { UserProjectResponseDto } from '../models/user-project-response-dto';

@Injectable({ providedIn: 'root' })
export class UserService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `userControllerConnectProject()` */
  static readonly UserControllerConnectProjectPath = '/api/user/connect';

  /**
   * Establishes a connection between an authorized user and a project
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerConnectProject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerConnectProject$Response(params: UserControllerConnectProject$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return userControllerConnectProject(this.http, this.rootUrl, params, context);
  }

  /**
   * Establishes a connection between an authorized user and a project
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerConnectProject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerConnectProject(params: UserControllerConnectProject$Params, context?: HttpContext): Observable<void> {
    return this.userControllerConnectProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `userControllerDisconnectProject()` */
  static readonly UserControllerDisconnectProjectPath = '/api/user/disconnect';

  /**
   * Terminates the connection between an authorized user and a project
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerDisconnectProject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerDisconnectProject$Response(params: UserControllerDisconnectProject$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return userControllerDisconnectProject(this.http, this.rootUrl, params, context);
  }

  /**
   * Terminates the connection between an authorized user and a project
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerDisconnectProject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerDisconnectProject(params: UserControllerDisconnectProject$Params, context?: HttpContext): Observable<void> {
    return this.userControllerDisconnectProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `userControllerProjectList()` */
  static readonly UserControllerProjectListPath = '/api/user/project';

  /**
   * List of connected projects
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerProjectList()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerProjectList$Response(params?: UserControllerProjectList$Params, context?: HttpContext): Observable<StrictHttpResponse<UserProjectResponseDto>> {
    return userControllerProjectList(this.http, this.rootUrl, params, context);
  }

  /**
   * List of connected projects
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerProjectList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerProjectList(params?: UserControllerProjectList$Params, context?: HttpContext): Observable<UserProjectResponseDto> {
    return this.userControllerProjectList$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserProjectResponseDto>): UserProjectResponseDto => r.body)
    );
  }

  /** Path part for operation `userControllerUpdateUser()` */
  static readonly UserControllerUpdateUserPath = '/api/user/profile';

  /**
   * Updates user's profile with new data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerUpdateUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerUpdateUser$Response(params: UserControllerUpdateUser$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return userControllerUpdateUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates user's profile with new data
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerUpdateUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerUpdateUser(params: UserControllerUpdateUser$Params, context?: HttpContext): Observable<void> {
    return this.userControllerUpdateUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
