import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TuiDialogOptions } from '@taiga-ui/core';

@Injectable({
  providedIn: 'root',
})
export class DialogOpenService {
  private router: Router = inject(Router);

  public open(
    dialogName: string,
    config?: Partial<TuiDialogOptions<Record<string, unknown>>>,
  ): void {
    this.router.navigate(['', { outlets: { dialog: [':', dialogName] } }], {
      queryParamsHandling: 'preserve',
      state: config,
    });
  }
}
