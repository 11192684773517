import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiButtonModule, TuiFlagPipeModule, TuiSvgModule } from '@taiga-ui/core';
import { TrackByPropPipe } from 'core/base/utils/track-by-prop.pipe';
import { LocalizationService } from 'core/features/localization/services/localization.service';
import { AmpEventsName } from 'shared/services/amplitude.service';
import { SendAnalyticsDirective } from 'shared/directives/send-analytics.directive';

@Component({
  selector: 'fid-language-selection-dialog',
  standalone: true,
  imports: [
    CommonModule,
    TuiButtonModule,
    NgOptimizedImage,
    TuiFlagPipeModule,
    TuiSvgModule,
    TranslocoDirective,
    TrackByPropPipe,
    SendAnalyticsDirective,
  ],
  templateUrl: './language-selection-dialog.component.html',
  styleUrl: './language-selection-dialog.component.scss',
})
export class LanguageSelectionDialogComponent {
  private localizationServise = inject(LocalizationService);
  private router = inject(Router);
  protected langCode$ = this.localizationServise.langCode$;
  protected availableLanguages$ = this.localizationServise.availableLanguages$;

  public select(lang: string): void {
    this.localizationServise.setLanguage(lang).then(() => {
      this.router.navigate(['', { outlets: { dialog: null } }], {
        queryParamsHandling: 'preserve',
      });
    });
  }

  protected readonly ampEventsName = AmpEventsName;
}
