import { inject, Injectable } from '@angular/core';
import { CasinoResponseDataObject } from 'core/capi/models/casino-response-data-object';
import { FeatureListResponse } from 'core/capi/models/feature-list-response';
import { FeatureListResponseDataItem } from 'core/capi/models/feature-list-response-data-item';
import { ListMapper, Mapper, ResponseMapper } from 'core/base/model/mapper.model';
import { ImageMapper } from 'core/data/common/image/image.mapper';
import { SpecialFeatureModel } from 'core/data/domain/special-feature.model';
import { CasinoMapper } from 'core/data/repository/casino/casino.mapper';

@Injectable({
  providedIn: 'root',
})
export class SpecialFeatureMapper
  implements
    Mapper<FeatureListResponseDataItem, SpecialFeatureModel>,
    ListMapper<FeatureListResponseDataItem, SpecialFeatureModel>,
    ResponseMapper<FeatureListResponse, SpecialFeatureModel[]>
{
  private readonly casinoMapper: CasinoMapper = inject(CasinoMapper);
  private readonly imageMapper: ImageMapper = inject(ImageMapper);

  public mapFrom({ attributes }: FeatureListResponseDataItem): SpecialFeatureModel {
    return {
      name: attributes?.name,
      label: attributes?.label,
      textGradient: attributes?.textGradient,
      backgroundGradient: attributes?.backgroundGradient,
      image: this.imageMapper.mapFrom(attributes?.image),
      casino: this.casinoMapper.mapFrom(
        (attributes?.casino?.data as CasinoResponseDataObject) || {},
      ),
    };
  }

  public mapFromList(params: FeatureListResponseDataItem[]): SpecialFeatureModel[] {
    return params.map((item: FeatureListResponseDataItem) => this.mapFrom(item));
  }

  public mapFromResponse({ data }: FeatureListResponse): SpecialFeatureModel[] {
    return this.mapFromList(data || []);
  }
}
