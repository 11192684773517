import { inject, Injectable } from '@angular/core';
import { io } from 'socket.io-client';
import { distinctUntilChanged, map, Observable, Subject, tap } from 'rxjs';
import { environment } from '@environments/environment';
import { WSEvents } from 'core/base/constants/websocket.constants';
import { AuthStorageService } from 'features/auth/services/auth-storage.service';
import { ProjectBalanceDto } from 'core/fapi/models/project-balance-dto';
import { AppUserService } from 'features/user/services/app-user.service';
import { UserRepository } from 'core/data/repository/user/user.repository';
import { WalletRepository } from 'core/data/repository/wallet/wallet.repository';

@Injectable({ providedIn: 'root' })
export class SocketIoService {
  private readonly storage: AuthStorageService = inject(AuthStorageService);
  private readonly appUserService: AppUserService = inject(AppUserService);
  private readonly userRepository: UserRepository = inject(UserRepository);
  private readonly walletRepository: WalletRepository = inject(WalletRepository);

  private io = io(environment.fApiDomain, {
    transports: ['polling', 'websocket'],
    autoConnect: false,
    rememberUpgrade: true,
    extraHeaders: {
      Authorization: `Bearer ${this.storage.getAuthToken()}`,
    },
  });

  public socketResponse$ = new Subject();

  public connect = () => this.io.connect();

  public emit(name: string, data: unknown): void {
    this.io.emit(name, data);
  }

  public listen(data: WSEvents | WSEvents[]): void {
    setTimeout(() => {
      if (Array.isArray(data)) {
        data.forEach(name => {
          this.io.on(name, (message: unknown) => {
            this.socketResponse$.next(message);
          });
        });
      } else {
        this.io.on(data, (message: unknown) => {
          this.socketResponse$.next(message);
        });
      }
    });
  }

  public getSocketAppBalance<T extends ProjectBalanceDto>(): Observable<T> {
    this.listen([WSEvents.BalanceUpdate, WSEvents.ProjectBalanceUpdate]);

    return this.socketResponse$.pipe(
      map((response: unknown) => response as T),
      distinctUntilChanged((prev, cur) => prev.balance === cur.balance),
      tap(item => {
        if (item.domain) {
          this.userRepository.refreshUserConnectedProjects();
          this.walletRepository.refreshConnectedWallets();
        }
        if (!item.domain) {
          this.appUserService.refreshUser();
        }
      }),
    );
  }
}
