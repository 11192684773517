import { OnInit, ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  TuiButtonModule,
  TuiErrorModule,
  TuiGroupModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import {
  TuiFieldErrorPipeModule,
  TuiInputDateModule,
  TuiInputModule,
  TuiRadioBlockModule,
} from '@taiga-ui/kit';
import { FormGroup, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { RegistrationFormGroupName } from 'features/auth/model/auth.model';
import { AsyncPipe } from '@angular/common';
import { TUI_DATE_FORMAT, TUI_DATE_SEPARATOR } from '@taiga-ui/cdk';
import { RegistrationTuiDayFormat } from 'features/auth/constants/registration-form.constants';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiDay } from '@taiga-ui/cdk';

@Component({
  selector: 'fid-registration-form-details',
  standalone: true,
  imports: [
    TuiButtonModule,
    TuiInputModule,
    ReactiveFormsModule,
    TuiTextfieldControllerModule,
    TuiErrorModule,
    TuiFieldErrorPipeModule,
    AsyncPipe,
    TuiInputDateModule,
    TuiGroupModule,
    TuiRadioBlockModule,
    TranslocoDirective,
  ],
  templateUrl: './registration-form-details.component.html',
  styleUrl: './registration-form-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TUI_DATE_FORMAT,
      useValue: RegistrationTuiDayFormat.dayFormat,
    },
    {
      provide: TUI_DATE_SEPARATOR,
      useValue: RegistrationTuiDayFormat.separator,
    },
  ],
})
export class RegistrationFormDetailsComponent implements OnInit {
  private rootFormGroup = inject(FormGroupDirective);

  protected formGroup!: FormGroup;
  protected maxBirthdayDate = this.getMaxBirthdayDate();

  private getMaxBirthdayDate(): TuiDay {
    const date = new Date();
    return new TuiDay(date.getUTCFullYear() - 18, date.getUTCMonth(), date.getUTCDate());
  }

  public ngOnInit(): void {
    this.formGroup = this.rootFormGroup.control.get(
      RegistrationFormGroupName.DetailsFormGroup,
    ) as FormGroup;
  }
}
