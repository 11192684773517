import { NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import {
  TuiDataListComponent,
  TuiDataListModule,
  TuiFlagPipeModule,
  tuiIsEditingKey,
  TuiPrimitiveTextfieldModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import { TrackByPropPipe } from 'core/base/utils/track-by-prop.pipe';
import { AllowedCountryModel } from 'core/data/domain/allowed-country.model';
import {
  TUI_DEFAULT_MATCHER,
  TuiAutoFocusModule,
  TuiFilterPipeModule,
  TuiKeysPipeModule,
} from '@taiga-ui/cdk';
import { IncludesPipe } from 'shared/pipes/includes.pipe';

@Component({
  selector: 'fid-country-search-list',
  standalone: true,
  imports: [
    TuiDataListModule,
    TuiPrimitiveTextfieldModule,
    TuiTextfieldControllerModule,
    NgIf,
    NgForOf,
    TuiFlagPipeModule,
    TranslocoDirective,
    TrackByPropPipe,
    TuiFilterPipeModule,
    TuiKeysPipeModule,
    IncludesPipe,
    TuiAutoFocusModule,
  ],
  templateUrl: './country-search-list.component.html',
  styleUrl: './country-search-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountrySearchListComponent {
  @Input() public items: AllowedCountryModel[] = [];
  @Input() public tuiTextfieldLabelOutside: boolean = true;

  protected value = '';
  protected readonly filter = TUI_DEFAULT_MATCHER;

  protected onArrowDown<T>(list: TuiDataListComponent<T>, event: Event): void {
    list.onFocus(event, true);
  }

  protected onKeyDown(key: string, element: HTMLElement | null): void {
    if (element && tuiIsEditingKey(key)) {
      element.focus({ preventScroll: true });
    }
  }
}
