/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getSidebar } from '../fn/sidebar/get-sidebar';
import { GetSidebar$Params } from '../fn/sidebar/get-sidebar';
import { SidebarResponse } from '../models/sidebar-response';

@Injectable({ providedIn: 'root' })
export class SidebarService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getSidebar()` */
  static readonly GetSidebarPath = '/sidebar';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSidebar()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSidebar$Response(params?: GetSidebar$Params, context?: HttpContext): Observable<StrictHttpResponse<SidebarResponse>> {
    return getSidebar(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSidebar$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSidebar(params?: GetSidebar$Params, context?: HttpContext): Observable<SidebarResponse> {
    return this.getSidebar$Response(params, context).pipe(
      map((r: StrictHttpResponse<SidebarResponse>): SidebarResponse => r.body)
    );
  }

}
