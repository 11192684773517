/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { swiperControllerGetFeedList } from '../fn/swiper/swiper-controller-get-feed-list';
import { SwiperControllerGetFeedList$Params } from '../fn/swiper/swiper-controller-get-feed-list';
import { swiperControllerGetGamesList } from '../fn/swiper/swiper-controller-get-games-list';
import { SwiperControllerGetGamesList$Params } from '../fn/swiper/swiper-controller-get-games-list';
import { SwiperFeedDto } from '../models/swiper-feed-dto';

@Injectable({ providedIn: 'root' })
export class SwiperService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `swiperControllerGetGamesList()` */
  static readonly SwiperControllerGetGamesListPath = '/api/swiper/games/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `swiperControllerGetGamesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  swiperControllerGetGamesList$Response(params?: SwiperControllerGetGamesList$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return swiperControllerGetGamesList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `swiperControllerGetGamesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  swiperControllerGetGamesList(params?: SwiperControllerGetGamesList$Params, context?: HttpContext): Observable<void> {
    return this.swiperControllerGetGamesList$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `swiperControllerGetFeedList()` */
  static readonly SwiperControllerGetFeedListPath = '/api/swiper/feed/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `swiperControllerGetFeedList()` instead.
   *
   * This method doesn't expect any request body.
   */
  swiperControllerGetFeedList$Response(params?: SwiperControllerGetFeedList$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SwiperFeedDto>>> {
    return swiperControllerGetFeedList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `swiperControllerGetFeedList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  swiperControllerGetFeedList(params?: SwiperControllerGetFeedList$Params, context?: HttpContext): Observable<Array<SwiperFeedDto>> {
    return this.swiperControllerGetFeedList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SwiperFeedDto>>): Array<SwiperFeedDto> => r.body)
    );
  }

}
