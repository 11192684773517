/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { countryCurrencyControllerGet } from '../fn/operations/country-currency-controller-get';
import { CountryCurrencyControllerGet$Params } from '../fn/operations/country-currency-controller-get';
import { CountryCurrencyResponseDto } from '../models/country-currency-response-dto';
import { InitWidgetResponse } from '../models/init-widget-response';
import { onfidoControllerInit } from '../fn/operations/onfido-controller-init';
import { OnfidoControllerInit$Params } from '../fn/operations/onfido-controller-init';
import { onfidoControllerStartCheck } from '../fn/operations/onfido-controller-start-check';
import { OnfidoControllerStartCheck$Params } from '../fn/operations/onfido-controller-start-check';
import { onfidoControllerTrigger } from '../fn/operations/onfido-controller-trigger';
import { OnfidoControllerTrigger$Params } from '../fn/operations/onfido-controller-trigger';
import { onfidoControllerWidget } from '../fn/operations/onfido-controller-widget';
import { OnfidoControllerWidget$Params } from '../fn/operations/onfido-controller-widget';
import { StartCheckResponse } from '../models/start-check-response';
import { TriggerResponse } from '../models/trigger-response';

@Injectable({ providedIn: 'root' })
export class Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `onfidoControllerInit()` */
  static readonly OnfidoControllerInitPath = '/api/onfido/initWidget';

  /**
   * Init onfido widget
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `onfidoControllerInit()` instead.
   *
   * This method doesn't expect any request body.
   */
  onfidoControllerInit$Response(params?: OnfidoControllerInit$Params, context?: HttpContext): Observable<StrictHttpResponse<InitWidgetResponse>> {
    return onfidoControllerInit(this.http, this.rootUrl, params, context);
  }

  /**
   * Init onfido widget
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `onfidoControllerInit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  onfidoControllerInit(params?: OnfidoControllerInit$Params, context?: HttpContext): Observable<InitWidgetResponse> {
    return this.onfidoControllerInit$Response(params, context).pipe(
      map((r: StrictHttpResponse<InitWidgetResponse>): InitWidgetResponse => r.body)
    );
  }

  /** Path part for operation `onfidoControllerTrigger()` */
  static readonly OnfidoControllerTriggerPath = '/api/onfido/trigger';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `onfidoControllerTrigger()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  onfidoControllerTrigger$Response(params: OnfidoControllerTrigger$Params, context?: HttpContext): Observable<StrictHttpResponse<TriggerResponse>> {
    return onfidoControllerTrigger(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `onfidoControllerTrigger$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  onfidoControllerTrigger(params: OnfidoControllerTrigger$Params, context?: HttpContext): Observable<TriggerResponse> {
    return this.onfidoControllerTrigger$Response(params, context).pipe(
      map((r: StrictHttpResponse<TriggerResponse>): TriggerResponse => r.body)
    );
  }

  /** Path part for operation `onfidoControllerWidget()` */
  static readonly OnfidoControllerWidgetPath = '/api/onfido/widget';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `onfidoControllerWidget()` instead.
   *
   * This method doesn't expect any request body.
   */
  onfidoControllerWidget$Response(params: OnfidoControllerWidget$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return onfidoControllerWidget(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `onfidoControllerWidget$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  onfidoControllerWidget(params: OnfidoControllerWidget$Params, context?: HttpContext): Observable<void> {
    return this.onfidoControllerWidget$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `onfidoControllerStartCheck()` */
  static readonly OnfidoControllerStartCheckPath = '/api/onfido/startCheck';

  /**
   * Start onfido check
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `onfidoControllerStartCheck()` instead.
   *
   * This method doesn't expect any request body.
   */
  onfidoControllerStartCheck$Response(params?: OnfidoControllerStartCheck$Params, context?: HttpContext): Observable<StrictHttpResponse<StartCheckResponse>> {
    return onfidoControllerStartCheck(this.http, this.rootUrl, params, context);
  }

  /**
   * Start onfido check
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `onfidoControllerStartCheck$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  onfidoControllerStartCheck(params?: OnfidoControllerStartCheck$Params, context?: HttpContext): Observable<StartCheckResponse> {
    return this.onfidoControllerStartCheck$Response(params, context).pipe(
      map((r: StrictHttpResponse<StartCheckResponse>): StartCheckResponse => r.body)
    );
  }

  /** Path part for operation `countryCurrencyControllerGet()` */
  static readonly CountryCurrencyControllerGetPath = '/api/countryCurrency';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countryCurrencyControllerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  countryCurrencyControllerGet$Response(params?: CountryCurrencyControllerGet$Params, context?: HttpContext): Observable<StrictHttpResponse<CountryCurrencyResponseDto>> {
    return countryCurrencyControllerGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `countryCurrencyControllerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countryCurrencyControllerGet(params?: CountryCurrencyControllerGet$Params, context?: HttpContext): Observable<CountryCurrencyResponseDto> {
    return this.countryCurrencyControllerGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<CountryCurrencyResponseDto>): CountryCurrencyResponseDto => r.body)
    );
  }

}
