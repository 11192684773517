import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ApiConfiguration } from 'core/capi/api-configuration';
import { GameProviderListResponse } from 'core/capi/models/game-provider-list-response';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GameProvidersApiService {
  private http: HttpClient = inject(HttpClient);
  private apiConfiguration: ApiConfiguration = inject(ApiConfiguration);

  public getGameProviders(
    params?: Record<string, string | number | boolean>,
  ): Observable<GameProviderListResponse> {
    // TODO: this is temporary service which exists only because of incorrect BE working
    return this.http.get<GameProviderListResponse>(
      `${this.apiConfiguration.rootUrl}/game-providers`,
      {
        params,
      },
    );
  }
}
