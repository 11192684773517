import { Injectable } from '@angular/core';
import { ListMapper, Mapper } from 'core/base/model/mapper.model';
import { CasinoDetailsEntity } from 'core/data/common/casino-details/casino-details.entity';
import { CasinoDetailsModel } from 'core/data/common/casino-details/casino-details.model';

@Injectable({
  providedIn: 'root',
})
export class CasinoDetailsMapper
  implements
    Mapper<CasinoDetailsEntity, CasinoDetailsModel>,
    ListMapper<CasinoDetailsEntity, CasinoDetailsModel>
{
  public mapFrom(param: CasinoDetailsEntity): CasinoDetailsModel {
    return { ...param };
  }

  public mapFromList(params: CasinoDetailsEntity[]): CasinoDetailsModel[] {
    return params.map(this.mapFrom);
  }
}
