import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[fidClipboard]',
  standalone: true,
})
export class ClipboardDirective {
  @Input() public fidClipboard?: string;

  @HostListener('click', ['$event']) public copy(): void {
    if (this.isString(this.fidClipboard)) {
      navigator.clipboard.writeText(this.fidClipboard as string).catch(() => {
        console.error('Something went wrong!');
      });
    }
  }

  private isString(value: unknown): boolean {
    return String(value) === value;
  }
}
