<a
  *ngIf="casino.id !== 'funid'; else placeholderLink"
  class="casino-card-link"
  [attr.aria-label]="casino.name"
  [fidRouterLink]="['casinos', casino.id]"
></a>
<ng-template #placeholderLink>
  <a class="casino-card-link" [attr.aria-label]="casino.name" [fidRouterLink]="['discovery']"></a>
</ng-template>

<img
  class="casino-card-image"
  [ngSrc]="casino.icon.url | cdnUrl"
  [alt]="casino.icon.alternativeText || ''"
  [width]="imageSizeMap[size]"
  [height]="imageSizeMap[size]"
/>

<div class="casino-card-details" [ngSwitch]="variant">
  <ng-container *ngSwitchCase="'base-logged-flat'">
    <p class="casino-card-details--text _{{ size }}">{{ casino.name }}</p>
    <p class="casino-card-details--title _{{ size }}">
      {{ casino.balance || '0' | currency: casino.currency }}
    </p>
  </ng-container>
  <ng-container *ngSwitchCase="'base-logged'">
    <p class="casino-card-details--text _{{ size }}">{{ casino.name }}</p>
    <p class="casino-card-details--title _{{ size }}">
      {{ casino.balance || '0' | currency: casino.currency }}
    </p>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <fid-badge
      *ngIf="labelKey && size !== 'large'"
      [title]="labelKey"
      variant="neutral"
    ></fid-badge>
    <p class="casino-card-details--title _{{ size }}">{{ casino.name }}</p>
    <p *ngIf="variant !== 'flat-base'" class="casino-card-details--text _{{ size }}">
      {{ casino.offer }}
    </p>
  </ng-container>
</div>

<div class="casino-card-extra">
  <ng-content></ng-content>
</div>
