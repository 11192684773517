<ng-container *transloco="let t">
  <ng-container *ngIf="selectedOption$ | async as selectedOption">
    <ng-container *ngFor="let filterOption of filterOptions">
      <button *ngIf="template; else defaultOption" (click)="select(filterOption)">
        <ng-container
          *ngTemplateOutlet="
            template;
            context: {
              option: filterOption,
              isActive: selectedOption.queryParamName === filterOption.queryParamName,
            }
          "
        ></ng-container>
      </button>
      <ng-template #defaultOption>
        <ng-container
          *ngTemplateOutlet="
            defaultOptionTemplate;
            context: {
              option: filterOption,
              isActive: selectedOption.queryParamName === filterOption.queryParamName,
            }
          "
        ></ng-container>
      </ng-template>
    </ng-container>
  </ng-container>

  <ng-template #defaultOptionTemplate let-option="option" let-isActive="isActive">
    <button
      appearance="whiteblock"
      #tab
      type="button"
      [attr.aria-label]="option.name"
      [pseudoActive]="isActive"
      [shape]="'rounded'"
      [class._active]="isActive"
      [size]="'m'"
      [fidSendAnalytics]="[
        ampEventsName.ChangeCategoryClick,
        {
          source: 'checkUrl',
          category: option.name,
        },
      ]"
      (click)="select(option)"
      tuiButton
    >
      {{ option.name }}
    </button>
  </ng-template>
</ng-container>
