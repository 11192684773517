<ng-container *transloco="let t">
  <div class="auth-dialog">
    <div class="auth-dialog--head">
      <img
        class="auth-dialog--image"
        [alt]="t('dialog.languages.title')"
        ngSrc="https://content.funid.com/uploads/banner_cc820796ac_5ed8e2d46a.png"
        fill
      />
    </div>

    <fid-login-form class="login-dialog--form"></fid-login-form>
  </div>
</ng-container>
