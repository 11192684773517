import { ChangeDetectionStrategy, Component, inject, signal, WritableSignal } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiButtonModule, TuiSvgModule } from '@taiga-ui/core';
import { CommonRepository } from 'core/data/repository/common/common.repository';
import { map } from 'rxjs';

import type { CommonModel } from 'core/data/domain/common.model';
import { AsyncPipe, NgIf } from '@angular/common';
import { AmpEventsName, AmplitudeService } from 'shared/services/amplitude.service';
import { AuthStorageService } from 'features/auth/services/auth-storage.service';
import { SendAnalyticsDirective } from 'shared/directives/send-analytics.directive';

@Component({
  selector: 'fid-app-link',
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    TuiButtonModule,
    TuiSvgModule,
    TranslocoDirective,
    SendAnalyticsDirective,
  ],
  templateUrl: './app-link.component.html',
  styleUrl: './app-link.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppLinkComponent {
  private readonly common = inject(CommonRepository);
  private readonly amplitude = inject(AmplitudeService);
  private readonly storage: AuthStorageService = inject(AuthStorageService);

  public onelink$ = this.common.get().pipe(map(({ onelink }: CommonModel) => onelink));
  protected isAuth: WritableSignal<boolean> = signal(this.storage.hasAuthToken());
  protected readonly ampEventsName = AmpEventsName;
}
