<div class="container search">
  <tui-input
    class="search--field"
    [tuiTextfieldIconLeft]="'fidSearchBlue'"
    [formControl]="searchControl"
    [tuiTextfieldSize]="'m'"
    [tuiTextfieldLabelOutside]="true"
  >
    {{ placeholder }}

    <input type="text" tuiTextfield />
  </tui-input>

  <div class="search-extra">
    <ng-content></ng-content>
  </div>
</div>
