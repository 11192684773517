import { ReverseMap } from 'core/base/utils/types';
import { LanguageSelectionDialogComponent } from 'core/features/localization/components/language-selection-dialog/language-selection-dialog.component';
import { LoginDialogComponent } from 'features/auth/dialogs/login-dialog/login-dialog.component';
import { CasinosDialogComponent } from 'features/casino/casinos-dialog/casinos-dialog.component';
import { GameProvidersDialogComponent } from 'features/games/game-providers-dialog/game-providers-dialog.component';
import { HowItWorksDialogComponent } from 'features/how-it-works/how-it-works-dialog/how-it-works-dialog.component';
import { TransactionDialogComponent } from 'features/transactions/components/transaction-dialog/transaction-dialog.component';
import { RegistrationDialogComponent } from 'features/auth/dialogs/registration-dialog/registration-dialog.component';
import { ProjectLoginDialogComponent } from 'features/project/dialogs/project-login-dialog/project-login-dialog.component';
import { CashierDialogComponent } from 'features/cashier/dialogs/cashier-dialog/cashier-dialog.component';
import { ProjectInfoDialogComponent } from 'features/auth/dialogs/project-info-dialog/project-info-dialog.component';
import { ProjectChooseDialogComponent } from 'features/project/dialogs/project-choose-dialog/project-choose-dialog.component';

export type DialogComponentType =
  | typeof LanguageSelectionDialogComponent
  | typeof ProjectInfoDialogComponent
  | typeof LoginDialogComponent
  | typeof GameProvidersDialogComponent
  | typeof CasinosDialogComponent
  | typeof HowItWorksDialogComponent
  | typeof ProjectLoginDialogComponent
  | typeof CashierDialogComponent
  | typeof ProjectChooseDialogComponent;

export type DialogsList = Record<DialogComponentName, DialogComponentType>;

export type DialogComponentName = ReverseMap<typeof DialogComponentName>;

export const DialogComponentName = {
  LanguageSelection: 'select-language',
  ProjectInfo: 'funid-info',
  Login: 'login',
  Registration: 'registration',
  GameProviders: 'game-provider',
  Casinos: 'casinos-list',
  HowItWorks: 'how-it-works',
  Transaction: 'transaction',
  BrandLogin: 'login-casino',
  Cashier: 'cashier',
  ChooseProject: 'choose-brand',
} as const;

export const DIALOGS_LIST: DialogsList = {
  [DialogComponentName.LanguageSelection]: LanguageSelectionDialogComponent,
  [DialogComponentName.ProjectInfo]: ProjectInfoDialogComponent,
  [DialogComponentName.Login]: LoginDialogComponent,
  [DialogComponentName.Registration]: RegistrationDialogComponent,
  [DialogComponentName.GameProviders]: GameProvidersDialogComponent,
  [DialogComponentName.Casinos]: CasinosDialogComponent,
  [DialogComponentName.HowItWorks]: HowItWorksDialogComponent,
  [DialogComponentName.Transaction]: TransactionDialogComponent,
  [DialogComponentName.BrandLogin]: ProjectLoginDialogComponent,
  [DialogComponentName.Cashier]: CashierDialogComponent,
  [DialogComponentName.ChooseProject]: ProjectChooseDialogComponent,
};
