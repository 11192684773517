import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
  signal,
  WritableSignal,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslocoDirective } from '@ngneat/transloco';
import { AsyncPipe } from '@angular/common';
import { TuiButtonModule, TuiErrorModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { TuiFieldErrorPipeModule, TuiInputModule, TuiInputPasswordModule } from '@taiga-ui/kit';
import { CasinoModel } from 'core/data/domain/casino.model';
import { OpenDialogDirective } from 'core/features/dialog/directives/open-dialog.directive';
import { BrandService } from 'features/project/services/brand.service';
import { ProjectLoginDto } from 'core/fapi/models/project-login-dto';
import { DialogCloseService } from 'core/features/dialog/services/dialog-close.service';
import { take, tap } from 'rxjs';
import { WINDOW } from '@ng-web-apis/common';
import { LOGIN_VALIDATOR, PASSWORD_VALIDATOR } from 'core/base/constants/form-validators.constants';
import { ErrorsStorageService } from 'core/base/services/errors-storage.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AmpEventsName, AmplitudeService } from 'shared/services/amplitude.service';
import { SendAnalyticsDirective } from 'shared/directives/send-analytics.directive';

@Component({
  selector: 'fid-project-login-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslocoDirective,
    AsyncPipe,
    TuiErrorModule,
    TuiFieldErrorPipeModule,
    TuiInputModule,
    TuiTextfieldControllerModule,
    TuiButtonModule,
    OpenDialogDirective,
    TuiInputPasswordModule,
    SendAnalyticsDirective,
  ],
  templateUrl: './project-login-form.component.html',
  styleUrl: './project-login-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectLoginFormComponent implements OnInit {
  private readonly window: Window = inject(WINDOW);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly brandService: BrandService = inject(BrandService);
  private readonly dialogClose: DialogCloseService = inject(DialogCloseService);
  private readonly errorsService: ErrorsStorageService = inject(ErrorsStorageService);
  private readonly amplitude = inject(AmplitudeService);

  @Input({ required: true }) public casino!: CasinoModel;

  protected form!: FormGroup;
  protected isPending: WritableSignal<boolean> = signal(false);
  protected readonly ampEventsName = AmpEventsName;

  public ngOnInit() {
    this.form = this.createForm();
  }

  public submitForm(): void {
    this.isPending.set(true);

    const formData = this.buildFormData(this.form);

    this.brandService
      .connectBrand(formData)
      .pipe(
        take(1),
        tap(item => {
          this.window.open(item.url, '_blank');
          this.isPending.set(false);
          this.dialogClose.close();
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        error: error => {
          this.amplitude.send(AmpEventsName.LoginBrandError, {
            isUserExist: false,
            brandDomain: formData.domain,
            error: error.exception.message,
          });
        },
        next: () => {
          this.amplitude.send(AmpEventsName.LoginBrandSuccess, {
            brandDomain: formData.domain,
            source: 'checkUrl',
          });
        },
      });

    this.errorsService.errors$
      .pipe(
        take(1),
        tap(() => this.isPending.set(false)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  private buildFormData(data: FormGroup): ProjectLoginDto {
    const formValue = {
      ...data.value,
    };

    return {
      domain: this.casino?.domain || '',
      login: formValue.email,
      password: formValue.password,
    };
  }

  private createForm(): FormGroup {
    return new FormGroup({
      email: new FormControl<string | null>(null, LOGIN_VALIDATOR),
      password: new FormControl<string | null>(null, PASSWORD_VALIDATOR),
    });
  }
}
