import {
  ChangeDetectorRef,
  DestroyRef,
  Directive,
  HostListener,
  inject,
  Input,
} from '@angular/core';
import { TuiButtonComponent } from '@taiga-ui/core';
import { BrandService } from 'features/project/services/brand.service';
import { take, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { WINDOW } from '@ng-web-apis/common';
import { DialogCloseService } from 'core/features/dialog/services/dialog-close.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogData } from 'features/project/model/project.model';
import { AmpEventsName, AmplitudeService } from 'shared/services/amplitude.service';

@Directive({
  selector: '[fidPlayButton]',
  standalone: true,
})
export class PlayButtonDirective {
  private readonly router: Router = inject(Router);
  private readonly window: Window = inject(WINDOW);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly brandService: BrandService = inject(BrandService);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly dialogClose: DialogCloseService = inject(DialogCloseService);
  private readonly changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);
  private readonly tuiButtonComponent: TuiButtonComponent = inject(TuiButtonComponent);
  private readonly amplitude = inject(AmplitudeService);

  private disableOpenDialog = this.activatedRoute.snapshot.routeConfig?.path === 'cashier';
  protected readonly ampEventsName = AmpEventsName;

  @Input() public playData!: Partial<DialogData>;

  @HostListener('click', ['$event'])
  public open(): void {
    const { casino, game, sport } = { ...this.playData };

    if (casino && casino.domain) {
      this.tuiButtonComponent.showLoader = true;

      this.brandService
        .getBalance(casino.domain)
        .pipe(
          tap(response => {
            if (response.balance <= 0 && !this.disableOpenDialog) {
              this.enableButton();
              this.openDepositDialog();
            } else {
              this.brandService
                .getPlayUrl(casino!.domain!, game?.alias, sport?.EventId)
                .pipe(
                  take(1),
                  tap(response => {
                    this.enableButton();
                    this.openNewTab(response);
                  }),
                  takeUntilDestroyed(this.destroyRef),
                )
                .subscribe({
                  error: error => {
                    this.enableButton();

                    this.amplitude.send(this.ampEventsName.BrandPlayError, {
                      source: 'checkUrl',
                      action: 'play',
                      brandDomain: casino.domain || '',
                      isUserExist: true,
                      error: error.exception.message,
                    });
                  },
                  next: () => {
                    this.amplitude.send(this.ampEventsName.GamePlayClick, {
                      source: 'checkUrl',
                      gameAlias: game!.alias,
                      gameName: game!.name,
                      gameId: game!.id,
                      gameTags: game!.tags.join('; '),
                      brandDomain: casino?.domain || '',
                      isUserExist: true,
                    });
                  },
                });
            }
          }),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe();
    }
  }

  private enableButton(): void {
    this.tuiButtonComponent.showLoader = false;
    this.changeDetectorRef.markForCheck();
  }

  private openNewTab(url: string): void {
    this.window.open(url, '_blank');
    // For clicking inside dialogs
    this.dialogClose.close();
  }

  private openDepositDialog(): void {
    this.router.navigate(['', { outlets: { dialog: [':', 'cashier'] } }], {
      queryParamsHandling: 'preserve',
      state: { size: 'm', data: { casino: this.playData?.casino, game: this.playData?.game } },
    });
  }
}
