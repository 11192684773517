import { DestroyRef, inject, Injectable, signal, WritableSignal } from '@angular/core';
import { DefaultListRequestOptions, ListRequestOptions } from 'core/base/model/list-options.model';
import { WalletPageService } from 'core/capi/services/wallet-page.service';
import { WalletService } from 'core/capi/services/wallet.service';
import { HowItWorksModel } from 'core/data/domain/how-it-works.model';
import {
  WalletFullModel,
  WalletModel,
  WalletPageResponseModel,
  WalletsResponseModel,
} from 'core/data/domain/wallet.model';
import { UserRepository } from 'core/data/repository/user/user.repository';
import { WalletMapper } from 'core/data/repository/wallet/wallet.mapper';
import { LocalizationService } from 'core/features/localization/services/localization.service';
import { map, Observable, take, tap } from 'rxjs';
import { combineLatest } from 'rxjs';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { AuthStorageService } from 'features/auth/services/auth-storage.service';
import { filter } from 'rxjs/operators';
import { FunIdWallet } from 'features/wallet/constants/wallet.constants';

@Injectable({
  providedIn: 'root',
})
export class WalletRepository {
  private readonly destroyRef = inject(DestroyRef);
  private readonly mapper: WalletMapper = inject(WalletMapper);
  private readonly walletService: WalletService = inject(WalletService);
  private readonly walletPageService: WalletPageService = inject(WalletPageService);
  private readonly localization: LocalizationService = inject(LocalizationService);
  private readonly user: UserRepository = inject(UserRepository);
  private readonly authStorageService: AuthStorageService = inject(AuthStorageService);

  private populateList = {
    '0': 'casino,casino.icon,casino.logo,casino.image,icon,casino.category',
  };
  private populatePage = {
    '0': 'hiws,hiws.button,hiws.image,hiws.steps,hiws.steps.image',
  };

  private connectedWallets: WritableSignal<WalletFullModel[]> = signal([]);

  public connectedWallets$: Observable<WalletFullModel[]> = toObservable(this.connectedWallets);

  constructor() {
    this.authStorageService.storage$
      .pipe(
        filter(item => Boolean(item.authToken)),
        tap(() => this.refreshConnectedWallets()),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  public getListResponse(
    options: ListRequestOptions = DefaultListRequestOptions,
  ): Observable<WalletsResponseModel> {
    return this.localization.trackChanges(
      this.walletService
        .getWallets({
          populate: this.populateList,
          'pagination[limit]': options.limit ? Number(options.limit) : undefined,
        })
        .pipe(map(response => this.mapper.mapFromResponse(response))),
    );
  }

  public getList(options?: ListRequestOptions): Observable<WalletModel[]> {
    return this.getListResponse(options).pipe(map(response => response.data));
  }

  public getConnectedWallets(): Observable<WalletFullModel[]> {
    return combineLatest([this.getList(), this.user.getUserProjectList()]).pipe(
      map(([wallets, projects]) =>
        wallets
          .map(wallet => {
            const project = projects.find(
              project => project.domain.toLowerCase() === wallet?.casino?.domain?.toLowerCase(),
            );

            return { ...project, ...wallet } as WalletFullModel;
          })
          .map(wallet => {
            return wallet;
          })
          .filter(wallet => typeof wallet?.balance === 'number'),
      ),
      tap((wallets: WalletFullModel[]) => {
        wallets.unshift(FunIdWallet);
        this.connectedWallets.set(wallets);
      }),
    );
  }

  public getPageResponse(
    options: ListRequestOptions = DefaultListRequestOptions,
  ): Observable<WalletPageResponseModel> {
    return this.localization.trackChanges(
      this.walletPageService
        .getWalletPage({
          populate: this.populatePage,
          'pagination[limit]': options.limit ? Number(options.limit) : undefined,
        })
        .pipe(map(response => this.mapper.mapFromPageResponse(response))),
    );
  }

  public getPageHiws(options?: ListRequestOptions): Observable<HowItWorksModel[]> {
    return this.getPageResponse(options).pipe(map(pageResponse => pageResponse.data));
  }

  public refreshConnectedWallets(): void {
    if (this.authStorageService.hasAuthToken()) {
      this.getConnectedWallets().pipe(take(1), takeUntilDestroyed(this.destroyRef)).subscribe();
    }
  }
}
