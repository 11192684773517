import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { OTPKeyCodes } from '../../constants';
import { InputOTPService } from '../../services';
import { NgForOf, NgIf } from '@angular/common';
import { TrackByPropPipe } from 'core/base/utils/track-by-prop.pipe';

@Component({
  selector: 'fid-input-otp',
  templateUrl: './input-otp.component.html',
  styleUrls: ['./input-otp.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputOTPComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: InputOTPComponent,
      multi: true,
    },
  ],
  standalone: true,
  imports: [ReactiveFormsModule, NgForOf, NgIf, TrackByPropPipe],
})
export class InputOTPComponent implements OnInit, AfterViewInit, ControlValueAccessor, Validator {
  constructor(private inputOTPService: InputOTPService) {}

  @Input() public size: number = 6;
  @ViewChildren('inputEl') public inputEls!: QueryList<ElementRef<HTMLInputElement>>;
  public inputsFormArray!: FormArray;
  public inputsFormArrayControls!: FormControl[];

  public onChange!: (value: string) => void;
  public onTouched!: () => void;

  @Output() protected inputsFilled: EventEmitter<string> = new EventEmitter();

  public ngOnInit(): void {
    if (!this.size || this.size < 1) {
      throw new Error('[size] must be >= 1 to use `<stb-input-otp>`.');
    }

    this.inputsFormArray = this.inputOTPService.buildFormArray(this.size);
    this.inputsFormArrayControls = this.inputsFormArray.controls as FormControl[];
  }

  public ngAfterViewInit(): void {
    this.focusInput(0, 25);
  }

  public writeValue(value: string): void {
    const arrayValues = this.isValueValid(value)
      ? String(value).split('')
      : new Array(this.size).fill('');

    this.inputsFormArray.setValue(arrayValues, { emitEvent: false });
  }

  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.inputsFormArray.disable() : this.inputsFormArray.enable();
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    return this.inputOTPService.validate(control, this.size);
  }

  public focus(): void {
    this.focusInput(0);
  }

  /**
   * Process pressed keys (e.g. Tab, Delete, Arrows) and update form control value
   */
  protected handleKeyDown(e: KeyboardEvent, idx: number): void {
    if (e.key === OTPKeyCodes.vKey && e.metaKey) {
      // handled by handleBeforeInput -> handlePaste(inputValue)
      return;
    }

    e.preventDefault();

    const keyCode = e.code || e.key;
    const isFirstInput = idx === 0;
    const isLastInput = idx === this.size - 1;
    const isDigit = this.isDigits(e.key);
    const isArrowLeft = keyCode === OTPKeyCodes.ArrowLeft;
    const isArrowRight = keyCode === OTPKeyCodes.ArrowRight;
    const isTabKey = keyCode === OTPKeyCodes.Tab && !e.shiftKey;
    const isShiftTabKey = keyCode === OTPKeyCodes.Tab && e.shiftKey;
    const isRemoveKey = [OTPKeyCodes.Backspace, OTPKeyCodes.Delete].includes(keyCode);

    if (isDigit || isRemoveKey) {
      this.inputsFormArray.controls[idx].setValue(isDigit ? e.key : '');
    }

    this.updateWiredValue();

    if ((isArrowLeft || isShiftTabKey || isRemoveKey) && !isFirstInput) {
      this.focusInput(idx - 1);
    }

    if ((isArrowRight || isTabKey || isDigit) && !isLastInput) {
      this.focusInput(idx + 1);
    }

    if (isDigit && isLastInput) {
      this.blurInput(idx);
      this.inputsFilled.emit(this.inputsFormArray.value.join(''));
    }
  }

  protected onBlur(): void {
    this.onTouched?.();
  }

  /**
   * Process Ctrl+V paste and MacOS TouchBar emojis
   */
  protected handleBeforeInput(e: InputEvent): void {
    e.preventDefault();

    const inputValue = e.data;

    if (this.isValueValid(inputValue) && inputValue) {
      this.handlePaste(inputValue);
    }
  }

  protected handlePaste(pasteData: string): void {
    this.writeValue(pasteData);
    this.focusInput(this.inputEls.length - 1);
    this.onTouched();
    this.updateWiredValue();
  }

  private isDigits(value: string): boolean {
    return /^\d+$/.test(value);
  }

  private isValueValid(value: unknown): boolean {
    return typeof value === 'string' && this.isDigits(value) && this.size === value.length;
  }

  private focusInput(idx: number, timeout: number = 0): void {
    setTimeout(() => this.getInput(idx)?.focus(), timeout);
  }

  private blurInput(idx: number, timeout: number = 50): void {
    setTimeout(() => this.getInput(idx)?.blur(), timeout);
  }

  private getInput(idx: number): HTMLInputElement | undefined {
    return this.inputEls.get(idx)?.nativeElement;
  }

  private updateWiredValue(): void {
    setTimeout(() => this.onChange?.(this.inputsFormArray.value.join('')));
  }
}
