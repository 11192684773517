<ng-container *transloco="let t" [ngSwitch]="transactionStatus()">
  <ng-container *ngSwitchCase="status.Frame">
    <iframe #paymentFrame class="payment-frame" [src]="paymentProcessUrl()"></iframe>
  </ng-container>

  <div *ngSwitchCase="status.Declined" class="brand-transaction">
    <p class="brand-transaction--title">{{ t('dialog.payment.wasDeclined') }}</p>
    <picture class="brand-transaction--image">
      <img
        alt=""
        ngSrc="/assets/images/transaction/transaction-declined.png"
        width="100"
        height="100"
      />
    </picture>
    <p class="brand-transaction--text">
      {{ errorMessage ? errorMessage : t('dialog.payment.notEnoughMoney') }}
    </p>
    <button
      type="button"
      class="brand-transaction--btn"
      [appearance]="'secondary'"
      [fidSendAnalytics]="[
        ampEventsName.PaymentResultPrimaryClick,
        {
          action: 'TryAgain',
          transactionType: transactionType(),
          isQrCodeScanned: false,
          isSuccess: transactionStatus() === 'success',
          brandDomain: casino.domain!,
        },
      ]"
      (click)="switchToCashier()"
      tuiButton
      size="l"
    >
      {{ t('global.btn.tryAgain') }}
    </button>
    <button
      appearance="whiteblock"
      type="button"
      class="brand-transaction--btn"
      [fidSendAnalytics]="[
        ampEventsName.PaymentResultSecondaryClick,
        {
          action: 'Ok',
          transactionType: transactionType(),
          isQrCodeScanned: false,
          isSuccess: transactionStatus() === 'success',
          brandDomain: casino.domain!,
        },
      ]"
      (click)="closeDialog()"
      tuiButton
      size="m"
    >
      {{ t('global.btn.okThanks') }}
    </button>
  </div>

  <div *ngSwitchCase="status.Success" class="brand-transaction">
    <p class="brand-transaction--title">
      {{
        transactionType() === 'DEPOSIT' || transactionType() === 'PLATFORM_DEPOSIT'
          ? t('dialog.payment.wasSuccessDeposit')
          : t('dialog.payment.wasSuccess')
      }}
    </p>

    <picture class="brand-transaction--image">
      <img
        alt=""
        ngSrc="/assets/images/transaction/transaction-success.png"
        width="100"
        height="100"
      />
    </picture>
    <p class="brand-transaction--text">
      {{
        transactionType() === 'DEPOSIT' || transactionType() === 'PLATFORM_DEPOSIT'
          ? t('dialog.payment.youAddedBalance', { project: casino.name })
          : t('dialog.payment.youWithdrawalFrom', { project: casino.name })
      }}
      <span class="brand-transaction--amount">
        {{ amount || '0' | currency: casino.currency }}
      </span>
    </p>
    <button
      type="button"
      class="brand-transaction--btn"
      [appearance]="'secondary'"
      [playData]="{ casino }"
      [fidSendAnalytics]="[
        ampEventsName.PaymentResultPrimaryClick,
        {
          action: 'GoTo',
          transactionType: transactionType(),
          isQrCodeScanned: false,
          isSuccess: transactionStatus() === 'success',
          brandDomain: casino.domain!,
        },
      ]"
      tuiButton
      size="l"
      fidPlayButton
    >
      {{ t('global.btn.goTo') }} {{ casino.name }}
    </button>
    <button
      appearance="whiteblock"
      type="button"
      class="brand-transaction--btn"
      [fidSendAnalytics]="[
        ampEventsName.PaymentResultSecondaryClick,
        {
          action: 'Ok',
          transactionType: transactionType(),
          isQrCodeScanned: false,
          isSuccess: transactionStatus() === 'success',
          brandDomain: casino.domain!,
        },
      ]"
      (click)="closeDialog()"
      tuiButton
      size="m"
    >
      {{ t('global.btn.okThanks') }}
    </button>
  </div>
  <div *ngSwitchDefault>
    <fid-loader height="464"></fid-loader>
  </div>
</ng-container>
