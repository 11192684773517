import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { TuiDialog } from '@taiga-ui/cdk';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiAlertOptions } from '@taiga-ui/core';
import { NgIf, NgOptimizedImage } from '@angular/common';
import { AlertContent, AlertIconUrl } from 'core/features/alerts/constants/alert.constants';
import { CdnUrlPipe } from 'core/base/utils/cdn-url.pipe';

@Component({
  selector: 'fid-alert',
  standalone: true,
  imports: [NgIf, NgOptimizedImage, CdnUrlPipe],
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent {
  public data: AlertContent;

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    public readonly context: TuiDialog<TuiAlertOptions<AlertContent>, AlertContent>,
  ) {
    this.data = this.context.data;
  }

  protected readonly alertIconUrl = AlertIconUrl;
}
