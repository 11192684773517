import { inject, Injectable } from '@angular/core';
import { ResponseMeta } from 'core/base/model/base-api.model';
import { ListMapper, Mapper, ResponseMapper } from 'core/base/model/mapper.model';
import { CasinoResponseDataObject } from 'core/capi/models/casino-response-data-object';
import { WalletListResponse } from 'core/capi/models/wallet-list-response';
import { WalletListResponseDataItem } from 'core/capi/models/wallet-list-response-data-item';
import { WalletPageResponse } from 'core/capi/models/wallet-page-response';
import { IconMapper } from 'core/data/common/icon/icon.mapper';
import { CasinoModel } from 'core/data/domain/casino.model';
import {
  WalletModel,
  WalletPageResponseModel,
  WalletsResponseModel,
} from 'core/data/domain/wallet.model';
import { CasinoMapper } from 'core/data/repository/casino/casino.mapper';
import { HowItWorksMapper } from 'core/data/repository/how-it-works/how-it-works.mapper';

@Injectable({
  providedIn: 'root',
})
export class WalletMapper
  implements
    ResponseMapper<WalletListResponse, WalletsResponseModel>,
    ListMapper<WalletListResponseDataItem, WalletModel>,
    Mapper<WalletListResponseDataItem, WalletModel>
{
  private readonly casinoMapper: CasinoMapper = inject(CasinoMapper);
  private readonly iconMapper: IconMapper = inject(IconMapper);
  private readonly howItWorksMapper: HowItWorksMapper = inject(HowItWorksMapper);

  public mapFrom({ attributes }: WalletListResponseDataItem): WalletModel {
    return {
      gradient: attributes?.gradient || '',
      casino: attributes?.casino?.data
        ? this.casinoMapper.mapFrom(attributes.casino.data as CasinoResponseDataObject)
        : ({} as CasinoModel),
      icon: this.iconMapper.mapFrom(attributes?.icon),
    };
  }

  public mapFromList(items: WalletListResponseDataItem[]): WalletModel[] {
    return items.map(item => this.mapFrom(item));
  }

  public mapFromResponse({ data, meta }: WalletListResponse): WalletsResponseModel {
    return {
      meta: meta as ResponseMeta,
      data: this.mapFromList(data || []),
    };
  }

  public mapFromPageResponse({ data, meta }: WalletPageResponse): WalletPageResponseModel {
    return {
      meta: meta as ResponseMeta,
      data: this.howItWorksMapper.mapFromList(data?.attributes?.hiws?.data || []),
    };
  }
}
