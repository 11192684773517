import { inject, Injectable } from '@angular/core';
import { DeviceInfoService } from 'core/base/services/device-info.service';
import packageJson from '../../../../../package.json';
import { LocalizationService } from 'core/features/localization/services/localization.service';

@Injectable({ providedIn: 'root' })
export class PlatformInfoService {
  private readonly deviceInfoService: DeviceInfoService = inject(DeviceInfoService);
  private readonly localization: LocalizationService = inject(LocalizationService);

  public info = {
    'x-platform-name': this.deviceInfoService.getDeviceType(),
    'x-platform-version': packageJson.version,
  };

  public lang = {
    'accept-language': this.localization.getActiveLangCode().toUpperCase(),
  };
}
