import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiLinkModule, TuiSvgModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { TuiInputNumberModule, TuiInputPasswordModule } from '@taiga-ui/kit';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AppAuthService } from 'features/auth/services/app-auth.service';
import { InputOTPComponent } from 'core/features/input-otp/components';
import { CounterComponent } from 'shared/components/counter/counter.component';
import { AmpEventsName, AmplitudeService } from 'shared/services/amplitude.service';

@Component({
  selector: 'fid-login-code-form',
  standalone: true,
  imports: [
    FormsModule,
    TranslocoDirective,
    TuiLinkModule,
    ReactiveFormsModule,
    TuiInputNumberModule,
    TuiTextfieldControllerModule,
    NgIf,
    CounterComponent,
    TuiSvgModule,
    TuiInputPasswordModule,
    InputOTPComponent,
  ],
  templateUrl: './login-code-form.component.html',
  styleUrl: './login-code-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginCodeFormComponent {
  private readonly appAuthService = inject(AppAuthService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly amplitudeService: AmplitudeService = inject(AmplitudeService);

  protected readonly counterStartDue: number = 30;
  protected readonly counterEndDue: number = 0;
  protected isActive = false;
  protected isCounterCompleted = signal<boolean>(false);
  protected isLoginResponseWaiting = signal<boolean>(false);
  protected form = new FormGroup({
    code: new FormControl(),
  });

  @Output() public switchStep: EventEmitter<boolean> = new EventEmitter();
  @Input() public formPhone?: string;

  @ViewChild('counter') public counterElement!: CounterComponent;

  protected prevStep(): void {
    this.switchStep.emit();
  }

  protected counterCompleted(): void {
    this.isCounterCompleted.set(true);
  }

  protected restartCounter(): void {
    this.isCounterCompleted.set(false);
    this.counterElement.restart();
    this.form.controls.code.reset();
  }

  protected sendLoginResponse(code: string): void {
    if (code && this.formPhone && !this.isLoginResponseWaiting()) {
      this.isLoginResponseWaiting.set(true);
      this.amplitudeService.send(AmpEventsName.AuthSmsCodeWritten);

      this.appAuthService
        .loginAuth({ code: code, phone: this.formPhone })
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: response => {
            this.amplitudeService.send(AmpEventsName.AuthSuccess, {
              type: 'sms',
              uuid: response.accessToken,
              source: 'checkUrl',
            });
          },
          error: error => {
            this.amplitudeService.send(AmpEventsName.AuthError, {
              type: 'sms',
              error: error.exception.message,
              source: 'checkUrl',
            });
          },
        });
    }

    setTimeout(() => {
      // Fix for unstopable filling inputs
      this.isLoginResponseWaiting.set(false);
    }, 500);
  }

  protected sendPhoneResponse(): void {
    if (this.formPhone) {
      this.appAuthService
        .loginInit(this.formPhone)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.restartCounter();
          this.amplitudeService.send(AmpEventsName.AuthResentSmsCode);
        });
    }
  }
}
