import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  TuiCheckboxLabeledModule,
  TuiCheckboxModule,
  TuiDataListWrapperModule,
  TuiFieldErrorPipeModule,
  TuiInputModule,
  TuiInputNumberModule,
  TuiSelectModule,
} from '@taiga-ui/kit';
import {
  TuiDataListModule,
  TuiErrorModule,
  TuiHostedDropdownModule,
  TuiLinkModule,
  tuiNumberFormatProvider,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import { RegistrationFormGroupName } from 'features/auth/model/auth.model';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { AllowedCountriesRepository } from 'core/data/repository/allowed-countries/allowed-countries.repository';
import { TranslocoDirective } from '@ngneat/transloco';
import { combineLatest, map, mergeAll, Observable, take, tap } from 'rxjs';
import { AllowedCountryCurrency } from 'core/data/domain/allowed-country.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LocalizationService } from 'core/features/localization/services/localization.service';
import { ClientInfoService } from 'core/features/client-info/services/client-info.service';
import { CountrySearchListComponent } from 'shared/components/country-search-list/country-search-list.component';

@Component({
  selector: 'fid-registration-form-address',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    TuiInputModule,
    TuiTextfieldControllerModule,
    AsyncPipe,
    NgIf,
    TuiInputNumberModule,
    TuiSelectModule,
    TuiHostedDropdownModule,
    TranslocoDirective,
    TuiDataListWrapperModule,
    TuiDataListModule,
    NgForOf,
    TuiCheckboxModule,
    TuiCheckboxLabeledModule,
    TuiLinkModule,
    TuiErrorModule,
    TuiFieldErrorPipeModule,
    CountrySearchListComponent,
  ],
  templateUrl: './registration-form-address.component.html',
  styleUrl: './registration-form-address.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [tuiNumberFormatProvider({ thousandSeparator: '' })],
})
export class RegistrationFormAddressComponent implements OnInit {
  private readonly rootFormGroup = inject(FormGroupDirective);
  private readonly allowedCountriesRepository = inject(AllowedCountriesRepository);
  private readonly destroyRef = inject(DestroyRef);
  private readonly localization: LocalizationService = inject(LocalizationService);
  private readonly clientInfo: ClientInfoService = inject(ClientInfoService);

  protected formGroup!: FormGroup;
  protected currentLang = this.localization.getActiveLangCode();
  protected countryCurrencies$?: Observable<AllowedCountryCurrency[]>;
  protected allowedCountries$ = this.allowedCountriesRepository.list$;

  public ngOnInit(): void {
    this.formGroup = this.rootFormGroup.control.get(
      RegistrationFormGroupName.AddressFormGroup,
    ) as FormGroup;

    this.formGroup.controls['country'].valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(countryCode => this.setControlCurrency(countryCode));

    combineLatest([this.clientInfo.info$, this.allowedCountries$])
      .pipe(
        take(1),
        tap(([info, allowedCountries]) => {
          if (info && allowedCountries) {
            const clientCountry = allowedCountries.find(item => item.code === info.country.code);
            if (clientCountry) {
              this.formGroup.controls['country'].patchValue(clientCountry.code);
              this.setControlCurrency(clientCountry.code);
              this.updateCurrenciesFieldStatus();
            }
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();

    this.updateCurrenciesFieldStatus();
  }

  private setControlCurrency(countryCode: string): void {
    this.countryCurrencies$ = this.getCountryCurrencies(countryCode).pipe(
      tap(item => {
        this.formGroup.controls['currency'].patchValue(item[0].code);
        this.updateCurrenciesFieldStatus();
      }),
    );
  }

  private getCountryCurrencies(countryName: string): Observable<AllowedCountryCurrency[]> {
    return this.allowedCountries$.pipe(
      map(list => list.filter(item => item.code === countryName)),
      mergeAll(),
      map(list => list.currency),
    );
  }

  private updateCurrenciesFieldStatus() {
    if (this.formGroup.controls['currency'].value) {
      this.formGroup.controls['currency'].enable();
    }
  }
}
