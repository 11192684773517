<div class="amount-calc" [formGroup]="amountGroup">
  <div *ngIf="config.buttons.amount && config.values" class="amount-calc--buttons">
    <button
      *ngFor="let value of config.values"
      type="button"
      class="amount-calc--button"
      [ngClass]="{ _active: value === amountInput.value }"
      (click)="setAmountInputValue(value)"
    >
      {{ value }}
    </button>
  </div>

  <div class="amount-calc--field">
    <button
      *ngIf="config.buttons.controls"
      appearance="accent"
      type="button"
      class="amount-calc--control"
      [fidSendAnalytics]="[
        ampEventsName.PaymentAmountMinusButtonClick,
        {
          value: amountGroup.value.amount,
          paymentMethodCurrency: selectedPayment.currency,
          paymentMethodMinAmount: selectedPayment.min,
          paymentMethodMaxAmount: selectedPayment.max,
        },
      ]"
      (click)="calcAmountInputValue(config.step, 'minus')"
      tuiIconButton
      size="l"
      shape="rounded"
    >
      <tui-svg src="fidMinus"></tui-svg>
    </button>

    <tui-input-number
      #amountInput
      class="amount-calc--input"
      [tuiTextfieldLabelOutside]="true"
      [tuiTextfieldPrefix]="selectedPayment.currency | tuiCurrency"
      [min]="selectedPayment.min"
      [max]="selectedPayment.max"
      formControlName="amount"
    ></tui-input-number>

    <button
      *ngIf="config.buttons.controls"
      appearance="accent"
      type="button"
      class="amount-calc--control"
      [fidSendAnalytics]="[
        ampEventsName.PaymentAmountPlusButtonClick,
        {
          value: amountGroup.value.amount,
          paymentMethodCurrency: selectedPayment.currency,
          paymentMethodMinAmount: selectedPayment.min,
          paymentMethodMaxAmount: selectedPayment.max,
        },
      ]"
      (click)="calcAmountInputValue(config.step, 'plus')"
      tuiIconButton
      size="l"
      shape="rounded"
    >
      <tui-svg src="fidPlus"></tui-svg>
    </button>
    <tui-error
      class="amount-calc--error"
      [formControlName]="'amount'"
      [error]="[] | tuiFieldError | async"
    />
  </div>
</div>
