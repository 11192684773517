import { DestroyRef, inject, Injectable } from '@angular/core';
import { AppAuthService } from 'features/auth/services/app-auth.service';
import { BehaviorSubject, tap } from 'rxjs';
import { InstantInitResponseDto } from 'core/fapi/models/instant-init-response-dto';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SocketIoService } from 'core/base/services/websocket.service';
import { WSEvents, WebSocketStatus } from 'core/base/constants/websocket.constants';
import { WebSocketStatusModel } from 'core/base/model/websocket.model';
import { DialogCloseService } from 'core/features/dialog/services/dialog-close.service';
import { AlertStatus } from 'core/features/alerts/constants/alert.constants';
import { AlertService } from 'core/features/alerts/services/alert.service';
import { AmpEventsName, AmplitudeService } from 'shared/services/amplitude.service';

@Injectable({ providedIn: 'root' })
export class AuthQrService {
  private readonly appAuth: AppAuthService = inject(AppAuthService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly wsIo: SocketIoService = inject(SocketIoService);
  private readonly dialogClose: DialogCloseService = inject(DialogCloseService);
  private readonly alert: AlertService = inject(AlertService);
  private readonly amplitudeService: AmplitudeService = inject(AmplitudeService);

  public instantInitData: InstantInitResponseDto = { token: '', url: '' };
  public instantInitData$ = new BehaviorSubject<InstantInitResponseDto>(this.instantInitData);

  constructor() {
    this.appAuth
      .loginInitQr()
      .pipe(
        tap(data => {
          if (data) {
            this.instantInitData = data;
            this.instantInitData$.next(data);

            this.wsIo.listen([WSEvents.TransactionStatus]);
            this.wsIo.emit(WSEvents.TransactionSubscribe, { transactionId: data.token });
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        error: error => {
          this.amplitudeService.send(AmpEventsName.AuthError, {
            type: 'qrcode',
            error: error.exception.message,
            source: 'WebSocket',
          });
        },
      });

    this.wsIo.socketResponse$
      .pipe(
        tap(response => this.socketResponseAction(response as WebSocketStatusModel)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  private socketResponseAction(response: WebSocketStatusModel): void {
    switch (response.status) {
      case WebSocketStatus.Done:
        this.amplitudeService.send(AmpEventsName.AuthStart, {
          type: 'qrcode',
          source: 'WebSocket',
        });

        this.appAuth
          .loginAuthQr(this.instantInitData.token)
          .pipe(
            tap(() => {
              this.amplitudeService.send(AmpEventsName.AuthSuccess, {
                type: 'qrcode',
                uuid: this.instantInitData.token,
                source: 'WebSocket',
              });
            }),
            takeUntilDestroyed(this.destroyRef),
          )
          .subscribe();
        this.dialogClose.close();

        break;
      case WebSocketStatus.Fail:
        this.amplitudeService.send(AmpEventsName.AuthError, {
          type: 'qrcode',
          error: response.status,
          source: 'WebSocket',
        });

        this.alert.show({ status: AlertStatus.Error });
        this.dialogClose.close();

        break;
      default:
        console.warn('Unexcepted response status');
    }

    this.appAuth
      .loginInitQr()
      .pipe(
        tap(data => {
          if (data) {
            this.instantInitData = data;
            this.instantInitData$.next(data);
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        error: error => {
          this.amplitudeService.send(AmpEventsName.AuthError, {
            type: 'qrcode',
            error: error.exception.message,
            source: 'WebSocket',
          });
        },
      });
  }
}
