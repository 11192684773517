<ng-container *transloco="let t">
  <form class="login-form form" [formGroup]="form">
    <p class="login-form--title form--title">{{ t('global.btn.alreadyHaveAccount') }}</p>

    <div class="form--group">
      <p class="form--title">
        {{ t('global.text.enterYourBrandData', { project: casino.name }) }}
      </p>

      <div class="form--row">
        <div class="form--field">
          <tui-input [formControlName]="'email'" [tuiTextfieldLabelOutside]="true">{{
            t('dialog.form.login')
          }}</tui-input>
          <tui-error [formControlName]="'email'" [error]="[] | tuiFieldError | async" />
        </div>
      </div>

      <div class="form--row">
        <div class="form--field">
          <tui-input-password [formControlName]="'password'" [tuiTextfieldLabelOutside]="true">{{
            t('dialog.form.password')
          }}</tui-input-password>
          <tui-error [formControlName]="'password'" [error]="[] | tuiFieldError | async" />
        </div>
      </div>
    </div>

    <div class="form--row">
      <button
        type="submit"
        class="login-form--btn"
        [disabled]="form.invalid"
        [showLoader]="isPending()"
        [fidSendAnalytics]="[
          ampEventsName.LoginBrandLoginAndPlay,
          {
            isPasswordVisible: false,
            brandDomain: casino.domain || '',
            isUserNameExist: 'unknown',
            isPasswordExist: 'unknown',
          },
        ]"
        (click)="submitForm()"
        tuiButton
      >
        {{ t('global.btn.loginAndPlay') }}
      </button>
    </div>
  </form>
</ng-container>
