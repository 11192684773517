import { inject, Injectable } from '@angular/core';
import { GameProviderListResponse } from 'core/capi/models/game-provider-list-response';
import { GameProviderListResponseDataItem } from 'core/capi/models/game-provider-list-response-data-item';
import { ResponseMeta } from 'core/base/model/base-api.model';
import { CountableListEntity } from 'core/base/model/list-options.model';
import { ListMapper, Mapper, ResponseMapper } from 'core/base/model/mapper.model';
import { IconMapper } from 'core/data/common/icon/icon.mapper';
import {
  GameProviderModel,
  GameProvidersResponseModel,
} from 'core/data/domain/game-provider.model';

@Injectable({
  providedIn: 'root',
})
export class GameProviderMapper
  implements
    Mapper<GameProviderListResponseDataItem, GameProviderModel>,
    ListMapper<GameProviderListResponseDataItem, GameProviderModel>,
    ResponseMapper<GameProviderListResponse, GameProvidersResponseModel>
{
  private iconMapper: IconMapper = inject(IconMapper);

  public mapFromResponse({ meta, data }: GameProviderListResponse): GameProvidersResponseModel {
    return {
      meta: (meta || {}) as ResponseMeta,
      data: this.mapFromList(data || []),
    };
  }

  public mapFromList(params: GameProviderListResponseDataItem[]): GameProviderModel[] {
    return params.map((dto: GameProviderListResponseDataItem) => this.mapFrom(dto));
  }

  public mapFrom(param: GameProviderListResponseDataItem): GameProviderModel {
    return {
      name: param.attributes?.name || '',
      icon: this.iconMapper.mapFrom(param.attributes?.icon),
      count:
        (param.attributes?.games as unknown as CountableListEntity)?.data?.attributes?.count || 0,
      queryParamName: param.attributes?.slug?.toLowerCase() || null,
    };
  }
}
