import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'chunk',
  standalone: true,
})
export class ChunkPipe implements PipeTransform {
  public transform<T>(data: T[] | null | undefined, size: number = 2): T[][] {
    return data ? this.toChunks<T>(data, size) : [];
  }

  private toChunks<T>(data: T[], size: number): T[][] {
    const result: T[][] = [];

    for (let i = 0; i < data.length; i += size) {
      const chunk: T[] = data.slice(i, i + size);

      result.push(chunk);
    }

    return result;
  }
}
