/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface DashboardControllerBrand$Params {
  date: Array<string>;
  'x-platform-name'?: any;
  'x-platform-version'?: any;
  'x-platform-timezone'?: any;
}

export function dashboardControllerBrand(http: HttpClient, rootUrl: string, params: DashboardControllerBrand$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
  const rb = new RequestBuilder(rootUrl, dashboardControllerBrand.PATH, 'get');
  if (params) {
    rb.query('date', params.date, {});
    rb.header('x-platform-name', params['x-platform-name'], {});
    rb.header('x-platform-version', params['x-platform-version'], {});
    rb.header('x-platform-timezone', params['x-platform-timezone'], {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<{
      }>;
    })
  );
}

dashboardControllerBrand.PATH = '/backoffice/dashboard/brand';
