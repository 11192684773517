/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { GamesListDto } from '../../models/games-list-dto';

export interface ProjectControllerGetGamesList$Params {
  domain?: string;
  category?: string;
  brand?: string;
  alias?: string;
  count?: number;
  page?: number;
  search?: string;
  'x-platform-name'?: any;
  'x-platform-version'?: any;
  'x-platform-timezone'?: any;
}

export function projectControllerGetGamesList(http: HttpClient, rootUrl: string, params?: ProjectControllerGetGamesList$Params, context?: HttpContext): Observable<StrictHttpResponse<GamesListDto>> {
  const rb = new RequestBuilder(rootUrl, projectControllerGetGamesList.PATH, 'get');
  if (params) {
    rb.query('domain', params.domain, {});
    rb.query('category', params.category, {});
    rb.query('brand', params.brand, {});
    rb.query('alias', params.alias, {});
    rb.query('count', params.count, {});
    rb.query('page', params.page, {});
    rb.query('search', params.search, {});
    rb.header('x-platform-name', params['x-platform-name'], {});
    rb.header('x-platform-version', params['x-platform-version'], {});
    rb.header('x-platform-timezone', params['x-platform-timezone'], {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<GamesListDto>;
    })
  );
}

projectControllerGetGamesList.PATH = '/api/project/game/list';
