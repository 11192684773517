import { CommonModule } from '@angular/common';
import { Component, inject, signal, WritableSignal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiButtonModule, TuiSvgModule } from '@taiga-ui/core';
import { FidQueryParam } from 'core/base/constants/query-params.constants';
import { SortOrder } from 'core/base/model/base-api.model';
import { CdnUrlPipe } from 'core/base/utils/cdn-url.pipe';
import { GameProviderModel } from 'core/data/domain/game-provider.model';
import { GameProviderRepository } from 'core/data/repository/game-provider/game-provider.repository';
import { OpenDialogDirective } from 'core/features/dialog/directives/open-dialog.directive';
import { DialogCloseService } from 'core/features/dialog/services/dialog-close.service';
import { FilterComponent } from 'core/features/filter/filter/filter.component';
import { SearchComponent } from 'core/features/search/search/search.component';
import { Observable, switchMap } from 'rxjs';
import { FilterDialogItemComponent } from 'shared/components/filter-dialog-item/filter-dialog-item.component';
import { LoaderComponent } from 'shared/components/loader/loader.component';

@Component({
  selector: 'fid-game-providers-dialog',
  standalone: true,
  imports: [
    CommonModule,
    FilterDialogItemComponent,
    FilterComponent,
    CdnUrlPipe,
    LoaderComponent,
    OpenDialogDirective,
    SearchComponent,
    TuiButtonModule,
    TuiSvgModule,
    TranslocoDirective,
  ],
  templateUrl: './game-providers-dialog.component.html',
  styleUrl: './game-providers-dialog.component.scss',
})
export class GameProvidersDialogComponent {
  private dialogClose: DialogCloseService = inject(DialogCloseService);
  private gameProvider: GameProviderRepository = inject(GameProviderRepository);

  protected order = SortOrder;
  protected alphabeticalOrder: WritableSignal<SortOrder> = signal(SortOrder.Asc);

  protected gameProviders$: Observable<GameProviderModel[]> = toObservable(
    this.alphabeticalOrder,
  ).pipe(switchMap((order: SortOrder) => this.gameProvider.getFullList(order)));
  protected filterBy: typeof FidQueryParam.Provider = FidQueryParam.Provider;

  public toggleOrder() {
    this.alphabeticalOrder.update((value: SortOrder) =>
      value === SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc,
    );
  }

  public close(): void {
    this.dialogClose.close();
  }
}
