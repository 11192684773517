import { Directive, HostListener, inject, PLATFORM_ID } from '@angular/core';
import { LocalizationService } from 'core/features/localization/services/localization.service';
import { isPlatformBrowser } from '@angular/common';
import { AmpEventsName, AmplitudeService } from 'shared/services/amplitude.service';

declare global {
  interface Window {
    zE: ZendeskWebWidgetFunction;
  }
}

type ZendeskWebWidgetFunction = (
  command: 'messenger' | 'webWidget' | 'webWidget:on',
  action: 'show' | 'hide' | 'open' | 'close' | 'on' | 'set' | 'setLocale' | 'updateSettings',
  ...args: ZendeskWebWidgetArgs
) => void;

type ZendeskWebWidgetArgs =
  | []
  | [locale: string]
  | [settings: ZendeskWidgetSettings]
  | [handler: () => void]
  | [event: 'close' | 'open' | 'unreadMessages', handler: () => void];

interface ZendeskWidgetSettings {
  webWidget?: {
    chat?: {
      departments?: {
        enabled?: string[];
        select?: string;
      };
    };
    [key: string]: unknown;
  };
}

@Directive({
  selector: '[fidOpenChat]',
  standalone: true,
})
export class OpenChatDirective {
  private readonly localization: LocalizationService = inject(LocalizationService);
  protected readonly platformId: object = inject(PLATFORM_ID);
  protected isBrowser = isPlatformBrowser(this.platformId);
  private readonly amplitude = inject(AmplitudeService);

  constructor() {
    if (this.isBrowser) {
      window.zE(
        'webWidget',
        'setLocale',
        this.localization.getActiveLangCode() as ZendeskWidgetSettings,
      );

      window.zE('webWidget', 'updateSettings', {
        webWidget: {
          chat: {
            departments: {
              enabled: ['FunID Support'],
              select: 'FunID Support',
            },
          },
        },
      });

      window.zE('webWidget', 'hide');

      window.zE('webWidget:on', 'close', function () {
        window.zE('webWidget', 'hide');
      });
    }
  }

  @HostListener('click', ['$event'])
  public open(): void {
    window.zE(
      'webWidget',
      'setLocale',
      this.localization.getActiveLangCode() as ZendeskWidgetSettings,
    );

    this.amplitude.send(AmpEventsName.ZendeskClick, { source: 'checkUrl' });

    if (this.isBrowser) {
      window.zE('webWidget', 'show');
      window.zE('webWidget', 'open');
    }
  }
}
