import {
  afterNextRender,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
} from '@angular/core';
import { RegistrationFormDetailsComponent } from 'features/auth/components/registration-form/registration-form-details/registration-form-details.component';
import { RegistrationFormAddressComponent } from 'features/auth/components/registration-form/registration-form-address/registration-form-address.component';
import { RegistrationFormAvatarComponent } from 'features/auth/components/registration-form/registration-form-avatar/registration-form-avatar.component';
import { NgClass } from '@angular/common';
import {
  RegistrationData,
  RegistrationFormGroupName,
  RegistrationSteps,
} from 'features/auth/model/auth.model';
import { TuiButtonModule, TuiSvgModule } from '@taiga-ui/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TuiDay } from '@taiga-ui/cdk';
import {
  InitialFormValues,
  RegistrationStepTitlesKeys,
  RegistrationTuiDayFormat,
} from 'features/auth/constants/registration-form.constants';
import { AppAuthService } from 'features/auth/services/app-auth.service';
import { LocalizationService } from 'core/features/localization/services/localization.service';
import { RegistrationRequest } from 'core/fapi/models/registration-request';
import { AuthTempHolderService } from 'features/auth/services/auth-temp-storage.service';
import { Router } from '@angular/router';
import {
  CustomPatternValidator,
  EMAIL_PATTERN,
  POSTCODE_PATTERN,
} from 'features/auth/validators/custom-pattern.validator';
import { AlertStatus } from 'core/features/alerts/constants/alert.constants';
import { AlertService } from 'core/features/alerts/services/alert.service';
import { DialogCloseService } from 'core/features/dialog/services/dialog-close.service';
import { NAME_VALIDATOR } from 'core/base/constants/form-validators.constants';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AmpEventsName, AmplitudeService } from 'shared/services/amplitude.service';

@Component({
  selector: 'fid-registration-form',
  standalone: true,
  imports: [
    RegistrationFormDetailsComponent,
    RegistrationFormAddressComponent,
    RegistrationFormAvatarComponent,
    TuiSvgModule,
    TuiButtonModule,
    NgClass,
    ReactiveFormsModule,
  ],
  templateUrl: './registration-form.component.html',
  styleUrl: './registration-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistrationFormComponent {
  private readonly authTempHolder = inject(AuthTempHolderService);
  private readonly router: Router = inject(Router);
  private readonly alert: AlertService = inject(AlertService);
  private readonly dialogClose: DialogCloseService = inject(DialogCloseService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly amplitude: AmplitudeService = inject(AmplitudeService);

  private readonly appAuthService = inject(AppAuthService);
  private localizationService = inject(LocalizationService);

  protected activeStep = RegistrationSteps.Details;
  protected registrationSteps = RegistrationSteps;
  protected registrationFormGroupName = RegistrationFormGroupName;
  protected totalSteps = Object.keys(RegistrationSteps).length / 2;
  protected stepTitleKey = RegistrationStepTitlesKeys;
  protected form = this.createForm(InitialFormValues);

  constructor() {
    afterNextRender((): void => {
      if (!this.authTempHolder.get()!.phone) {
        this.amplitude.send(AmpEventsName.RegistrationError);

        this.dialogClose.close();

        this.alert.show({
          status: AlertStatus.Error,
          title: 'Access denied',
          text: 'Required login first',
        });
      }

      this.amplitude.send(AmpEventsName.RegistrationStart, {
        userId: this.authTempHolder.get()!.phone as string,
      });
    });
  }

  public backStep(): void {
    if (this.activeStep === RegistrationSteps.Details) {
      this.router.navigate(['', { outlets: { dialog: [':', 'login'] } }], {
        queryParamsHandling: 'preserve',
        state: { size: 'm' },
      });

      return;
    }

    this.activeStep--;
  }

  public nextStep(): void {
    if (this.activeStep === RegistrationSteps.Details) {
      this.amplitude.send(AmpEventsName.RegistrationPersonalDetailsNextClick);
    }
    if (this.activeStep === RegistrationSteps.Address) {
      this.amplitude.send(AmpEventsName.RegistrationHomeAddressNextClick, {
        country: this.form.value[RegistrationFormGroupName.AddressFormGroup].country,
        countryCode: this.form.value[RegistrationFormGroupName.AddressFormGroup].country,
        currency: this.form.value[RegistrationFormGroupName.AddressFormGroup].currency,
        isAcceptPolicy: true,
        isAcceptReceiveEmails:
          this.form.value[RegistrationFormGroupName.AddressFormGroup].acceptPrivacyPolicy,
      });
    }
    if (this.activeStep === RegistrationSteps.Avatar) {
      this.amplitude.send(AmpEventsName.RegistrationSelectAvatarYeahClick, {
        selectedAvatar: this.form.value[RegistrationFormGroupName.AvatarFormGroup].avatar,
      });

      this.submitForm();
      return;
    }

    this.activeStep++;
  }

  public submitForm(): void {
    const formData = this.buildFormData(this.form);

    this.appAuthService
      .registration(formData)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        error: () => {
          this.amplitude.send(AmpEventsName.RegistrationError);
        },
        next: () => {
          this.amplitude.send(AmpEventsName.RegistrationSuccess);
        },
      });
  }

  private buildFormData(data: FormGroup): RegistrationRequest {
    const formValue = {
      ...data.value[RegistrationFormGroupName.DetailsFormGroup],
      ...data.value[RegistrationFormGroupName.AddressFormGroup],
      ...data.value[RegistrationFormGroupName.AvatarFormGroup],
    };

    return {
      email: formValue.email,
      phone: this.authTempHolder.get()!.phone as string,
      name: formValue.name,
      surname: formValue.surname,
      birthday: formValue.birthday.getFormattedDay(
        RegistrationTuiDayFormat.dayFormat,
        RegistrationTuiDayFormat.separator,
      ),
      gender: formValue.gender.toUpperCase() as RegistrationRequest['gender'],
      address: formValue.address,
      city: formValue.city,
      country: formValue.country,
      postcode: formValue.postcode,
      currency: formValue.currency,
      language: this.localizationService
        .getActiveLangCode()
        .toUpperCase() as RegistrationRequest['language'],
      acceptTermsAndConditions: formValue.acceptTermsAndConditions,
      acceptPrivacyPolicy: formValue.acceptPrivacyPolicy,
      avatar: formValue.avatar,
    };
  }

  private createForm(initialValues?: RegistrationData): FormGroup {
    return new FormGroup({
      [RegistrationFormGroupName.DetailsFormGroup]: new FormGroup({
        email: new FormControl<string | null>(initialValues?.email || null, [
          CustomPatternValidator(EMAIL_PATTERN, {
            incorrect: true,
          }),
          Validators.required,
        ]),
        name: new FormControl<string | null>(initialValues?.name || null, NAME_VALIDATOR),
        surname: new FormControl<string | null>(initialValues?.surname || null, NAME_VALIDATOR),
        birthday: new FormControl<TuiDay | string | null>(
          initialValues?.birthday || null,
          Validators.required,
        ),
        gender: new FormControl<string | null>(initialValues?.gender || null, Validators.required),
      }),
      [RegistrationFormGroupName.AddressFormGroup]: new FormGroup({
        country: new FormControl<string | null>(
          initialValues?.country || null,
          Validators.required,
        ),
        currency: new FormControl<string | null>(
          { value: initialValues?.currency || null, disabled: true },
          Validators.required,
        ),
        address: new FormControl<string | null>(
          initialValues?.address || null,
          Validators.required,
        ),
        city: new FormControl<string | null>(initialValues?.city || null, Validators.required),
        postcode: new FormControl<string | null>(initialValues?.postcode || null, [
          CustomPatternValidator(POSTCODE_PATTERN, {
            incorrect: true,
          }),
          Validators.required,
        ]),
        acceptTermsAndConditions: new FormControl<true | null>(
          initialValues?.acceptTermsAndConditions || null,
          Validators.requiredTrue,
        ),
        acceptPrivacyPolicy: new FormControl<boolean | null>(
          initialValues?.acceptPrivacyPolicy || null,
          Validators.required,
        ),
      }),
      [RegistrationFormGroupName.AvatarFormGroup]: new FormGroup({
        avatar: new FormControl<string | null>(initialValues?.avatar || null, Validators.required),
      }),
    });
  }
}
