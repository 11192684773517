import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  Renderer2,
  signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import {
  CurrencyPipe,
  NgClass,
  NgForOf,
  NgIf,
  NgOptimizedImage,
  NgTemplateOutlet,
  SlicePipe,
} from '@angular/common';
import { TuiRadioBlockModule } from '@taiga-ui/kit';
import { FormGroup, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { TuiRadioModule } from '@taiga-ui/experimental';
import { TranslocoDirective } from '@ngneat/transloco';
import { PaymentMethodsDto } from 'core/fapi/models/payment-methods-dto';
import { TuiButtonModule, TuiSvgModule } from '@taiga-ui/core';
import { delay, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AmpEventsName, AmplitudeService } from 'shared/services/amplitude.service';
import { SendAnalyticsDirective } from 'shared/directives/send-analytics.directive';

@Component({
  selector: 'fid-payments-carousel',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    TuiRadioBlockModule,
    ReactiveFormsModule,
    TuiRadioModule,
    CurrencyPipe,
    NgOptimizedImage,
    TranslocoDirective,
    NgClass,
    SlicePipe,
    NgTemplateOutlet,
    TuiSvgModule,
    TuiButtonModule,
    SendAnalyticsDirective,
  ],
  templateUrl: './payments-carousel.component.html',
  styleUrl: './payments-carousel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentsCarouselComponent implements OnInit {
  private readonly rootFormGroup = inject(FormGroupDirective);
  private readonly renderer: Renderer2 = inject(Renderer2);
  private readonly elementRef = inject(ElementRef);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly amplitude = inject(AmplitudeService);

  @Input({ required: true }) public payments!: PaymentMethodsDto[] | null;
  @Input() public heading?: string;

  @Output() public paymentChanged: EventEmitter<PaymentMethodsDto> = new EventEmitter();

  @ViewChild('placholderContainer') public placholderContainer!: ElementRef<HTMLElement>;

  protected maxPayments = 2;
  protected paymentGroup!: FormGroup;
  protected ampEventsName = AmpEventsName;

  protected isPaymentListShowed: WritableSignal<boolean> = signal(false);

  public ngOnInit(): void {
    this.paymentGroup = this.rootFormGroup.control.get('paymentGroup') as FormGroup;
    if (this.payments && this.payments[0]) {
      this.paymentGroup.patchValue({ payment: this.payments[0].alias });
    }

    this.paymentGroup.valueChanges
      .pipe(
        delay(1),
        tap(value => {
          if (this.payments) {
            this.isPaymentListShowed.set(false);

            const selectedValue = this.payments.find(payment => payment.alias === value.payment);
            const selectedIndex = this.payments.findIndex(
              payment => payment.alias === value.payment,
            );

            this.createPaymentPlaceholder(selectedIndex);
            this.paymentChanged.emit(selectedValue);
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  protected switchPaymentList(): void {
    this.isPaymentListShowed.set(!this.isPaymentListShowed());

    if (this.isPaymentListShowed()) {
      this.amplitude.send(AmpEventsName.PaymentMethodsScreenOpen);
    }
  }

  private createPaymentPlaceholder(index: number): void {
    this.placholderContainer.nativeElement.innerHTML = '';
    const activeLabel = this.elementRef.nativeElement.querySelector(':checked')?.parentElement;

    if (activeLabel) {
      let clonedActiveLabel;
      if (index >= this.maxPayments) {
        clonedActiveLabel = activeLabel.cloneNode(true);
        this.renderer.appendChild(this.placholderContainer.nativeElement, clonedActiveLabel);
      }
      (clonedActiveLabel || activeLabel).scrollIntoView({ behavior: 'smooth', inline: 'center' });
    }
  }
}
