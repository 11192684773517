import { DestroyRef, inject, Injectable } from '@angular/core';
import { AllowedCountryModel } from 'core/data/domain/allowed-country.model';
import { AllowedCountriesMapper } from 'core/data/repository/allowed-countries/allowed-countries.mapper';
import { Service } from 'core/fapi/services/service';
import { BehaviorSubject, map, Observable, take, tap } from 'rxjs';
import { CountryCurrencyResponseDto } from 'core/fapi/models/country-currency-response-dto';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class AllowedCountriesRepository {
  private serviceApi = inject(Service);
  private mapper = inject(AllowedCountriesMapper);
  private destroyRef: DestroyRef = inject(DestroyRef);

  public list$: BehaviorSubject<AllowedCountryModel[]> = new BehaviorSubject<AllowedCountryModel[]>(
    [],
  );

  constructor() {
    this.getList().pipe(take(1), takeUntilDestroyed(this.destroyRef)).subscribe();
  }

  public getItem(): Observable<AllowedCountryModel[]> {
    return this.serviceApi
      .countryCurrencyControllerGet()
      .pipe(map((response: CountryCurrencyResponseDto) => this.mapper.mapFromResponse(response)));
  }

  public getList(): Observable<AllowedCountryModel[]> {
    return this.serviceApi.countryCurrencyControllerGet().pipe(
      map((response: CountryCurrencyResponseDto) => this.mapper.mapFromResponse(response)),
      tap(response => this.list$.next(response)),
    );
  }
}
