import { AsyncPipe, NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  inject,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FidCardType } from 'core/base/model/fid-card.model';
import { findLast } from 'core/base/utils/functions';
import { TrackByPropPipe } from 'core/base/utils/track-by-prop.pipe';
import { map, Observable } from 'rxjs';
import { CAROUSEL_CONFIG, FidCarouselConfig } from 'shared/components/carousel/carousel.config';
import { ChunkPipe } from 'shared/pipes/chunk.pipe';
import { BreakpointsService, FidBreakpoint } from 'shared/services/breakpoints.service';
import { register, SwiperContainer } from 'swiper/element/bundle';

register();

@Component({
  selector: 'fid-carousel',
  templateUrl: './carousel.component.html',
  styleUrl: './carousel.component.scss',
  standalone: true,
  imports: [NgForOf, TrackByPropPipe, NgTemplateOutlet, NgIf, ChunkPipe, AsyncPipe],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent<T> implements AfterViewInit {
  protected sliderConfig: FidCarouselConfig = inject(CAROUSEL_CONFIG);
  private breakpointsService = inject(BreakpointsService);

  @Input() public data: Array<T> = [];
  @Input() public cardType: FidCardType = FidCardType.Tall;

  @ViewChild('sliderRef', { static: true }) public sliderRef!: ElementRef<SwiperContainer>;
  @ContentChild('slide', { static: false }) protected slideTemplateRef!: TemplateRef<unknown>;

  protected swiperContainer!: SwiperContainer;
  protected readonly breakpoints$ = this.breakpointsService.selectAllBreakpointsObservable();

  protected slidesRowsCount$: Observable<number> = this.breakpoints$.pipe(
    map(breakpoints => {
      if (!this.sliderConfig.slidesRowsCountBreakpoints) {
        return this.sliderConfig.slidesRowsCount;
      }

      const [currentBreakpoint] =
        findLast(Object.entries(breakpoints), ([key, val]) => {
          return (
            Boolean(val) &&
            Object.prototype.hasOwnProperty.call(this.sliderConfig.slidesRowsCountBreakpoints!, key)
          );
        }) || [];

      return (
        this.sliderConfig.slidesRowsCountBreakpoints[currentBreakpoint as FidBreakpoint] ||
        this.sliderConfig.slidesRowsCount
      );
    }),
  );

  public ngAfterViewInit(): void {
    this.swiperContainer = this.sliderRef.nativeElement;
    Object.assign(this.swiperContainer, this.sliderConfig);
    if (this.swiperContainer.initialize) {
      this.swiperContainer.initialize();
    }
  }

  public next(): void {
    this.swiperContainer.swiper.slideNext();
  }

  public prev(): void {
    this.swiperContainer.swiper.slidePrev();
  }

  public goTo(slide: number): void {
    this.swiperContainer.swiper.slideTo(slide);
  }

  public getActiveIndex(): number {
    return this.sliderRef.nativeElement.swiper.activeIndex;
  }

  public update(): void {
    setTimeout(() => {
      this.sliderRef.nativeElement.swiper.update();
    });
  }
}
