import { BrandConnectData, CalcConfigModel } from 'features/project/model/project.model';

export const RegConfig = {
  Prefix: 'FUNID-',
  LoginCharsLength: 12,
  PasswordCharsLength: 12,
};

export const CalcConfig: CalcConfigModel = {
  values: [50, 100, 200],
  defaultValue: 100,
  step: 10,
  buttons: {
    amount: true,
    controls: true,
  },
};

export const InitialValues: BrandConnectData = {
  amount: CalcConfig.values[1],
  passData: true,
  termsAndPolicy: true,
};
