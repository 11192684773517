import { InjectionToken } from '@angular/core';
import { SearchConfig } from 'core/features/search/model/search.model';

export const DEFAULT_SEARCH_CONFIG: SearchConfig = {
  minChar: 3,
  debounceTimeInMs: 400,
};

export const SEARCH_CONFIG: InjectionToken<SearchConfig> = new InjectionToken<SearchConfig>(
  'App Search Config',
);
