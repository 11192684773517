import { Directive, HostListener, inject, Input } from '@angular/core';
import { AmpEventsName, AmplitudeService } from 'shared/services/amplitude.service';

@Directive({
  selector: '[fidSendAnalytics]',
  standalone: true,
})
export class SendAnalyticsDirective {
  private readonly amplitude: AmplitudeService = inject(AmplitudeService);

  @Input() public fidSendAnalytics?: [AmpEventsName, Record<string, string | number | boolean>?];

  @HostListener('click', ['$event'])
  public send(): void {
    setTimeout(() => {
      if (this.fidSendAnalytics) {
        const [name, event] = this.fidSendAnalytics;
        this.amplitude.send(name, event);
      }
    });
  }
}
