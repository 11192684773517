import { inject, Injectable } from '@angular/core';
import { ProjectService } from 'core/fapi/services/project.service';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';
import { ProjectPaymentMethodsDto } from 'core/fapi/models/project-payment-methods-dto';
import { ProjectRegistrationDto } from 'core/fapi/models/project-registration-dto';
import { ProjectRegisterResponseDto } from 'core/fapi/models/project-register-response-dto';
import { PaymentMethodsDto } from 'core/fapi/models/payment-methods-dto';
import { PaymentResultDto } from 'core/fapi/models/payment-result-dto';
import { ProjectPaymentDepositDto } from 'core/fapi/models/project-payment-deposit-dto';
import { PaymentService } from 'core/fapi/services/payment.service';
import { TransactionService } from 'core/fapi/services/transaction.service';
import { IntersectionTransactionDtoAdditionalTransactionDto } from 'core/fapi/models/intersection-transaction-dto-additional-transaction-dto';
import { WalletService } from 'core/fapi/services/wallet.service';
import { ApiResponseDto } from 'core/fapi/models/api-response-dto';
import { WalletPaymentMethodsDto } from 'core/fapi/models/wallet-payment-methods-dto';
import { PlatformInfoService } from 'core/base/services/platform-info.service';

/* eslint-disable */
const parseJson = (data: string): any => JSON.parse(data);
/* eslint-enable */

@Injectable({ providedIn: 'root' })
export class CashierService {
  private readonly projectService: ProjectService = inject(ProjectService);
  private readonly paymentService: PaymentService = inject(PaymentService);
  private readonly transactionService: TransactionService = inject(TransactionService);
  private readonly walletService: WalletService = inject(WalletService);
  private readonly platformInfo = inject(PlatformInfoService);

  public transactionData$ = new BehaviorSubject<
    (PaymentResultDto & ProjectPaymentDepositDto) | null
  >(null);

  public register(params: ProjectRegistrationDto): Observable<ProjectRegisterResponseDto> {
    return this.projectService
      .projectControllerRegister({ body: params })
      .pipe(map(response => response.data));
  }

  public paymentsMethod(params: ProjectPaymentMethodsDto): Observable<PaymentMethodsDto[]> {
    return this.projectService
      .projectControllerPaymentMethod({ body: params })
      .pipe(map(response => response.data as unknown as PaymentMethodsDto[]));
  }

  public platformPaymentsMethod(params: WalletPaymentMethodsDto): Observable<PaymentMethodsDto[]> {
    return this.walletService
      .walletControllerPaymentMethod({ body: params })
      .pipe(map((item: unknown) => parseJson(item as string).data));
  }

  public withdraw({
    domain,
    method,
    amount,
    currency,
  }: ProjectPaymentDepositDto): Observable<PaymentResultDto> {
    const params = {
      ...this.platformInfo.info,
      body: { domain, method, amount, currency },
    };

    return this.projectService.projectControllerPaymentWithdrawal({ ...params }).pipe(
      map(response => response.data),
      tap(response => this.transactionData$.next({ ...response, ...params.body })),
    );
  }

  public deposit({
    domain,
    method,
    amount,
    currency,
  }: ProjectPaymentDepositDto): Observable<PaymentResultDto> {
    const params = {
      ...this.platformInfo.info,
      body: { domain, method, amount, currency },
    };

    return this.projectService.projectControllerPaymentDeposit({ ...params }).pipe(
      map(response => response.data),
      tap(response => this.transactionData$.next({ ...response, ...params.body })),
    );
  }

  public platformDeposit({
    domain,
    method,
    amount,
    currency,
  }: ProjectPaymentDepositDto): Observable<PaymentResultDto> {
    const params = {
      ...this.platformInfo.info,
      body: { domain, method, amount, currency },
    };

    return this.walletService.walletControllerPaymentDeposit({ ...params }).pipe(
      map((response: unknown) => parseJson(response as string)),
      map((response: unknown) => response as ApiResponseDto & { data?: PaymentResultDto }),
      map(response => response.data),
      tap(response => this.transactionData$.next({ ...response, ...params.body })),
    );
  }

  public platformWithdrawal({
    domain,
    method,
    amount,
    currency,
  }: ProjectPaymentDepositDto): Observable<PaymentResultDto> {
    const params = {
      ...this.platformInfo.info,
      body: { domain, method, amount, currency },
    };

    return this.walletService.walletControllerPaymentWithdraw({ ...params }).pipe(
      map((response: unknown) => parseJson(response as string)),
      map((response: unknown) => response as ApiResponseDto & { data?: PaymentResultDto }),
      map(response => response.data),
      tap(response => this.transactionData$.next({ ...response, ...params.body })),
    );
  }

  public paymentConfirm(uuid: string): Observable<void> {
    return this.paymentService.paymentControllerConfirmPayment({ body: { uuid } });
  }

  public transactionInfo(
    uuid: string,
  ): Observable<IntersectionTransactionDtoAdditionalTransactionDto> {
    return this.transactionService
      .transactionControllerInfo({ uuid })
      .pipe(map(response => response.data));
  }
}
