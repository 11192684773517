/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { walletControllerPaymentDeposit } from '../fn/wallet/wallet-controller-payment-deposit';
import { WalletControllerPaymentDeposit$Params } from '../fn/wallet/wallet-controller-payment-deposit';
import { walletControllerPaymentMethod } from '../fn/wallet/wallet-controller-payment-method';
import { WalletControllerPaymentMethod$Params } from '../fn/wallet/wallet-controller-payment-method';
import { walletControllerPaymentWithdraw } from '../fn/wallet/wallet-controller-payment-withdraw';
import { WalletControllerPaymentWithdraw$Params } from '../fn/wallet/wallet-controller-payment-withdraw';

@Injectable({ providedIn: 'root' })
export class WalletService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `walletControllerPaymentMethod()` */
  static readonly WalletControllerPaymentMethodPath = '/api/wallet/method';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `walletControllerPaymentMethod()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  walletControllerPaymentMethod$Response(params: WalletControllerPaymentMethod$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return walletControllerPaymentMethod(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `walletControllerPaymentMethod$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  walletControllerPaymentMethod(params: WalletControllerPaymentMethod$Params, context?: HttpContext): Observable<void> {
    return this.walletControllerPaymentMethod$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `walletControllerPaymentDeposit()` */
  static readonly WalletControllerPaymentDepositPath = '/api/wallet/deposit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `walletControllerPaymentDeposit()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  walletControllerPaymentDeposit$Response(params: WalletControllerPaymentDeposit$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return walletControllerPaymentDeposit(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `walletControllerPaymentDeposit$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  walletControllerPaymentDeposit(params: WalletControllerPaymentDeposit$Params, context?: HttpContext): Observable<void> {
    return this.walletControllerPaymentDeposit$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `walletControllerPaymentWithdraw()` */
  static readonly WalletControllerPaymentWithdrawPath = '/api/wallet/withdrawal';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `walletControllerPaymentWithdraw()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  walletControllerPaymentWithdraw$Response(params: WalletControllerPaymentWithdraw$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return walletControllerPaymentWithdraw(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `walletControllerPaymentWithdraw$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  walletControllerPaymentWithdraw(params: WalletControllerPaymentWithdraw$Params, context?: HttpContext): Observable<void> {
    return this.walletControllerPaymentWithdraw$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
