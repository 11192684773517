/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { GameListResponse } from '../models/game-list-response';
import { GameResponse } from '../models/game-response';
import { getGames } from '../fn/game/get-games';
import { GetGames$Params } from '../fn/game/get-games';
import { getGamesId } from '../fn/game/get-games-id';
import { GetGamesId$Params } from '../fn/game/get-games-id';

@Injectable({ providedIn: 'root' })
export class GameService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getGames()` */
  static readonly GetGamesPath = '/games';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGames()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGames$Response(params?: GetGames$Params, context?: HttpContext): Observable<StrictHttpResponse<GameListResponse>> {
    return getGames(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGames$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGames(params?: GetGames$Params, context?: HttpContext): Observable<GameListResponse> {
    return this.getGames$Response(params, context).pipe(
      map((r: StrictHttpResponse<GameListResponse>): GameListResponse => r.body)
    );
  }

  /** Path part for operation `getGamesId()` */
  static readonly GetGamesIdPath = '/games/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGamesId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGamesId$Response(params: GetGamesId$Params, context?: HttpContext): Observable<StrictHttpResponse<GameResponse>> {
    return getGamesId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGamesId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGamesId(params: GetGamesId$Params, context?: HttpContext): Observable<GameResponse> {
    return this.getGamesId$Response(params, context).pipe(
      map((r: StrictHttpResponse<GameResponse>): GameResponse => r.body)
    );
  }

}
