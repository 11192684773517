import { AsyncPipe, DecimalPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, finalize, map, Observable, switchMap, takeWhile, timer } from 'rxjs';

@Component({
  selector: 'fid-counter',
  standalone: true,
  imports: [AsyncPipe, DecimalPipe],
  templateUrl: './counter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CounterComponent implements OnInit {
  private destroyRef: DestroyRef = inject(DestroyRef);

  @Input({ required: true }) public startDue!: number;
  @Input({ required: true }) public endDue!: number;
  @Input() public interval: number = 1000;

  @Output() private counterCompleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  protected startCounter = new BehaviorSubject<void>(undefined);
  protected counter$!: Observable<number>;

  private getCounter(): Observable<number> {
    const isCountdown = this.startDue > this.endDue;

    return this.startCounter.asObservable().pipe(
      switchMap(() => {
        return timer(this.startDue, this.interval).pipe(
          map(item => (isCountdown ? this.startDue - item : item)),
          takeWhile(item => (isCountdown ? item >= 0 : item <= this.endDue)),
          takeUntilDestroyed(this.destroyRef),
          finalize(() => this.counterCompleted.emit(true)),
        );
      }),
    );
  }

  public ngOnInit(): void {
    this.counter$ = this.getCounter();
  }

  public restart(): void {
    this.startCounter.next(undefined);
  }
}
