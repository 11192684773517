import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import { PlayButtonDirective } from 'shared/directives/play-button.directive';
import {
  CurrencyPipe,
  NgOptimizedImage,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
} from '@angular/common';
import { LoaderComponent } from 'shared/components/loader/loader.component';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiButtonModule } from '@taiga-ui/core';
import { DialogCloseService } from 'core/features/dialog/services/dialog-close.service';
import { CasinoModel } from 'core/data/domain/casino.model';
import { UserProjectModel } from 'features/user/model/user.model';
import { map, take, tap } from 'rxjs';
import { TransactionType } from 'core/data/domain/transaction.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PaymentResultDto } from 'core/fapi/models/payment-result-dto';
import { ProjectPaymentDepositDto } from 'core/fapi/models/project-payment-deposit-dto';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { WebSocketStatus, WSEvents } from 'core/base/constants/websocket.constants';
import { SocketIoService } from 'core/base/services/websocket.service';
import { WebSocketStatusModel } from 'core/base/model/websocket.model';
import { distinctUntilChanged } from 'rxjs/operators';
import { CashierService } from 'features/cashier/services/cashier.service';
import { AmpEventsName } from 'shared/services/amplitude.service';
import { SendAnalyticsDirective } from 'shared/directives/send-analytics.directive';

export enum Status {
  Pending = 'pending',
  Declined = 'declined',
  Success = 'success',
  Frame = 'frame',
}

@Component({
  selector: 'fid-cashier-status',
  standalone: true,
  imports: [
    PlayButtonDirective,
    CurrencyPipe,
    LoaderComponent,
    NgOptimizedImage,
    NgSwitchCase,
    TranslocoDirective,
    TuiButtonModule,
    NgSwitch,
    NgSwitchDefault,
    SendAnalyticsDirective,
  ],
  templateUrl: './cashier-status.component.html',
  styleUrl: './cashier-status.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CashierStatusComponent implements OnInit, OnDestroy {
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly sanitizer: DomSanitizer = inject(DomSanitizer);
  private readonly wsIo: SocketIoService = inject(SocketIoService);
  private readonly dialogClose: DialogCloseService = inject(DialogCloseService);
  private readonly cashierService: CashierService = inject(CashierService);

  protected status = Status;
  protected amount?: number;
  protected errorMessage?: string;
  protected ampEventsName = AmpEventsName;

  protected transactionStatus: WritableSignal<Status> = signal(Status.Pending);
  protected transactionType: WritableSignal<TransactionType> = signal(TransactionType.Deposit);
  protected paymentProcessUrl: WritableSignal<SafeResourceUrl> = signal('');

  @Input({ required: true }) public casino!: CasinoModel & Partial<UserProjectModel>;

  @Output() public isSwitchedToCashier = new EventEmitter<boolean>();

  @ViewChild('paymentFrame', { static: false })
  protected paymentFrame!: ElementRef<HTMLIFrameElement>;

  public ngOnInit(): void {
    this.cashierService.transactionData$
      .pipe(
        take(1),
        tap(payment => {
          if (payment) {
            this.cashierService
              .transactionInfo(payment.uuid)
              .pipe(
                take(1),
                tap(item => {
                  this.transactionType.set(item.type);

                  if (
                    item.type === TransactionType.Deposit ||
                    item.type === TransactionType.Withdraw
                  ) {
                    this.transactionStatus.set(Status.Frame);
                    this.paymentProcessUrl.set(
                      this.sanitizer.bypassSecurityTrustResourceUrl(payment.url),
                    );
                    this.wsIo.listen([WSEvents.TransactionStatus]);
                    this.wsIo.emit(WSEvents.TransactionSubscribe, { transactionId: payment.uuid });

                    this.wsIo.socketResponse$
                      .pipe(
                        map(response => response as WebSocketStatusModel),
                        distinctUntilChanged((prev, cur) => prev.status === cur.status),
                        tap(response => {
                          this.socketResponseAction({ ...response, ...{ amount: payment.amount } });
                        }),
                        takeUntilDestroyed(this.destroyRef),
                      )
                      .subscribe();
                  } else if (
                    item.type === TransactionType.PlatformDeposit ||
                    item.type === TransactionType.PlatformWithdraw
                  ) {
                    this.paymentConfirm(payment);
                  } else {
                    this.errorCloseDialog();
                  }
                }),
                takeUntilDestroyed(this.destroyRef),
              )
              .subscribe();
          } else {
            this.errorCloseDialog();
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        error: () => {
          this.transactionStatus.set(Status.Declined);
        },
      });
  }

  public ngOnDestroy(): void {
    this.cashierService.transactionData$.next(null);
  }

  public switchToCashier(): void {
    this.transactionStatus.set(Status.Pending);
    this.isSwitchedToCashier.emit(false);
  }

  protected closeDialog(): void {
    this.dialogClose.close();
  }

  private errorCloseDialog(): void {
    this.dialogClose.errorClose();
  }

  private paymentConfirm(payment: PaymentResultDto & ProjectPaymentDepositDto): void {
    this.cashierService
      .paymentConfirm(payment.uuid)
      .pipe(
        take(1),
        tap(response => {
          const unknownResponse = response as unknown;
          if (unknownResponse) {
            this.transactionStatus.set(Status.Success);
            this.amount = payment.amount;
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        error: error => {
          this.transactionStatus.set(Status.Declined);
          if (error.error.exception) {
            this.errorMessage = error.error.exception.message;
          }
        },
      });
  }

  private socketResponseAction(response: WebSocketStatusModel): void {
    switch (response.status) {
      case WebSocketStatus.Done:
        this.transactionStatus.set(Status.Success);
        this.amount = response.amount;

        break;
      case WebSocketStatus.Fail:
        this.transactionStatus.set(Status.Declined);

        break;
      default:
        console.error('Unexcepted response status');
    }
  }
}
