import { inject, Injectable } from '@angular/core';
import { SportCategoryListResponse } from 'core/capi/models/sport-category-list-response';
import { SportCategoryListResponseDataItem } from 'core/capi/models/sport-category-list-response-data-item';
import { ListMapper, Mapper, ResponseMapper } from 'core/base/model/mapper.model';
import { SportCategoryModel } from 'core/data/domain/sport-category.model';
import { SportMapper } from 'core/data/repository/sport/sport.mapper';

@Injectable({ providedIn: 'root' })
export class SportCategoryMapper
  implements
    Mapper<SportCategoryListResponseDataItem, SportCategoryModel>,
    ListMapper<SportCategoryListResponseDataItem, SportCategoryModel>,
    ResponseMapper<SportCategoryListResponse, SportCategoryModel[]>
{
  private sportMapper: SportMapper = inject(SportMapper);

  public mapFromResponse({ data }: SportCategoryListResponse): SportCategoryModel[] {
    return this.mapFromList(data || []);
  }

  public mapFromList(params: SportCategoryListResponseDataItem[]): SportCategoryModel[] {
    return params.map(item => this.mapFrom(item));
  }

  public mapFrom({ attributes }: SportCategoryListResponseDataItem): SportCategoryModel {
    return {
      name: attributes?.name || '',
      sports: this.sportMapper.mapFromList(attributes?.sports?.data || []),
    };
  }
}
