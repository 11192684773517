import { inject, Injectable } from '@angular/core';
import { CasinoResponseDataObject } from 'core/capi/models/casino-response-data-object';
import { GameListResponse } from 'core/capi/models/game-list-response';
import { GameListResponseDataItem } from 'core/capi/models/game-list-response-data-item';
import { ResponseMeta } from 'core/base/model/base-api.model';
import { ListMapper, Mapper, ResponseMapper } from 'core/base/model/mapper.model';
import { GameDetailsMapper } from 'core/data/common/game-details/game-details.mapper';
import { IconMapper } from 'core/data/common/icon/icon.mapper';
import { ImageMapper } from 'core/data/common/image/image.mapper';
import { ScreenshotMapper } from 'core/data/common/screenshot/screenshot.mapper';
import { GameModel, GamesResponseModel } from 'core/data/domain/game.model';
import { CasinoMapper } from 'core/data/repository/casino/casino.mapper';

@Injectable({
  providedIn: 'root',
})
export class GameMapper
  implements
    ResponseMapper<GameListResponse, GamesResponseModel>,
    ListMapper<GameListResponseDataItem, GameModel>,
    Mapper<GameListResponseDataItem, GameModel>
{
  private casinoMapper: CasinoMapper = inject(CasinoMapper);
  private screenshotMapper: ScreenshotMapper = inject(ScreenshotMapper);
  private imageMapper: ImageMapper = inject(ImageMapper);
  private iconMapper: IconMapper = inject(IconMapper);
  private gameDetailsMapper: GameDetailsMapper = inject(GameDetailsMapper);

  public mapFromResponse({ meta, data }: GameListResponse): GamesResponseModel {
    return {
      meta: (meta || {}) as ResponseMeta,
      data: this.mapFromList(data || []),
    };
  }

  public mapFromList(params: GameListResponseDataItem[]): GameModel[] {
    return params.map((dto: GameListResponseDataItem) => this.mapFrom(dto));
  }

  public mapFrom({ attributes }: GameListResponseDataItem): GameModel {
    return {
      ...attributes,
      id: attributes?.slug || '',
      gameProvider: {
        name: attributes?.gameProvider?.data?.attributes?.name,
        icon: this.iconMapper.mapFrom(attributes?.gameProvider?.data?.attributes?.icon),
      },
      gameCategories:
        attributes?.gameCategories?.data?.map(item => item.attributes?.name || '') || [],
      image: this.imageMapper.mapFrom(attributes?.image),
      screenshots: this.screenshotMapper.mapFromList(attributes?.screenshots?.data || []),
      gameDetails: this.gameDetailsMapper.mapFromList(attributes?.gameDetails || []),
      casinos: this.casinoMapper.mapFromList(
        (attributes?.casinos?.data || []) as CasinoResponseDataObject[],
      ),
      relations: this.mapFromList(attributes?.relations?.data || []),
    };
  }
}
