import { inject, Injectable } from '@angular/core';
import { CasinoResponseDataObject } from 'core/capi/models/casino-response-data-object';
import { OfferListResponse } from 'core/capi/models/offer-list-response';
import { OfferListResponseDataItem } from 'core/capi/models/offer-list-response-data-item';
import { ResponseMeta } from 'core/base/model/base-api.model';
import { ListMapper, Mapper, ResponseMapper } from 'core/base/model/mapper.model';
import { ImageMapper } from 'core/data/common/image/image.mapper';
import { OfferDetailsMapper } from 'core/data/common/offer-details/offer-details.mapper';
import { OfferModel, OffersResponseModel } from 'core/data/domain/offer.model';
import { CasinoMapper } from 'core/data/repository/casino/casino.mapper';
import { HowItWorksMapper } from 'core/data/repository/how-it-works/how-it-works.mapper';

@Injectable({
  providedIn: 'root',
})
export class OfferMapper
  implements
    Mapper<OfferListResponseDataItem, OfferModel>,
    ListMapper<OfferListResponseDataItem, OfferModel>,
    ResponseMapper<OfferListResponse, OffersResponseModel>
{
  private imageMapper: ImageMapper = inject(ImageMapper);
  private casinoMapper: CasinoMapper = inject(CasinoMapper);
  private offerDetailsMapper: OfferDetailsMapper = inject(OfferDetailsMapper);
  private howItWorksMapper: HowItWorksMapper = inject(HowItWorksMapper);

  public mapFromResponse({ meta, data }: OfferListResponse): OffersResponseModel {
    return {
      meta: (meta || {}) as ResponseMeta,
      data: this.mapFromList(data || []),
    };
  }

  public mapFrom({ attributes }: OfferListResponseDataItem): OfferModel {
    return {
      ...attributes,
      id: attributes?.slug || '',
      image: this.imageMapper.mapFrom(attributes?.image || {}),
      casino: this.casinoMapper.mapFrom(
        (attributes?.casino?.data || {}) as CasinoResponseDataObject,
      ),
      offerDetails: this.offerDetailsMapper.mapFromList(attributes?.offerDetails || []),
      howItWorks: this.howItWorksMapper.mapFromList(attributes?.hiws?.data || []),
      relatedOffers: attributes?.relations?.data?.map(({ attributes: relatedOfferAttributes }) => ({
        ...relatedOfferAttributes,
        id: relatedOfferAttributes?.slug || '',
        image: this.imageMapper.mapFrom(relatedOfferAttributes?.image),
        casino: this.casinoMapper.mapFrom(
          (relatedOfferAttributes?.casino?.data || {}) as CasinoResponseDataObject,
        ),
        offerDetails: this.offerDetailsMapper.mapFromList(
          relatedOfferAttributes?.offerDetails || [],
        ),
      })),
    };
  }

  public mapFromList(params: OfferListResponseDataItem[]): OfferModel[] {
    return params.map((dto: OfferListResponseDataItem) => this.mapFrom(dto));
  }
}
