<button
  type="button"
  class="cashier-tab"
  [appearance]="activeTab === transactionType.Deposit ? 'tertiary' : 'icon'"
  (click)="switchTab(transactionType.Deposit)"
  tuiButton
>
  Deposit
</button>

<button
  type="button"
  class="cashier-tab"
  [appearance]="activeTab === transactionType.Withdrawal ? 'tertiary' : 'icon'"
  (click)="switchTab(transactionType.Withdrawal)"
  tuiButton
>
  Withdrawal
</button>
